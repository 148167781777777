/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import PaginationWrapper from '../../components/PaginationWrapper';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import SearchBarWrapper from '../../components/SearchBarWrapper';
import { debounce } from 'lodash';

const API_URL = '/reg/v1/document/suggested_documents/';

function SuggestedDocuments({ docketId, documentId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [allDocs, setAllDocs] = useState([]);
    const [showDocs, setShowDocs] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [shortTitle, setShortTitle] = useState('ShortTitle');
    const [searchInput, setSearchInput] = useState('');


    const moment = require('moment-timezone');
    function formatDate(timestamp) {
        return timestamp ? moment.utc(timestamp).format('YYYY-MM-DD') : null;
    }

    const title = ['title'];
    const secondaryRow = [['Document Type', 'documentType']];
    const bottomRow = [['Modified Date', 'modifyDate', formatDate]];
    const links = ['fileUrl1', 'iddocument'];


    const fetchDocs = useCallback(
        debounce(async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}${docketId}`, {
                    params: { documentId, searchInput },
                });
                const data = response.data;
                setAllDocs(data);
                setTotalPages(Math.ceil(data.length / itemsPerPage));
            } catch (err) {
                setError('Failed to load documents.');
            } finally {
                setIsLoading(false);
            }
        }, 300),
        [docketId, documentId, searchInput]
    );


    const getShortTitle = async () => {
        try {
            const response = await axios.get(`/reg/v1/document/shortTitle/${docketId}`);

            setShortTitle(response.data[0]?.title || 'No Title');

        } catch (err) {
            console.log(err);
            setError('Failed to load documents.');
        }
    };

    useEffect(() => {
        getShortTitle();
        fetchDocs();
    }, [fetchDocs, docketId, searchInput]);

    useEffect(() => {
        setTotalPages(Math.ceil(allDocs.length / itemsPerPage));
        const startIdx = (currentPage - 1) * itemsPerPage;
        const endIdx = startIdx + itemsPerPage;
        setShowDocs(allDocs.slice(startIdx, endIdx));
    }, [currentPage, itemsPerPage, allDocs]);


    return (
        <div>
            <div className="d-flex ms-8 mt-5 mb-5">
                <h4 className="text-gray-800 fw-bold">{shortTitle}</h4>
            </div>
            <div className="col-7 ms-8 mt-5 mb-5">
                <SearchBarWrapper searchInput={searchInput} setSearchInput={setSearchInput} />
            </div>
            {showDocs.length > 0 ? (
                isLoading ? <HourGlass /> : (<>

                    <DocumentListWrapper
                        filteredDocuments={showDocs}
                        topRow={title}
                        secondaryRow={secondaryRow}
                        bottomRow={bottomRow}
                        linkTo={"id"}
                        links={links}
                        currentPage={currentPage}
                        searchInput={searchInput}
                    />
                </>)
            ) : error ? (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            ) : (
                <div className="d-flex ms-10 mt-5 mb-5">
                    <h2>No documents found with selected filters. Please try again with other filters.</h2>
                </div>
            )}
            <div className="d-flex justify-content-center align-items-center">
                <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

export default SuggestedDocuments;

import React, { useEffect, useState } from 'react'


function QASuggestions({setQuestion,askQuestion}) {
    const sugestedButtons = ["Summary","Impact","References","People",];
    const [triggerQuestion,setTriggerQuestion] = useState(false);

    const suggestedAnswers = {
    "Summary": 'Summarize this document in one paragraph',
    "Impact": "How will this information impact a medical device manufacturer",
    "References": "List all the references made in this document",
    "People": 'Who are key individuals mentioned in this document',
    };
    
    useEffect(() => {
        if(triggerQuestion){
            askQuestion();
            setTriggerQuestion(false);
        }
    },[askQuestion, triggerQuestion])
    
    const onClickHandler = (thisQuestion)=>{
        setQuestion(suggestedAnswers[thisQuestion]);
        setTriggerQuestion(true);

    }

  return (
    <div className="d-flex justify-content-start flex-wrap mb-5 mt-0">
        {sugestedButtons.map((value, index) => (
            <div key={index} className='me-2'>
                <button className="btn btn-sm btn-primary mt-2" onClick={() => onClickHandler(value)}>
                    {value}
                </button>
            </div>
        ))}
    </div>
  );
}

export default QASuggestions;

import React, { useState } from 'react';
import CompareDocumentModal from '../CompareDocumentModal';
import { KTIcon } from '../../../../_metronic/helpers';

const DocDiffTableRow = ({ diffFile, uploadedFiles, setActiveTab, setDiffDeleteConfirmation, setDiffToDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    // console.log("uploaded files:", uploadedFiles);
    const handleButtonClick = async () => {
        setIsOpen(true);
    }
    const compareOnClose = () => {
        setIsOpen(false);
    }
    const title1 = uploadedFiles.find(file => file.id === diffFile.doc_id_1)?.doc_name;
    const title2 = uploadedFiles.find(file => file.id === diffFile.doc_id_2)?.doc_name;
    const filename1 = uploadedFiles.find(file => file.id === diffFile.doc_id_1)?.name;
    const filename2 = uploadedFiles.find(file => file.id === diffFile.doc_id_2)?.name;

    // console.log(title1, title2, typeof(filename1), filename2);
    return (
        <tr>
            <td className="text-start">
                <span className='fw-bold'>File name: </span> {filename1};
                <br />
                <span className='fw-bold'>Title: </span> {title1}
            </td>
            <td className="text-start">
                <span className='fw-bold'>File name: </span>  {filename2};
                <br />
                <span className='fw-bold'>Title: </span> {title2}
            </td>
            <td className="text-start">
                {new Date(diffFile?.created_on).toLocaleString('en-US', { timeZone: 'UTC' })}
            </td>
            <td className="text-start">
                {diffFile.is_completed ?
                    <span className='badge badge-light-success fs-8 fw-bold'>Completed</span>
                    : <span className='badge badge-light-dark fs-8 fw-bold'>Pending</span>
                }
            </td>
            <td className="px-5">
                {/* {diffFile.s3_link} */}
                {diffFile.is_completed ?
                    <button className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }} onClick={handleButtonClick}>Details</button>
                    : <button className="btn btn-sm" style={{ cursor: 'not-allowed' }} onClick={(e) => e.preventDefault()}>Details</button>
                }
                {isOpen && (
                    // compareOnClose, isCompleted, title1, filename1, title2, filename2, s3_link
                    <CompareDocumentModal
                        compareIsOpen={isOpen}
                        compareOnClose={compareOnClose}
                        isCompleted={diffFile.is_completed}
                        title1={title1}
                        filename1={filename1}
                        title2={title2}
                        filename2={filename2}
                        s3_link={diffFile.s3_link}
                        setActiveTab={setActiveTab}
                    />
                )}
            </td>
            <td>
                <button
                    type='button'
                    className='btn btn-icon btn-active-light-primary btn-sm me-4'
                    data-bs-toggle='tooltip'
                    title='Delete'
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDiffDeleteConfirmation(true);
                        setDiffToDelete(diffFile.diff_id); // Store the entire document object
                    }}
                >
                    <KTIcon iconName='trash' className='fs-2 duotone text-danger' />
                </button>
            </td>
        </tr>
    );
};

export default DocDiffTableRow;
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CountAndTable } from './CountAndTable';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import PreferencesComponent from './PreferencesComponent';
const fetchData = (endpoint, setter, currentUser, setLoading, logout, navigate) => {
    if (!currentUser) return;

    const storedData = sessionStorage.getItem(endpoint);
    if (storedData) {
        setter(JSON.parse(storedData));
        return; // Return early if data is found in session storage
    }

    setLoading(true);
    let url = `${endpoint}`;
    axios.get(url)
        .then(response => {
            setter(response.data[0]);
            try {
                sessionStorage.setItem(endpoint, JSON.stringify(response.data[0])); // Store data in session storage
            } catch (error) {
                console.error('Error writing home page counts to session storage', error);

            }
            setLoading(false);
        })
        .catch(error => {
            console.error(`error: couldn't get ${endpoint} count`, error);
            if (error?.response?.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error('There was an error!', error);
                setLoading(false);
            }
        });
};

const HomeDashboard = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [days, setDays] = useState(7);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const interests = useSelector(state => state.account.interests);
    const [programs, setPrograms] = useState({});
    const [committees, setCommittees] = useState({});



    const handleDayChange = (newDays) => {
        setDays(newDays);
        Cookies.set('selectedDays', newDays);
    }

    useEffect(() => {
        const savedDays = Cookies.get('selectedDays');

        if (savedDays) {
            setDays(parseInt(savedDays, 10));
        }
    }, []);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);


    useEffect(() => {
        fetchData(`/reg/v1/home/dashboard_user/` + currentUser?.id, setData, currentUser, setLoading, logout, navigate);
    }, [currentUser, logout, navigate]);



    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div>

                <PreferencesComponent interests={interests} programs={programs} committees={committees} />
            </div>
            <div>

                <div className=' pt-3 row g-5 g-xl-10 '
                    style={{
                        pointerEvents: loading ? 'none' : 'auto',
                        opacity: loading ? 0.9 : 1
                    }}
                >
                    <CountAndTable
                        className='h-md-100 mb-5 mb-xl-10'
                        data={data}
                        days={days}
                        handleDayChange={handleDayChange}
                        loadingmain={loading}
                    />
                </div>
            </div>
        </div>
    );
}
export default HomeDashboard;
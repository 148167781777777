import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import CalendarWrapper from '../pages/CalendarPage/CalendarWrapper'
import DocketWrapper from '../pages/Docket/DocketWrapper'
import DocumentWrapper from '../pages/Document/Documentwrapper'
import Document from '../pages/Document/Document'
import CommentsWrapper from '../pages/Comments/CommentsWrapper'
// import SubscriptionsWrapper from '../pages/MySubsciptions/SubscriptionsWrapper'
import PdfWrapper from '../pages/PDFViewer/PdfWrapper'
import AdminWrapper from '../pages/Admin/AdminWrapper'
import SuperAdminWrapper from '../pages/SuperAdmin/SuperAdminWrapper'
import DocWrapper from '../pages/DOCViewer/DocWrapper'
import DocAttachWrapper from '../pages/DOCAttachViewer/DocAttachWrapper'
import NotesWrapper from '../pages/Notes/NotesWrapper'
import ForecastWrapper from '../pages/Forecast/ForecastWrapper'
import DocketDetails from '../pages/Docket/DocketDetails'
import DocumentDetails from '../pages/Document/DocumentDetails'
import CommentDetails from '../pages/Comments/CommentDetails'
import ProtectedRoute from './ProtectedRoute'
import HouseBills from '../pages/Bills/HouseBills'
import SenateBills from '../pages/Bills/SenateBills'
import StateBills from '../pages/Bills/StateBills'
import { RegulatoryInsights } from '../pages/Insights/RegulatoryInsights'
import HomePageWrapper from '../pages/Home/HomePageWrapper'
import MyDocumentsWrapper from '../pages/MyDocuments/MyDocumentsWrapper'
import { ConnectedRegistration } from '../modules/auth/components/Registration'
import MyDocumentDetails from '../pages/MyDocuments/MyDocumentDetails'
import TranslationWrapper from '../pages/Translation/TranslationWrapper'
import UsefulLinksTable from '../pages/UsefulLinks/UsefulLinksTable'
import Title21 from '../pages/Title21/Title21'
import Title42 from '../pages/Title42/Title42'
import ComingSoon from '../pages/ComingSoon/ComingSoon'
import Device from '../pages/510K/510k'
import PMA from '../pages/PMA/pma'
import Recalls from '../pages/Recalls/Recalls'
import Classification from '../pages/Classification/Classification'
import DeviceDetails from '../pages/510K/510KDetails'
import PMADetails from '../pages/PMA/PMADetails'
import ClassificationDetails from '../pages/Classification/ClassificationDetails'
import Enforcement from '../pages/Enforcement/Enforcement'
import { useAuth } from '../modules/auth'
import GuidanceWrapper from '../pages/Guidance/GuidanceWrapper'
import NoticesWrapper from '../pages/Notices/NoticesWrapper'
import WarningLettersWrapper from '../pages/WarningLetters/WarningLettersWrapper';
import ProposedRulesWrapper from '../pages/ProposedRules/ProposedRulesWrapper'
import Guidance from '../pages/Guidance/Guidance'
import ProposedRules from '../pages/ProposedRules/ProposedRules'
import DebarmentWrapper from '../pages/Debarment/DebarmentWrapper'
import ComingSoonACA from '../pages/ComingSoon/ComingSoonACA'
import ComingSoonSA from '../pages/ComingSoon/ComingSoonSA'
import ComingSoonAnnouncements from '../pages/ComingSoon/ComingSoonAnnouncements'
import ComingSoonBilling from '../pages/ComingSoon/ComingSoonBilling'
import ComingSoonComment from '../pages/ComingSoon/ComingSoonComment'
import ComingSoonDocDiff from '../pages/ComingSoon/ComingSoonDocDiff'
import ComingSoonLabel from '../pages/ComingSoon/ComingSoonLabel'
import ComingSoonPM from '../pages/ComingSoon/ComingSoonPM'
import ComingSoonProposed from '../pages/ComingSoon/ComingSoonProposed'
import ComingSoonWL from '../pages/ComingSoon/ComingSoonWL'
import ComingSoonSupport from '../pages/ComingSoon/ComingSoonSupport'
import ComingSoonRules from '../pages/ComingSoon/ComingSoonRules'
import ComingSoonIRA from '../pages/ComingSoon/ComingSoonIRA'
import AdverseEvents from '../pages/AdverseEvents/AdverseEvents'
import RecallsDetails from '../pages/Recalls/RecallsDetails'
import EnforcementsDetails from '../pages/Enforcement/EnforcementsDetails'
import AdverseEventsDetails from '../pages/AdverseEvents/AdverseEventsDetails'
import Collection from '../pages/MyVault/Collection/collection';
import Preamble from '../pages/Preamble/Preamble';
import MyResearch from '../pages/MyResearch/MyResearch';
import Registration from '../pages/Registration/registration';
import RegistrationDetails from '../pages/Registration/RegistrationDetails';
import CountryWrapper from '../pages/Country/CountryWrapper';
import INDocumentDetails from '../pages/Document/INDocumentDetails';

const DynamicRoute = () => {
  const { documentType } = useParams();

  switch (documentType) {
    case 'final-rules':
      return <Document />;
    case 'proposed-rules':
      return <ProposedRules />;
    case 'guidances':
      return <Guidance />;
    default:
      return <Document />;
  }
};

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const { currentUser } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={currentUser?.is_first_time_login ? '/register' : '/home'} />} />

        {/* Pages */}
        {currentUser?.is_first_time_login && <Route path="/register" element={<ConnectedRegistration />} />}
        {/* <Route path="/register" element={<ConnectedRegistration />} /> */}
        <Route path='/docket' element={<DocketWrapper />} />
        <Route path='/notes' element={<NotesWrapper />} />
        <Route path='/forecast' element={<ForecastWrapper />} />
        <Route path='/calendar' element={<CalendarWrapper />} />
        <Route path='/proposed-rules' element={<ProposedRulesWrapper />} />
        <Route path='/final-rules' element={<DocumentWrapper />} />
        <Route path='/guidances' element={<GuidanceWrapper />} />
        <Route path='/notices' element={<NoticesWrapper />} />
        <Route path='/debarment-list' element={<DebarmentWrapper />} />
        <Route path='/comments' element={<CommentsWrapper />} />
        <Route path='/myitems/:documentType' element={<DynamicRoute />} />
        {/* <Route path='/subscriptions' element={<SubscriptionsWrapper />} /> */}
        <Route path='/viewpdf' element={<PdfWrapper />} />
        <Route path='/viewtxt' element={<DocWrapper />} />
        <Route path='/viewattach' element={<DocAttachWrapper />} />
        <Route path='/docket/:id' element={<DocketDetails />} />
        <Route path='/document/:id' element={<DocumentDetails />} />
        <Route path='international/:id' element={<INDocumentDetails />} />
        <Route path='/comment/:id' element={<CommentDetails />} />
        <Route path='/house' element={<HouseBills />} />
        <Route path='/house/:id' element={<HouseBills />} />
        <Route path='/insights' element={<RegulatoryInsights />} />
        <Route path='/insights/:period' element={<RegulatoryInsights />} />
        <Route path='/home' element={<HomePageWrapper />} />
        <Route path='/senate' element={<SenateBills />} />
        <Route path='/senate/:id' element={<SenateBills />} />
        <Route path='/state' element={<StateBills />} />
        <Route path='/state/:id' element={<StateBills />} />
        <Route path='/apps/mydocuments' element={<MyDocumentsWrapper />} />
        <Route path='/apps/mydocuments/:id' element={<MyDocumentDetails />} />
        <Route path='/links/all' element={<UsefulLinksTable />} />
        <Route path='/links/:id' element={<UsefulLinksTable />} />
        <Route path='/apps/translation' element={<TranslationWrapper />} />
        <Route path='/cor/title-21' element={<Title21 />} />
        <Route path='/cor/title-42' element={<Title42 />} />
        <Route path='/comingsoon' element={<ComingSoon />} />
        <Route path='/device-intelligence/device' element={<Device />} />
        <Route path='/device-intelligence/pma' element={<PMA />} />
        <Route path='/device-intelligence/device/:id' element={<DeviceDetails />} />
        <Route path='/device-intelligence/pma/:id' element={<PMADetails />} />
        <Route path='/device-intelligence/recalls' element={<Recalls />} />
        <Route path='/device-intelligence/recalls/:id' element={<RecallsDetails />} />
        <Route path='/device-intelligence/enforcements' element={<Enforcement />} />
        <Route path='/device-intelligence/enforcements/:id' element={<EnforcementsDetails />} />
        <Route path='/device-intelligence/classification' element={<Classification />} />
        <Route path='/device-intelligence/classification/:id' element={<ClassificationDetails />} />
        <Route path='/acacomingsoon' element={<ComingSoonACA />} />
        <Route path='/sacomingsoon' element={<ComingSoonSA />} />
        <Route path='/eu-comingsoon' element={<ComingSoonAnnouncements />} />
        <Route path='/uk-comingsoon' element={<ComingSoonBilling />} />
        <Route path='/australia-comingsoon' element={<ComingSoonComment />} />
        <Route path='/japan-comingsoon' element={<ComingSoonDocDiff />} />
        <Route path='/china-comingsoon' element={<ComingSoonLabel />} />
        <Route path='/southkorea-comingsoon' element={<ComingSoonPM />} />
        <Route path='/proposedcomingsoon' element={<ComingSoonProposed />} />
        <Route path='/wlcomingsoon' element={<ComingSoonWL />} />
        <Route path='/supportcomingsoon' element={<ComingSoonSupport />} />
        <Route path='/rulescomingsoon' element={<ComingSoonRules />} />
        <Route path='/iracomingsoon' element={<ComingSoonIRA />} />
        <Route path='/device-intelligence/adverseevents' element={<AdverseEvents />} />
        <Route path='/device-intelligence/adverseevents/:id' element={<AdverseEventsDetails />} />
        <Route path='collection/:id' element={<Collection />} />
        <Route path='/preamble' element={<Preamble />} />
        <Route path='/myresearch' element={<MyResearch />} />
        <Route path='/warning-letters' element={<WarningLettersWrapper />} />
        <Route path='/device-intelligence/registration' element={<Registration />} />
        <Route path='/device-intelligence/registration-details' element={<RegistrationDetails />} />
        <Route path='/:country' element={<CountryWrapper />} />
        {/* Admin Routes */}
        <Route path='/admin/*' element={
          <ProtectedRoute roles={[2, 3]}>
            <AdminWrapper />
          </ProtectedRoute>
        } />

        <Route path='/super-admin/*' element={
          <ProtectedRoute roles={[3]}>
            <SuperAdminWrapper />
          </ProtectedRoute>
        } />

        {/* Lazy Modules */}

        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

import React from 'react';
import clsx from 'clsx';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function PaginationWrapper({totalPages,currentPage,setCurrentPage}) {
    const handlePageChange = (event, value) => {
        if(typeof(value) === 'number')setCurrentPage(value);
        else{
            setTimeout(()=>{},100)
        };
       
    };

    return (
        <div className='d-flex justify-content-center mb-7'>
                <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        size="large"
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                className={clsx({
                                    'Mui-selected': item.page === currentPage && item.type !== 'first' && item.type !== 'last',
                                })}
                                sx={{
                                    border: item.page === currentPage && !item.disabled ? '1px solid' : 'none',
                                    color: item.page === currentPage ? 'text.primary' : 'text.primary', // Change to your desired hex color
                                    '&.Mui-selected': {
                                        backgroundColor: 'linear-gradient(to bottom, #2C81AF 50%, #2C81AF 50%)' // Change to your desired hex color
                                    },
                                }}
                                disabled={
                                    ((item.type === 'first' || item.type === 'previous') && currentPage === 1) ||
                                    ((item.type === 'last' || item.type === 'next') && currentPage === totalPages)
                                }
                            />
                        )}
                    />
                </div>
            </div>
    );
}

export default PaginationWrapper
import React, { useState } from "react";
// import Question from "./Question";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface } from "./QAInterface";


const MyResearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    //const [questions, setQuestions] = useState([]);
    //const [conversations, setConversations] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState('');
    //const [isNewReport, setIsNewReport] = useState(true);
    // const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleFirstQuestionUpdate = (question) => {
        // setQuestions([...questions, question]);
        setCurrentQuestion(question);
        // Update qaPairs with the new data
        setIsLoading(false);
    };

    // const handleResize = () => {
    //     setScreenWidth(window.innerWidth);
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    // const handleDownload = (question) => {
    //     const conversation = conversations[question];
    //     if (!conversation) {
    //         console.error('Conversation not found');
    //         return;
    //     }

    //     // Construct the content to be downloaded
    //     let conversationText = `\n"${question}" Full Conversation Thread:\n\n`;
    //     conversationText += '================================================================================\n\n';
    //     conversationText += `Domains: ${conversation.domains.join(', ')}\n\n`;
    //     conversationText += '================================================================================\n\n';

    //     conversation.qaPairs.forEach((qaPair, index) => {
    //         conversationText += `Question ${index + 1}: ${qaPair.question}\n\n`;
    //         conversationText += `Answer ${index + 1}: ${qaPair.answer.text}\n\n`;
    //         if (qaPair.answer.sources && Array.isArray(qaPair.answer.sources)) {
    //             conversationText += 'Sources:\n';
    //             qaPair.answer.sources.forEach((source, sourceIndex) => {
    //                 conversationText += `${sourceIndex + 1}. ${source.title || 'Source'} \n(${source.url || 'No URL'})\n`;
    //                 conversationText += `Relevant Content: "${source.content || 'No content'}"\n\n`;
    //             });
    //             if (index < conversation.qaPairs.length - 1) {
    //                 conversationText += '================================================================================\n\n';
    //             }
    //         } else {
    //             conversationText += 'No sources provided.\n\n';
    //         }
    //     });

    //     const blob = new Blob([conversationText], { type: 'text/plain' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${question}_fullchat.txt`;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(url);
    // };



    // const onConversationUpdate = (conversation) => {
    //     setConversations(JSON.parse(JSON.stringify(conversation)));
    // };
 


    return (
        <div>
            <div className="d-flex flex-column px-5 py-0" >
                <div className="d-flex flex-column-auto mb-2 mt-2 ">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName='search-list' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>My Research</span>
                            {isLoading && (
                                <div className="spinner-border text-primary ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </div>
                    </h1>
                </div>
            </div>

            <div
                className={`container mt-3 px-8 p-3 text-wrap mx-auto vh-80 d-flex flex-row`}
            >
                
                <QAInterface
                    onFirstQuestionAsked={handleFirstQuestionUpdate}
                    // onConversationUpdate={onConversationUpdate}
                    // isNewReport={isNewReport}
                    // setIsNewReport={setIsNewReport}
                    currentQuestion={currentQuestion}
                    // conversations={conversations}
                    className='col-12' />
            </div>
        </div >
    )
}
export default MyResearch;

import React from 'react';
import clsx from 'clsx';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const OrganizationsListPagination = ({ totalPages, currentPage, setCurrentPage }) => {

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // const PAGINATION_PAGES_COUNT = 5;

  // const sliceLinks = (totalPages, currentPage) => {
  //   let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

  //   if (pageLinks.length > PAGINATION_PAGES_COUNT) {
  //     let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
  //     let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

  //     if (start < 0) {
  //       end -= start;
  //       start = 0;
  //     }

  //     if (end >= pageLinks.length) {
  //       start -= end - pageLinks.length + 1;
  //       end = pageLinks.length - 1;
  //     }

  //     pageLinks = pageLinks.slice(start, end + 1);
  //   }

  //   return pageLinks;
  // }

  // const paginationLinks = sliceLinks(totalPages, currentPage);

  return (
    <div className='row mt-5 mb-1'>
      <div className='d-flex align-items-center justify-content-center'>
        <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            size="large"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                className={clsx({
                  'Mui-selected': item.page === currentPage && item.type !== 'first' && item.type !== 'last',
                })}
                sx={{
                  border: item.page === currentPage && !item.disabled ? '1px solid' : 'none',
                  color: item.page === currentPage ? 'text.primary' : 'text.primary', // Change to your desired hex color
                  '&.Mui-selected': {
                    backgroundColor: 'linear-gradient(to bottom, #2C81AF 50%, #2C81AF 50%)' // Change to your desired hex color
                  },
                }}
                disabled={
                  ((item.type === 'first' || item.type === 'previous') && currentPage === 1) ||
                  ((item.type === 'last' || item.type === 'next') && currentPage === totalPages)
                }
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export { OrganizationsListPagination }
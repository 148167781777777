import axios, { AxiosResponse } from 'axios'
import { ID, Response } from "../../../../../../../_metronic/helpers"
import { User, UsersQueryResponse } from './_models'
import Cookies from 'js-cookie';

const USER_URL = `/reg/v1/admin/user`

const getUsers = (query: string, orgId: number, logout: Function, navigate: Function): Promise<UsersQueryResponse> => {

  const GET_USERS_URL = `/reg/v1/admin/orgusers/${orgId}`; // Update the URL
  const searchParam = new URLSearchParams(query).get("search")?.toLowerCase();
  const filterParamActive = new URLSearchParams(query).get("filter_isactive");
  const filterParamLocked = new URLSearchParams(query).get("filter_locked");
  const sortParam = new URLSearchParams(query).get("sort") || "first_name";
  const orderParam = new URLSearchParams(query).get("order") || "asc";
  const roleParam = new URLSearchParams(query).get("filter_role");

  const filterParamAsNumber = filterParamActive ? parseInt(filterParamActive, 10) : null;
  const filterParamLockedAsNumber = filterParamLocked ? parseInt(filterParamLocked, 10) : null;
  const filterParamRoleAsNumber = roleParam ? parseInt(roleParam, 10) : null;

  return axios
    .get(`${GET_USERS_URL}?${query}`) // Append the query string if needed
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      if (!searchParam && filterParamAsNumber === null && filterParamLockedAsNumber === null && sortParam === null && orderParam === null && filterParamRoleAsNumber === null) {
        // If searchParam is not provided, return the entire data without filtering
        const data = d.data;

        return data;
      }
      const data = d.data as User[];
      // Log the data
      let filteredData = data;

      if (filterParamAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_active === filterParamAsNumber);
      }
      if (filterParamLockedAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_locked === filterParamLockedAsNumber);
      }

      if (filterParamRoleAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.role === filterParamRoleAsNumber);
      }

      if (searchParam) {
        // Filter the data based on searchParam if it's not null
        filteredData = filteredData.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
          return (
            fullName.includes(searchParam) ||
            user.email?.toLowerCase().includes(searchParam)
          );
        });
      }
      if (sortParam && orderParam) {
        // Sort the data based on the sortParam and orderParam
        filteredData = filteredData.sort((a, b) => {
          if (sortParam === 'first_name') {
            // Case-insensitive sorting for first names
            let aFirstNameLower = a.first_name?.toLowerCase();
            let bFirstNameLower = b.first_name?.toLowerCase();

            if (aFirstNameLower && bFirstNameLower) {
              if (aFirstNameLower < bFirstNameLower) {
                return orderParam === 'asc' ? -1 : 1;
              } else if (aFirstNameLower > bFirstNameLower) {
                return orderParam === 'asc' ? 1 : -1;
              } else {
                // If first names are equal, sort by last name
                let aLastNameLower = a.last_name?.toLowerCase();
                let bLastNameLower = b.last_name?.toLowerCase();
                if (aLastNameLower && bLastNameLower) {
                  if (aLastNameLower < bLastNameLower) {
                    return orderParam === 'asc' ? -1 : 1;
                  } else if (aLastNameLower > bLastNameLower) {
                    return orderParam === 'asc' ? 1 : -1;
                  } else {
                    // If last names are also equal, sort by email
                    let aEmailLower = a.email?.toLowerCase();
                    let bEmailLower = b.email?.toLowerCase();
                    if (aEmailLower && bEmailLower) {
                      return aEmailLower < bEmailLower ? -1 : 1;
                    }
                  }
                }
              }
              return 0;
            }
          } else {
            // Existing sorting logic for other cases
            const aValue = a[sortParam];
            const bValue = b[sortParam];

            if (orderParam === 'asc') {
              if (typeof aValue === 'string' && typeof bValue === 'string') {
                const aValueLower = aValue.toLowerCase();
                const bValueLower = bValue.toLowerCase();
                return aValueLower < bValueLower ? -1 : 1;
              } else {
                return aValue < bValue ? -1 : 1;
              }
            } else if (orderParam === 'desc') {
              if (typeof aValue === 'string' && typeof bValue === 'string') {
                const aValueLower = aValue.toLowerCase();
                const bValueLower = bValue.toLowerCase();
                return aValueLower > bValueLower ? -1 : 1;
              } else {
                return aValue > bValue ? -1 : 1;
              }
            }
          }
          return 0;
        });
      }



      return {
        data: filteredData, // Wrap the filtered data in a `data` property
      };



    })
    .catch((error: any) => {
      // Handle any errors that may occur during the get request
      if (error?.response?.status === 401) {
        logout();
        navigate('/auth/login');
      } else {
        console.error("Error getting users:", error);
      }
      return { data: [] };
    });
}

const getUserById = async (id: ID, logout: Function, navigate: Function): Promise<User | undefined> => {
  try {
    const response: AxiosResponse<Response<User>> = await axios.get(`${USER_URL}/${id}`);
    const data = response.data;
    // console.log("user data", id);
    const response2 = await axios.get(`/reg/v1/user/accessgroups/${id}`);
    const groupIds = response2.data.map(item => item.group_id);
    data[0].user_group = groupIds;

    // console.log("user groups", response2.data);
    // console.log("modified data", data[0]);

    return data[0];
  } catch (error: any) {
    if (error?.response?.status === 401) {
      logout();
      navigate('/auth/login');
    } else {
      console.error("Error getting user details:", error);
    }
  }
}

const createUser = (user: User, orgId: number, logout: Function, navigate: Function): Promise<any> => {

  const data = {
    first_name: user.first_name,
    last_name: user.last_name,
    title: user.title,
    suffix: "None",
    email: user.email,
    phone: "",
    role: 1,
    organization: orgId,
    adminnotes: user.adminnotes,
  };
  const jsonData = JSON.stringify(data);
  const csrfToken = Cookies.get('csrf_access_token');



  return axios
    .post(`/reg/v1/admin/user`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const result = response.data[0].code;
        // console.log(response.data[0]);
        // console.log('User created', user.user_group);
        return result;
      } else {
        // Handle the case where the response status is not 200 or 201 (e.g., handle errors)
        console.log('Update failed');
      }
    }).catch((error: any) => {
      // Handle any errors that may occur during the get request
      if (error?.response?.status === 401) {
        console.log('Before logout');
        // logout();
        console.log('After logout, before navigate');
        // navigate('/auth/login');
        console.log('After navigate');
      } else if (error?.response?.data?.error) {
        console.error("Error creating user", error.response.data.error);
      } else {
        console.error("Error creating user", error);
        throw error;
      }
    });

}

const updateUser = (user: User, logout: Function, navigate: Function): Promise<User | undefined> => {
  const data = {
    columns: {
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      suffix: user.suffix,
      email: user.email,
      phone: user.phone,
      organization: user.organization,
      role: user.role,
      adminnotes: user.adminnotes,
    }
  };

  const jsonData = JSON.stringify(data);

  const updateUserData = axios
    .patch(`${USER_URL}/${user.id}`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

  const updateAccessGroupsData = {
    access_groups: user.user_group,
  };

  const updateAccessGroups = axios
    .post(`/reg/v1/admin/user/accessgroups/${user.id}`, JSON.stringify(updateAccessGroupsData), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

  return Promise.all([updateUserData, updateAccessGroups])
    .then(([updateUserResponse, updateAccessGroupsResponse]) => {
      if (updateUserResponse.status === 200 || updateUserResponse.status === 201) {
        const updatedUser = updateUserResponse.data;
        updatedUser.user_group = user.user_group;
        // console.log('User updated', updatedUser);
        return updatedUser;
      } else {
        console.error('Error updating user: HTTP Status', updateUserResponse.status);
        console.log('Update failed');
      }
    })
    .catch((error: any) => {
      if (error?.response?.status === 401) {
        logout();
        navigate('/auth/login');
      } else {
        console.error("Error updating user:", error);
      }
      return undefined;
    });
  // return axios
  //   .patch(`${USER_URL}/${user.id}`, jsonData, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }) // Send 'data' as the payload
  //   .then((response) => {
  //     if (response.status === 200 || response.status === 201) {
  //       const updatedUser = response.data;
  //       console.log('User updated', updatedUser, user.user_group);
  //       return updatedUser;
  //     } else {
  //       // Handle the case where the response status is not 200 (e.g., handle errors)
  //       console.error('Error updating user: HTTP Status', response.status);
  //       console.log('Update failed');
  //     }
  //   })
  //   .then((response: Response<User>) => response.data)
  //   .catch((error: any) => {
  //     // Handle any errors that may occur during the get request
  //     if (error?.response?.status === 401) {
  //       logout();
  //       navigate('/auth/login');
  //     } else {
  //       console.error("Error updating user:", error);
  //     }
  //     return undefined; // Return undefined when an error occurs
  //   });
}

const deleteUser = (userId: ID, logout: Function, navigate: Function): Promise<void> => {
  return axios.get(`/reg/v1/admin/deactivate/user/${userId}`).then((response) => { }).catch((error: any) => {
    // Handle any errors that may occur during the delete requests
    if (error?.response?.status === 401) {
      logout();
      navigate('/auth/login');
    } else {
      console.error("Error deleting user:", error);
    }
  });
}

const deleteSelectedUsers = (userIds: Array<ID>, logout: Function, navigate: Function): Promise<void> => {
  const deletePromises = userIds.map((userId) => {
    return deleteUser(userId, logout, navigate);
  });

  // Use Promise.all to wait for all delete requests to complete
  return Promise.all(deletePromises)
    .then((results) => {
      // All delete requests have completed

    })
    .catch((error: any) => {
      // Handle any errors that may occur during the delete requests
      if (error?.response?.status === 401) {
        logout();
        navigate('/auth/login');
      } else {
        console.error("Error deleting selected users:", error);
      }
    });
}

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser }
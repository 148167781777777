import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useAuth } from '../../../../app/modules/auth';
import { useEffect, useState } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { Modal, Button } from 'react-bootstrap';

const supportFormSchema = Yup.object().shape({
    requestType: Yup.string().required('Request type is required'),
    description: Yup.string().required('Description is required'),
});

const SupportModal = ({ displayModal, onClose, activeTab }) => {
    const [showModal, setShowModal] = useState(displayModal);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        setShowModal(displayModal);
    }, [displayModal]);

    const openModal = () => {
        setShowModal(true);
        document.body.classList.add('modal-open');
    }

    useEffect(() => {
        if (displayModal) {
            openModal();
        }
    }, [displayModal]);

    const closeModal = () => {
        setShowModal(false);
        document.body.classList.remove('modal-open');
        onClose(); // Call the onClose function passed as a prop
    }

    useEffect(() => {
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            requestType: '',
            description: '',
        },
        validationSchema: supportFormSchema,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                // Change the format of the values here
                const formattedValues = {
                    ...values,
                    subject: values.requestType,
                    feedback: values.description,
                    resolution: 'Open',
                };

                await axios.post(`/reg/v1/user/feedback/${currentUser?.id}`, formattedValues);
                // console.log(response.data);
                formik.resetForm();
                setShowModal(false);
                document.body.classList.remove('modal-open');
                setShowSuccessModal(true);
                setModalMessage('Feedback submitted successfully!');
                // Here you can handle the response from the server
            } catch (error) {
                console.error('Failed to submit feedback:', error);
                setModalMessage('Failed to submit feedback.');
                setShowSuccessModal(true);
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <>
            <div
                className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* begin::Modal content */}
                    <div className='modal-content position-relative'>
                        {/* begin::Modal header */}
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder mt-1'>{activeTab} Form</h2>
                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-organizations-modal-action='close'
                                onClick={closeModal}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
                            <div className='card-body pt-0 pb-0'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='row mt-2 mb-4'>
                                        <label style={{ width: '30%' }} className='col-form-label required fw-bold fs-6 mb-1'>Type of request</label>
                                        <div style={{ width: '60%', marginTop: '0.25rem' }} className='fv-row' >
                                            <select
                                                name="requestType"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.requestType}
                                                className='form-control form-control-lg form-control-solid'
                                                style={{
                                                    backgroundColor: '#f5f5f5',
                                                    appearance: 'none',
                                                    backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l3 3 3-3' /></svg>")`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'right .5rem center',
                                                    backgroundSize: '1.5em'
                                                }}
                                            >
                                                <option value="">Select...</option>
                                                <option value="Question">Ask a question</option>
                                                <option value="Bug">Report a Bug</option>
                                                <option value="Feedback">Give Feedback</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {formik.touched.requestType && formik.errors.requestType && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '7px', fontSize: '12px' }}>{formik.errors.requestType}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-2'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description</label>
                                    </div>
                                    <div className='row mb-7'>
                                        <div className='col-12 fv-row'>
                                            <textarea
                                                name="description"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                                className='form-control form-control-solid mb-3 mb-lg-0'
                                                rows={10}
                                                maxLength={500}
                                                style={{ backgroundColor: '#f5f5f5' }}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {formik.touched.description && formik.errors.description ? (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>{formik.errors.description}</div>
                                                    </div>
                                                ) : (
                                                    <div style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>&nbsp;</div> // Placeholder div
                                                )}
                                                <div style={{ color: '#6c757d', marginRight: '15px', marginTop: '10px', fontSize: '15px' }}>
                                                    {formik.values.description.length}/500
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' d-flex justify-content-end px-5 mb-5'>
                                        <button type='submit' className='btn btn-primary'>
                                            {loading ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            {showModal && <div className='modal-backdrop fade show'></div>}
            {/* end::Modal Backdrop */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton />
                <Modal.Body className="d-flex justify-content-center fs-2">
                    {modalMessage === 'Feedback submitted successfully!' ? (
                        <div>
                            <i className="bi bi-check-circle-fill text-success fs-1 me-3"></i>
                            {modalMessage}
                        </div>
                    ) : (
                        <div>
                            <i className="bi bi-x-circle text-danger fs-1 me-3"></i>
                            {modalMessage}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { SupportModal }
import React, { useEffect, useRef, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers'
import SearchBar from '../../components/SearchBar';
import { useParams } from 'react-router-dom';
import StateSelector from './StateSelector';

const StateBills = () => {
    const { id } = useParams();
    const [searchInput, setSearchInput] = useState(id || '');
    const highlightedRef = useRef(null);
    useEffect(() => {
        if (highlightedRef.current) {
            highlightedRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [searchInput]);
    const statesData = [
        { id: "1", name: "Alabama", selected: false },
        { id: "2", name: "Alaska", selected: false },
        { id: "3", name: "Arizona", selected: false },
        { id: "4", name: "Arkansas", selected: false },
        { id: "5", name: "California", selected: false },
        { id: "6", name: "Colorado", selected: false },
        { id: "7", name: "Connecticut", selected: false },
        { id: "8", name: "Delaware", selected: false },
        { id: "9", name: "Florida", selected: false },
        { id: "10", name: "Georgia", selected: false },
        { id: "11", name: "Hawaii", selected: false },
        { id: "12", name: "Idaho", selected: false },
        { id: "13", name: "Illinois", selected: false },
        { id: "14", name: "Indiana", selected: false },
        { id: "15", name: "Iowa", selected: false },
        { id: "16", name: "Kansas", selected: false },
        { id: "17", name: "Kentucky", selected: false },
        { id: "18", name: "Louisiana", selected: false },
        { id: "19", name: "Maine", selected: false },
        { id: "20", name: "Maryland", selected: false },
        { id: "21", name: "Massachusetts", selected: false },
        { id: "22", name: "Michigan", selected: false },
        { id: "23", name: "Minnesota", selected: false },
        { id: "24", name: "Mississippi", selected: false },
        { id: "25", name: "Missouri", selected: false },
        { id: "26", name: "Montana", selected: false },
        { id: "27", name: "Nebraska", selected: false },
        { id: "28", name: "Nevada", selected: false },
        { id: "29", name: "New Hampshire", selected: false },
        { id: "30", name: "New Jersey", selected: false },
        { id: "31", name: "New Mexico", selected: false },
        { id: "32", name: "New York", selected: false },
        { id: "33", name: "North Carolina", selected: false },
        { id: "34", name: "North Dakota", selected: false },
        { id: "35", name: "Ohio", selected: false },
        { id: "36", name: "Oklahoma", selected: false },
        { id: "37", name: "Oregon", selected: false },
        { id: "38", name: "Pennsylvania", selected: false },
        { id: "39", name: "Rhode Island", selected: false },
        { id: "40", name: "South Carolina", selected: false },
        { id: "41", name: "South Dakota", selected: false },
        { id: "42", name: "Tennessee", selected: false },
        { id: "43", name: "Texas", selected: false },
        { id: "44", name: "Utah", selected: false },
        { id: "45", name: "Vermont", selected: false },
        { id: "46", name: "Virginia", selected: false },
        { id: "47", name: "Washington", selected: false },
        { id: "48", name: "West Virginia", selected: false },
        { id: "49", name: "Wisconsin", selected: false },
        { id: "50", name: "Wyoming", selected: false },
    ];
    const [states] = useState(statesData);
    const [selectedState, setSelectedState] = useState('');
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };
    const [bills, setBills] = useState([
        {
            id: "1",
            billnumber: "SB 427",
            State: "California",
            title: "Health care coverage: antiretroviral drugs, drug devices, and drug products.",
            Status: "PASSED",
            Commitee: "Rules, Health, Appropriations",
            LatestAction: "09-14-2023",
            part: "2023-2024 Regular Session",
            type: "Senate",
            track: false
        },
        {
            id: "2",
            billnumber: "SB 524",
            State: "California",
            title: "Pharmacists: furnishing prescription medications.",
            Status: "REFERRED TO COMMITTEE",
            Commitee: "Rules, Health, Appropriations",
            LatestAction: "05-18-2023",
            part: "2023-2024 Regular Session",
            type: "Senate",
            track: false

        },
        {
            id: "3",
            billnumber: "SB 294",
            State: "California",
            title: "Health care coverage: independent medical review.",
            Status: "INTRODUCED",
            Commitee: "Rules, Governance And Finance, Health",
            LatestAction: "01-04-2024",
            part: "2023-2024 Regular Session",
            type: "Senate",
            track: false
        },
        {
            id: "4",
            billnumber: "AB 1180",
            State: "California",
            title: "Emergency medical services.",
            Status: "REFERRED TO COMMITTEE",
            Commitee: "Health, Appropriations",
            LatestAction: "05-18-2023",
            part: "2023-2024 Regular Session",
            type: "House",
            track: false
        },
        {
            id: "5",
            billnumber: "AB 532",
            State: "California",
            title: "Emergency medical services.",
            Status: "FIRST READING",
            Commitee: "No Committee",
            LatestAction: "02-09-2023",
            part: "2023-2024 Regular Session",
            type: "House",
            track: false
        },
        {
            id: "6",
            billnumber: "AB 677",
            State: "California",
            title: "Confidentiality of Medical Information Act.",
            Status: "FIRST READING",
            Commitee: "No Committee",
            LatestAction: "02-14-2023",
            part: "2023-2024 Regular Session",
            type: "House",
            track: false
        },
    ]);

    const handleTrack = (billnumber) => {
        setBills(prevBills => prevBills.map(bill =>
            bill.billnumber === billnumber ? { ...bill, track: !bill.track } : bill
        ));
    };

    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                <KTIcon iconName='book-square' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' style={{ backgroundColor: "#d9f2d9" }} /> State Bills
            </h1>
            <div className="d-flex flex-column-auto  align-items-center mt-4 mx-5 p-3">
                <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} placeholder={"Search State Bills"} />
                <StateSelector states={states} selectedState={selectedState} handleStateChange={handleStateChange} />

            </div>
            <></>
            <table className="table">
                <tbody>
                    {bills.map((bill) => (
                        <div
                            key={bill.billnumber}
                            ref={bill.billnumber === searchInput ? highlightedRef : null}
                            className="card card-custom mb-3" style={{ borderRadius: '10px' }}>
                            <div className="card-header d-flex justify-content-between align-items-center"
                                style={{ backgroundColor: bill.billnumber === searchInput ? "#d9f2d9" : "#f1faff" }}
                            >
                                <h2 className="card-title">{bill.billnumber}</h2>
                                <button
                                    className={`btn ${bill.track ? 'btn-success' : 'btn-primary'} h-26px w-140px btn-sm d-flex align-items-center justify-content-center`}
                                    onClick={() => handleTrack(bill.billnumber)}>
                                    <i className="bi bi-check-circle-fill"></i>
                                    {bill.track ? 'Tracking' : 'Track'}
                                </button>
                            </div>
                            <div className="card-body" style={{ fontSize: '15px' }}>
                                <p style={{ color: 'mediumblue' }}>{bill.Status}</p>
                                <p><KTIcon iconName={'geolocation'} className='fs-2' />{bill.State}</p>
                                <h3>Title: {bill.title}</h3>
                                <p>Committee: {bill.Commitee}</p>
                                <p>Latest Action Date: {bill.LatestAction}</p>
                                <p className='text-muted'>{bill.part}</p>
                            </div>
                        </div>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StateBills;

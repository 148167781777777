import { FC, useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { KTIcon } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';

//import TreeNode from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { TreeSelect, TreeSelectSelectionKeysType } from 'primereact/treeselect';
import { Modal, Button } from 'react-bootstrap';
import QASuggestions from '../Document/QASuggestions';


interface QAInterfaceProps {
    // documentid: string;
    onFirstQuestionAsked: (question: string) => void;
    // onConversationUpdate: (conversation: {}) => void;
    // isNewReport: boolean;
    // setIsNewReport: (isNewReport: boolean) => void;
    currentQuestion: string;
    // conversations: { [key: string]: { qaPairs: QAPair[], domains: string[] } };
}

interface Answer {
    text: string;
    sources: string[];
    valid: boolean;
}

interface QAPair {
    question: string;
    answer: Answer;
    engine: string;
    error: boolean;
}

interface CustomTreeNode {
    key: string;
    label: string;
    parent?: string;
    data?: string;
    children?: CustomTreeNode[];
}

const QAInterface: FC<QAInterfaceProps> = ({ onFirstQuestionAsked, currentQuestion }) => {

    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(currentQuestion !== '' ? true : false);
    const [sessionDomains, setSessionDomains] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<TreeSelectSelectionKeysType[]>([]);
    const [domainMap, setDomainMap] = useState<{ [key: string]: string }>({});
    const [dropdownOpen, setdropdownOpen] = useState(false);
    //const toggleDropdown = () => setdropdownOpen(!dropdownOpen);
    //const [domainOptions, setDomainOptions] = useState([]);
    const [treeData, setTreeData] = useState<CustomTreeNode[]>([]);
    //const [currentModel, setCurrentModel] = useState('perplexity');
    const currentModel = 'perplexity';
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [domainLink, setDomainLink] = useState('');
    const [loading, setLoading] = useState(false);
    // const [domainTitle, setDomainTitle] = useState('');
    //const [isLoading, setIsLoading] = useState<boolean>(false);

    // const handleChange = (event) => {
    //     const { value, checked } = event.target;

    //     // Handle "All" option separately
    //     if (value === 'All') {
    //         if (checked) {
    //             setSelectedDomains(domainOptions); // Select all options
    //         }
    //         else {
    //             setSelectedDomains([]); // Deselect all options
    //         }
    //     } else {
    //         setSelectedDomains((prevSelectedProductCodes) => {
    //             if (checked && !prevSelectedProductCodes.includes(value)) {
    //                 // If selecting an option and all other options are selected, add "All" to the selection
    //                 const allSelected = [...prevSelectedProductCodes, value].length === domainOptions.length;
    //                 return allSelected ? [...prevSelectedProductCodes, value, 'All'] : [...prevSelectedProductCodes, value];
    //             } else if (!checked) {
    //                 // If deselecting an option, also remove "All" from the selection
    //                 return prevSelectedProductCodes.filter((productCode) => productCode !== value && productCode !== 'All');
    //             }
    //             return prevSelectedProductCodes;
    //         });
    //     }
    // };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).catch(() => {
            alert('Failed to copy.');
        });
    };




    const handleTreeChange = (e) => {
        setSelectedDomains(e.value);
    };

    const dropdownRef2 = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setdropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    // useEffect(() => {
    //     const fetchDomains = () => {
    //         axios.get('/reg/v1/user/resources')
    //             .then(response => {
    //                 const filteredData = response.data;

    //                 // Transform the data into a tree structure
    //                 const treeData = filteredData.reduce((acc, curr) => {
    //                     const countryNode = acc.find(node => node.label === curr.country);
    //                     if (countryNode) {
    //                         countryNode.children.push({ key: curr.title, label: curr.title, data: curr.link });
    //                     } else {
    //                         acc.push({
    //                             key: curr.country,
    //                             label: curr.country,
    //                             children: [{ key: curr.title, label: curr.title, data: curr.link }]
    //                         });
    //                     }
    //                     return acc;
    //                 }, []);

    //                 // Sort the treeData array with USA first, EU second, and the rest in alphabetical order
    //                 treeData.sort((a, b) => {
    //                     if (a.label === 'USA') return -1;
    //                     if (b.label === 'USA') return 1;
    //                     if (a.label === 'European Union') return -1;
    //                     if (b.label === 'European Union') return 1;
    //                     return a.label.localeCompare(b.label);
    //                 });

    //                 setTreeData(treeData);

    //                 // Create a domain map with titles as keys and links as values
    //                 const domainMap = filteredData.reduce((map, curr) => {
    //                     map[curr.title] = curr.link;
    //                     return map;
    //                 }, {});

    //                 // Set the domain map
    //                 setDomainMap(domainMap);
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching domains:', error);
    //             });
    //     };
    //     fetchDomains();
    // }, []);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                // Fetch resources data
                const response1 = await axios.get('/reg/v1/user/resources');
                const filteredData1 = response1.data;

                // Fetch my domains data
                const response2 = await axios.get('/reg/v1/user/mydomains');
                const filteredData2 = response2.data;

                // Create an empty tree structure
                const treeData: CustomTreeNode[] = [];

                // Add "My Domains" node
                treeData.push({
                    key: 'my-domains',
                    label: 'My Domains',
                    children: [],
                    parent: ''
                });

                // Process my domains data
                filteredData2.forEach(myDomain => {
                    if (myDomain.is_active) {
                        treeData[0].children?.push({
                            key: myDomain.domain,
                            label: myDomain.domain,
                            data: myDomain.domain,
                            parent: 'My Domains', // Save parent label
                        });
                    }
                });

                // Process resources data
                filteredData1.forEach(resource => {
                    const countryNode = treeData.find((node: CustomTreeNode) => node.label === resource.country) as CustomTreeNode | undefined;
                    if (countryNode) {
                        const newNode = { key: resource.title, label: resource.title, data: resource.link, parent: countryNode.label }; // Save parent label
                        countryNode?.children?.push(newNode);
                    } else {
                        const newCountryNode = {
                            key: resource.country,
                            label: resource.country,
                            children: [{ key: resource.title, label: resource.title, data: resource.link, parent: resource.country }],
                            parent: ''
                        };
                        treeData.push(newCountryNode);
                    }
                });

                // Sort the treeData
                treeData.sort((a, b) => {
                    if (a.label === 'My Domains') return -1; // Move "My Domains" to the top
                    if (b.label === 'My Domains') return 1;
                    if (a.label === 'USA') return -1;
                    if (b.label === 'USA') return 1;
                    if (a.label === 'European Union') return -1;
                    if (b.label === 'European Union') return 1;
                    return a.label.localeCompare(b.label);
                });

                setTreeData(treeData);

                // Create domain map (optional)
                let domainMap = filteredData1.reduce((map, curr) => {
                    map[curr.title] = curr.link;
                    return map;
                }, {});

                domainMap = filteredData2.reduce((map, curr) => {
                    map[curr.domain] = curr.domain;
                    return map;
                }, {});

                setDomainMap(domainMap);
            } catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, []);

    const clearQuestion = () => {
        setQuestion('');
    }

    useEffect(() => {
        if (selectedDomains && typeof selectedDomains === 'object') {
            const sessionDomainLinks = Object.keys(selectedDomains)
                .filter((key) =>
                    selectedDomains[key].checked === true && // Check if the domain is checked
                    domainMap.hasOwnProperty(key) && // Check if the key exists in domainMap
                    !treeData.some((node: CustomTreeNode) => node.key === key) // Check if the key is not a category in treeData
                )
                .map((key) => domainMap[key]); // Map the filtered keys to their corresponding links
            setSessionDomains(sessionDomainLinks);
        } else {
            console.error('selectedDomains is not a valid object:', selectedDomains);
        }
    }, [selectedDomains, domainMap, treeData]);

    // useEffect(() => {
    //     console.log('Selected Domains:', selectedDomains);
    //     console.log('Domains:', sessionDomains);
    //     //console.log('domain map:', domainMap);
    // }, [selectedDomains, sessionDomains, domainMap]);

    const handleDownload = (question, answer, engine) => {
        // Ensure answer is an object for consistent data extraction
        if (typeof answer !== 'object') {
            console.error('Invalid answer format. Expected an object.');
            return;
        }

        // Construct answer content with clear formatting
        let answerContent = `Question: ${question}\n\nAnswer:\n${answer?.text}\n\n`;

        // Extract and format sources with numbered list
        if (engine === 'tavily') {
            answerContent += 'Sources:\n';
            if (answer.sources && Array.isArray(answer.sources) && answer.sources.length > 0) {
                answer.sources.forEach((source, index) => {
                    if (typeof source === 'object') {
                        answerContent += `${index + 1}. ${source.title || 'Source'} \n(${source.url || 'No URL'})\n`;
                        answerContent += `Relevant Content: "${source.content || 'No content'}"\n\n`;
                    } else {
                        answerContent += `${index + 1}. ${source}\n\n`;
                    }
                });
            } else {
                answerContent += 'No sources provided.\n';
            }
        }

        const blob = new Blob([answerContent], { type: 'text/plain' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = `${question}_answer.txt`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
    };


    const askQuestion = async (model: string = 'perplexity') => {
        const newQuestion = question;
        if (!firstQuestionAsked) {
            onFirstQuestionAsked(newQuestion);
            setFirstQuestionAsked(true);
            // setIsNewReport(false);
        }
        setTimeout(async () => {
            setQAPairs(prevQAPairs => [
                {
                    question: newQuestion,
                    answer: { text: 'Thinking ...', sources: [], valid: true },
                    engine: model,
                    error: false
                },
                ...prevQAPairs
            ]);
        }, 0);
        //setQuestion('');
        //
        try {
            const payload = {
                question: question,
                domains: sessionDomains
            };

            // Make a POST request to save the question and selected domains to the DB
            await axios.post('/reg/v1/user/myresearch', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error("Error Response:", error);
        }

        try {
            // const response = await fetch(`http://localhost:8000/answer-research-query/${model}`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            // });

            // const response = await fetch(`https://deepapi.lexim.ai/answer-research-query/${model}`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            // });

            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/answer-research-query/${model}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            });

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    // Once the stream is fully read, parse the JSON
                    const jsonResponse = JSON.parse(text);
                    setQAPairs(prevQAPairs => {
                        const newQAPairs = [...prevQAPairs];

                        // Ensure newQAPairs[0] exists
                        newQAPairs[0] = { question: newQuestion, answer: { text: 'Thinking ...', sources: [], valid: true }, engine: model, error: false };
                        // console.log('JSON Response:', jsonResponse);
                        // Safely assign values to answer
                        newQAPairs[0].question = newQuestion;
                        if (model === 'tavily') {
                            newQAPairs[0].answer.text = jsonResponse.answer || "No answer provided.";
                            newQAPairs[0].answer.sources = jsonResponse.results || [];
                        }
                        else {
                            newQAPairs[0].answer.text = jsonResponse.choices[0].message.content || "No answer provided.";
                            newQAPairs[0].answer.sources = [];
                        }
                        newQAPairs[0].engine = model;
                        newQAPairs[0].error = false;

                        return newQAPairs;
                    });
                    return;
                }
                text += new TextDecoder().decode(value);
                await readStream();
            };

            await readStream();

        } catch (error) {
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs];
                newQAPairs[0].question = newQuestion;
                newQAPairs[0].answer['text'] = (error as Error).message;
                newQAPairs[0].answer['sources'] = [];
                newQAPairs[0].error = true;
                return newQAPairs;
            });
        }
    };



    const handleDomainLinkChange = (event) => {
        setDomainLink(event.target.value);
    };

    // const handleDomainTitleChange = (event) => {
    //     setDomainTitle(event.target.value);
    // };

    const handleNewDomain = async () => {
        try {
            setLoading(true);
            const cleanedDomainLink = domainLink.replace(/^https?:\/\//, '');
            setDomainLink(cleanedDomainLink);
            // Clone the existing tree data to avoid mutating the state directly
            const newTreeData = [...treeData];

            // Find the "My Domains" node
            let myDomainsNode = newTreeData.find((node: CustomTreeNode) => node?.label === 'My Domains');

            // If "My Domains" doesn't exist, create it
            if (!myDomainsNode) {
                myDomainsNode = {
                    key: 'my-domains',
                    label: 'My Domains',
                    children: [],
                    parent: ''
                };
                newTreeData.push(myDomainsNode);
            }

            // Check if a domain with the same link already exists
            const existingDomain = myDomainsNode.children?.find(child => child.label === domainLink || child.key === domainLink);

            if (existingDomain) {
                setLoading(false);
                setModalMessage('Domain already exists with the given link.');
                setShowSuccessModal(true);
                return;
            }

            // Add the new domain as a child of "My Domains"
            myDomainsNode.children!.push({
                key: domainLink, // Use the domain itself as the key
                label: domainLink,
                data: domainLink,
                parent: 'My Domains'
            });

            // Update the tree data state with the modified version
            setTreeData(newTreeData);

            domainMap[domainLink] = domainLink;

            const payload = {
                domain: domainLink,
                is_active: true
            };

            // Make a POST request to save the question and selected domains to the DB
            await axios.post('/reg/v1/user/mydomains', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setShowModal(false);
            setModalMessage('New domain added successfully!');
            setShowSuccessModal(true);
            setDomainLink('');
            setLoading(false);
            // setDomainTitle('');

        } catch (error) {
            console.error("Error Response:", error);
            if (error instanceof Error) {
                setModalMessage(`Error adding new domain: ${error.message}`);
            } else {
                setModalMessage('Error adding new domain.');
            }
            setShowSuccessModal(true);
            setLoading(false);
        }
    };

    const handleDeleteDomain = async (domain: string) => {
        try {
            // Clone the existing tree data to avoid mutating the state directly
            const newTreeData = [...treeData];

            // Find the "My Domains" node
            let myDomainsNode = newTreeData.find((node: CustomTreeNode) => node?.label === 'My Domains');

            // If "My Domains" doesn't exist, create it
            if (!myDomainsNode) {
                myDomainsNode = {
                    key: 'my-domains',
                    label: 'My Domains',
                    children: [],
                    parent: ''
                };
                newTreeData.push(myDomainsNode);
            }

            // Remove the domain from the tree data
            myDomainsNode.children = myDomainsNode.children?.filter(child => child.label !== domain);

            // Update the tree data state with the modified version
            setTreeData(newTreeData);

            // Delete the domain from the domain map
            delete domainMap[domain];

            // Make a POST request to save the question and selected domains to the DB
            await axios.delete('/reg/v1/user/mydomains', {
                data: { domains: [domain] },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setModalMessage(`"${domain}" deleted successfully!`);
            setShowSuccessModal(true);

        } catch (error) {
            console.error("Error Response:", error);
            if (error instanceof Error) {
                setModalMessage(`Error deleting domain: ${error.message}`);
            } else {
                setModalMessage('Error deleting domain.');
            }
            setShowSuccessModal(true);
        }
    }

    const handleDeleteAllDomains = async () => {
        try {
            // Clone the existing tree data to avoid mutating the state directly
            const newTreeData = [...treeData];

            // Find the "My Domains" node
            let myDomainsNode = newTreeData.find((node: CustomTreeNode) => node?.label === 'My Domains');

            // If "My Domains" doesn't exist, create it
            if (!myDomainsNode) {
                myDomainsNode = {
                    key: 'my-domains',
                    label: 'My Domains',
                    children: [],
                    parent: ''
                };
                newTreeData.push(myDomainsNode);
            }

            // Save the domains to be deleted
            const domainsToDelete = myDomainsNode.children?.map(child => child.key) || [];

            // Remove all domains under "My Domains" from the tree data
            myDomainsNode.children = [];

            // Update the tree data state with the modified version
            setTreeData(newTreeData);

            // Delete the domains from the domain map
            const newDomainMap = { ...domainMap };
            domainsToDelete.forEach(domain => {
                delete newDomainMap[domain];
            });
            setDomainMap(newDomainMap);

            // Make a DELETE request to remove the domains from the backend
            await axios.delete('/reg/v1/user/mydomains', {
                data: { domains: domainsToDelete },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setModalMessage('All custom domains deleted successfully!');
            setShowSuccessModal(true);

        } catch (error) {
            console.error("Error Response:", error);
            if (error instanceof Error) {
                setModalMessage(`Error deleting all domains: ${error.message}`);
            } else {
                setModalMessage('Error deleting all domains.');
            }
            setShowSuccessModal(true);
        }
    }


    // useEffect(() => {
    //     if (firstQuestionAsked) {
    //         const updatedConversations = { ...conversations }; // Deep copy

    //         if (updatedConversations[currentQuestion]) {
    //             // Existing report: update existing entry
    //             updatedConversations[currentQuestion] = {
    //                 ...updatedConversations[currentQuestion],
    //                 qaPairs: [...qaPairs],
    //                 domains: [...sessionDomains]
    //             };
    //         } else {
    //             // New report: create a new entry
    //             updatedConversations[currentQuestion] = {
    //                 qaPairs: [...qaPairs],
    //                 domains: [...sessionDomains]
    //             };
    //         }

    //         onConversationUpdate(updatedConversations);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentQuestion, qaPairs, firstQuestionAsked]);

    // useEffect(() => {
    //     // console.log('useEffect triggered with currentQuestion:', currentQuestion);
    //     // console.log(conversations[currentQuestion]);
    //     setQAPairs(conversations[currentQuestion]?.qaPairs || []);
    //     if (conversations[currentQuestion]) {
    //         setSessionDomains(conversations[currentQuestion]?.domains || []);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentQuestion]);

    // useEffect(() => {
    //     if (isNewReport) {
    //         setQuestion('');
    //         //setQAPairs([]);
    //         setFirstQuestionAsked(false);
    //         setSessionDomains([]);
    //     }
    // }, [isNewReport, setIsNewReport]);

    return (
        
        <div className={`card-body`} style={{ maxHeight: 'calc(100vh)', width: '100%' }}>
            <>
            <QASuggestions setQuestion={setQuestion} askQuestion={askQuestion}/>
            </>
            <div>
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='d-flex position-relative' style={{ flex: 1 }}>
                        <textarea
                            className="form-control pe-16 text-dark"
                            placeholder={firstQuestionAsked ? "Please ask a follow-up question" : "Please ask a research question"}
                            value={question}
                            rows={3} // Specify the number of visible text lines
                            onChange={(e) => setQuestion(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && question.trim() !== '') {
                                    askQuestion(currentModel);
                                    e.preventDefault(); // Prevent form submission
                                }
                            }}
                            style={{ paddingRight: '50px', resize: 'none' }} // Make room for the icon
                        />
                        {question.trim() !== '' && (
                            <>
                                <div>
                                    <button onClick={clearQuestion} className='position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center' style={{ background: 'none', border: 'none' }}>
                                        <KTIcon
                                            iconName='cross'
                                            iconType="duotone"
                                            className='text-dark fs-1 mx-2 mt-2 me-12 icon-color'
                                        />
                                    </button>
                                    <div style={{ borderLeft: '1px solid #d3d3d3', height: '24px', position: 'absolute', top: '50%', transform: 'translateY(20%)', right: '36px' }}></div>
                                </div>

                                <button
                                    onClick={() => askQuestion(currentModel)}
                                    className='position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center'
                                    style={{ background: 'none', border: 'none' }}
                                    disabled={question.trim() === ''}
                                >
                                    <KTIcon
                                        iconName='arrow-right'
                                        iconType="duotone"
                                        className='text-dark fs-1 mx-2 mt-2 me-1 icon-color'
                                    />
                                </button>
                            </>
                        )}
                    </div>
                </div>

                {/* Dropdown and New Domain Button container */}
                <div className="mt-6" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TreeSelect
                        value={selectedDomains}
                        options={treeData}
                        onChange={handleTreeChange}
                        placeholder="Domains to Search"
                        className="text-dark fw-semibold me-3"
                        selectionMode="checkbox"
                        display="chip"
                        filter
                        panelStyle={{ maxWidth: '500px' }}
                        style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '0.25rem',
                            padding: '0.375rem 0.75rem',
                            cursor: 'pointer',
                            color: '#495057',
                            flex: 1
                        }}
                        nodeTemplate={(node) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <span>{node.label}</span>
                                {(node as CustomTreeNode).parent === 'My Domains' && (
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-link"
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => handleDeleteDomain((node as CustomTreeNode).label)}
                                    >
                                        <i className="bi bi-trash text-danger me-8"></i>
                                    </button>
                                )}
                                {(node as CustomTreeNode).label === 'My Domains' && (node as CustomTreeNode).children && (node as CustomTreeNode).children!.length > 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-link"
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => handleDeleteAllDomains()}
                                    >
                                        <span className="text-danger me-5">Delete All</span>
                                    </button>
                                )}
                            </div>
                        )}
                    />
                    <button type="button" className="btn btn-primary d-flex align-items-center ms-3" onClick={() => setShowModal(true)}>
                        <KTIcon iconName="plus" className="fs-2" />
                        New Domain
                    </button>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Domain</Modal.Title>
                </Modal.Header>
                {/* <div className='form-group px-10 pt-5 pb-5'>
                    <label htmlFor='domainTitle' className='form-label mb-1'>Title</label>
                    <input type='text' className='form-control' id='domainTitle' value={domainTitle} onChange={handleDomainTitleChange} />
                </div> */}
                <div className='form-group px-10 py-7'>
                    {/* <label htmlFor='domainLink' className='form-label mb-1'>Link</label> */}
                    <input
                        type='text'
                        className='form-control'
                        id='domainLink'
                        value={domainLink}
                        onChange={handleDomainLinkChange}
                        placeholder="www.fda.gov, www.cms.gov, etc."
                    />
                </div>
                <Modal.Footer>
                    <button type='button' className='btn btn-secondary' onClick={() => setShowModal(false)}>Close</button>
                    <button type='submit' className='btn btn-primary' disabled={loading} onClick={() => handleNewDomain()}>
                        {!loading && 'Save Changes'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton />
                <Modal.Body className="d-flex justify-content-center fs-2">
                    {modalMessage.includes('successfully!') ? (
                        <div>
                            <i className="bi bi-check-circle-fill text-success fs-1 me-3"></i>
                            {modalMessage}
                        </div>
                    ) : (
                        <div>
                            <i className="bi bi-x-circle text-danger fs-1 me-3"></i>
                            {modalMessage}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='mb-5 mt-8' style={{ maxHeight: 'calc(80vh)', overflowY: 'auto' }}>

                {qaPairs.map((qaPair, index) => (
                    <div key={index} className={`d-flex flex-column ${index !== 0 ? 'mt-3' : ''}`}>
                        <div className={`d-flex justify-content-start ${index !== 0 ? 'mt-2' : ''} mb-2`}>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-primary '}`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                {qaPair.answer['text'] !== 'Thinking ...' && (
                                    <div className="d-flex justify-content-end">
                                        <div className='px-5' >
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Copy Message</Tooltip>}
                                            >
                                            <div
                                                onClick={() => copyToClipboard(qaPair.answer.text)}
                                                style={{ cursor: 'pointer' }}
                                                title="Copy to clipboard"
                                            >
                                                <KTIcon iconName="copy" iconType="duotone" className="fw-bold fs-1" />
                                            </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}
                                            >
                                                <i
                                                    className="bi bi-download text-primary text-bold fs-1 me-1"
                                                    onClick={() => handleDownload(qaPair.question, qaPair.answer, qaPair.engine)}
                                                ></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                )}
                                <h1 className='text-dark fw-bold mb-5' style={{ fontSize: '1.5rem' }}>{qaPair.question}</h1>
                                {qaPair.answer['text'] === 'Thinking ...' ?
                                    <>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </>
                                    :
                                    <div>
                                        {/* {qaPair.engine && (
                                            <div>
                                                <ReactMarkdown className="mt-n3 mb-5">
                                                    {`Response generated by ${qaPair.engine.charAt(0).toUpperCase() + qaPair.engine.slice(1).toLowerCase()} AI`}
                                                </ReactMarkdown>
                                            </div>
                                        )} */}
                                        <h3 className='fw-bold mb-3 text-dark'>Answer:</h3>
                                        <ReactMarkdown className="mb-5">{qaPair.answer.text}</ReactMarkdown>
                                        

                                        {qaPair.engine === 'tavily' && (
                                                <div>
                                                    <h3 className="fw-bold mb-3 text-dark">Sources:</h3>
                                                    <ReactMarkdown>
                                                        {qaPair.answer.sources && qaPair.answer.sources.length > 0
                                                            ? qaPair.answer.sources.map(source => `* **${source['title']}**\n\n\t (${source['url']})`).join('\n\n')
                                                            : 'No sources provided.'
                                                        }
                                                    </ReactMarkdown>
                                                </div>
                                                
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { QAInterface };
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { useAuth } from '../../modules/auth';
import axios from 'axios';
import { FilesTablePagination } from './FilesTablePagination';
import Modal from 'react-modal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import MyDocumentsToolbar from './MyDocumentsToolbar';
import UploadSuccessModal from './UploadSuccessModal';
import MyDocumentsTable from './MyDocumentsTable';
import clsx from 'clsx';
import DocDiffTable from './DocDiff/DocDiffTable';
import CompareDocumentModal from './CompareDocumentModal';

Modal.setAppElement('#root');
const PAGINATION_PAGES_COUNT = 10;

const MyDocuments = () => {
    const [uploadedFiles, setUploadedFiles] = useState<{ id: number, name: string, description: string, s3_link: string, create_date: Date, doc_name: string }[]>([]);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [filteredFiles, setFilteredFiles] = useState(uploadedFiles);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [isUploaded, setIsUploaded] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingFile, setEditingFile] = useState<{ id: number, name: string, description: string, s3_link: string, create_date: Date } | null>(null);
    const totalPages = Math.ceil(uploadedFiles.length / PAGINATION_PAGES_COUNT);
    const [description, setDescription] = useState(editingFile ? editingFile.description : '');
    const [name, setName] = useState(editingFile ? editingFile.name : '');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [prevMessage, setPrevMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [diffFiles, setDiffFiles] = useState([]);
    const [activeTab, setActiveTab] = useState('uploadDocuments');
    const [compareIsOpen, setCompareIsOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [title1, setTitle1] = useState<string | undefined>('');
    const [title2, setTitle2] = useState<string | undefined>('');
    const [filename1, setFilename1] = useState<string | undefined>('');
    const [filename2, setFilename2] = useState<string | undefined>('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [diffToDelete, setDiffToDelete] = useState<{} | null>(null);
    const [diffDeleteConfirmation, setDiffDeleteConfirmation] = useState(false);

    const [documentToDelete, setDocumentToDelete] = useState<{ id: number, name: string, description: string, s3_link: string, create_date: Date } | null>(null);
    const [filters, setFilters] = useState<{ [key: string]: string }>({
        fileName: '',
        startDate: '',
        endDate: '',
    });
    const startIndex = (currentPage - 1) * PAGINATION_PAGES_COUNT;
    const endIndex = startIndex + PAGINATION_PAGES_COUNT;

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);

    const fetchFiles = useCallback(() => {
        setIsLoading(true);
        setSelectedFiles([]);
        axios.get(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`)
            .then(response => {
                const files = response.data.map((file: any) => ({
                    id: file.mv_document_id,
                    name: file.file_name,
                    description: file.description,
                    doc_name: file.title,
                    s3_link: file.s3_link,
                    in_elastic: file.in_elastic,
                    create_date: new Date(file.uploaded_on)
                }));
                setUploadedFiles(files);
                setFilteredFiles(files);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsLoading(false);
            });
    }, [currentUser?.id, currentUser?.organization]); // Add any dependencies here

    const fetchCompareRequests = useCallback(() => {
        axios.get(`/reg/v1/document/diff/${currentUser?.organization}`)
            .then(response => {
                setDiffFiles(response.data);
            }).catch(error => {
                console.error('Failed to fetch compare requests:', error);
            });
    }, [currentUser?.organization]);

    useEffect(() => {
        fetchCompareRequests();
    }, [fetchCompareRequests]);

    useEffect(() => {
        if (message === '' && !prevMessage.includes("Failed")) {
            fetchFiles();
        }
    }, [fetchFiles, message, prevMessage]);

    const handleSelectionChange = (fileId, isChecked) => {
        // console.log(fileId, isChecked);
        if (isChecked) {
            setSelectedFiles(prev => [...prev, fileId]);
        } else {
            setSelectedFiles(prev => prev.filter(f => f !== fileId));
        }
        // console.log(selectedFiles);
    };

    const compareOnClose = () => {
        setCompareIsOpen(false);
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!isUploaded) {
            setMessage('');
        }
    }, [isUploaded]);

    useEffect(() => {
        // If the search input is empty, set filteredFiles to all uploadedFiles
        if ((searchInput || '').trim() === '') {
            setFilteredFiles(uploadedFiles);
        } else {
            // Otherwise, filter uploadedFiles based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = uploadedFiles.filter((file) => {
                return (
                    (file.name && file.name.toLowerCase().includes(searchTerm)) ||
                    (file.description && file.description.toLowerCase().includes(searchTerm))
                    // Add more fields as needed
                );
            });
            setFilteredFiles(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);



    const handleFilterChange = (key: string, value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value
        }));
    }

    const handleApplyFilters = () => {
        let filtered = uploadedFiles.filter((file) => {
            const fileDate = new Date(file.create_date).getTime();
            const startDate = filters.startDate ? new Date(filters.startDate).getTime() : null;
            // Adjust the endDate to the end of the day
            const endDate = filters.endDate ? new Date(filters.endDate + "T23:59:59").getTime() : null;

            return (!filters.fileName || file.name.includes(filters.fileName)) &&
                (!startDate || fileDate >= startDate) &&
                (!endDate || fileDate <= endDate);
        });
        setFilteredFiles(filtered);
    };

    const handleResetFilters = () => {
        setFilters({
            fileName: '',
            startDate: '',
            endDate: ''
        });
        setFilteredFiles(uploadedFiles);
    };

    const handleHeaderClick = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isUploaded) {
            timer = setTimeout(() => {
                setIsUploaded(false);
            }, 15000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isUploaded]);

    const handleDelete = (document: { id: number, name: string, description: string, s3_link: string, create_date: Date } | null) => {
        if (document === null) return;

        axios.delete(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, { data: { mv_document_id: document.id } })
            .then(response => {
                fetchFiles();
                // setDeleteConfirmation(false);
                setDocumentToDelete(null);
                setDeleteSuccess(true); // Set deleteSuccess to true when the deletion is successful
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const handleDiffDelete = (diff: { diff_id: number } | null) => {
        console.log("check to delete:", diff);
        axios.patch(`/reg/v1/document/diff/${currentUser?.organization}`, { diff_id: diff })
            .then(response => {
                console.log("response:", response);
                fetchCompareRequests();
                setDiffToDelete(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (deleteSuccess) {
            timer = setTimeout(() => {
                setDeleteSuccess(false);
            }, 15000); // 15 seconds
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [deleteSuccess]);

    const compareDocuments = async () => {
        if (selectedFiles.length === 2) {
            setShowAlert(false);
            setFilename1(uploadedFiles.find(file => file.id === selectedFiles[0])?.name);
            setTitle1(uploadedFiles.find(file => file.id === selectedFiles[0])?.doc_name);
            setFilename2(uploadedFiles.find(file => file.id === selectedFiles[1])?.name);
            setTitle2(uploadedFiles.find(file => file.id === selectedFiles[1])?.doc_name);
            const response = await axios.post(`/reg/v1/document/diff/${currentUser?.organization}`, {
                doc_id_1: selectedFiles[0],
                doc_id_2: selectedFiles[1]
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                setIsCompleted(response.data[0].isCompleted);
                if (response.data[0].isCompleted) {
                    setUrl(response.data[0].url);
                    setCompareIsOpen(true);
                }
                else {
                    setUrl('');
                    setCompareIsOpen(true);
                }
                fetchCompareRequests();
            }
        } else {
            setShowAlert(true);
            // return(
            //     <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
            //         <i className="bi bi-info fs-2 me-2"></i>
            //         Please select exactly two files to compare.
            //     </div>
            // )
            // alert("Please select exactly two files to compare.");
        }
    };

    const updateDocument = async (documentId: number, newName: string, newDescription: string) => {
        try {
            const response = await axios.patch(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, {
                mv_document_id: documentId,
                file_name: newName,
                title: newDescription,
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        } catch (error) {
            console.error('Failed to update document:', error);
        }
    }


    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='some-files' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                My Documents
                                {isLoading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className='card card-custom'>
                    <div className='card-header card-header-stretch'>
                        <ul
                            className='nav nav-stretch nav-line-tabs
                            nav-line-tabs-2x
                            border-transparent
                            flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': activeTab === 'uploadDocuments' })}
                                    onClick={() => setActiveTab('uploadDocuments')}
                                    role='tab'
                                >
                                    Upload Documents
                                </h5>
                            </li>
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': activeTab === 'compareDocuments' })}
                                    onClick={() => setActiveTab('compareDocuments')}
                                    role='tab'
                                >
                                    {/* <KTIcon iconName='information' className='fs-5 me-1 text-primary' />  */}
                                    Compare Documents
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: activeTab === 'uploadDocuments' })}>
                                <UploadSuccessModal isOpen={isUploaded} onRequestClose={() => { setIsUploaded(false); setPrevMessage(message); setMessage(''); }} message={message} />
                                <MyDocumentsToolbar
                                    isLoading={isLoading}
                                    handleOpenModal={handleOpenModal}
                                    isModalOpen={isModalOpen}
                                    handleCloseModal={handleCloseModal}
                                    setIsUploaded={setIsUploaded}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    filters={filters}
                                    handleFilterChange={handleFilterChange}
                                    handleApplyFilters={handleApplyFilters}
                                    handleResetFilters={handleResetFilters}
                                    setMessage={setMessage}
                                />
                                {showAlert &&
                                    <div className={`alert alert-danger alert-dismissible fade show mt-3 d-flex justify-content-between`} role="alert">
                                        Please select exactly two (2) files to compare.
                                        <div className='justify-content-end' style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-x-circle-fill fs-2 ms-2 text-end" onClick={() => setShowAlert(false)}></i>
                                        </div>
                                    </div>
                                }
                                {!isLoading &&
                                    <div>
                                        <MyDocumentsTable
                                            filteredFiles={filteredFiles}
                                            selectedFiles={selectedFiles}
                                            sortConfig={sortConfig}
                                            handleHeaderClick={handleHeaderClick}
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            navigate={navigate}
                                            editingFile={editingFile}
                                            setEditingFile={setEditingFile}
                                            setName={setName}
                                            setDescription={setDescription}
                                            name={name}
                                            description={description}
                                            currentUser={currentUser}
                                            updateDocument={updateDocument}
                                            compareDocuments={compareDocuments}
                                            fetchFiles={fetchFiles}
                                            setDeleteConfirmation={setDeleteConfirmation}
                                            setDocumentToDelete={setDocumentToDelete}
                                            handleSelectionChange={handleSelectionChange}
                                        />
                                        {totalPages > 1 && (
                                            <FilesTablePagination
                                                totalPages={totalPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        )}
                                        <DeleteConfirmationModal
                                            isOpen={deleteConfirmation}
                                            onRequestClose={() => {
                                                setDeleteConfirmation(false);
                                                setMessage('');
                                                setDocumentToDelete(null);
                                            }}
                                            onDelete={(document) => {
                                                handleDelete(document);
                                                setDeleteConfirmation(false);
                                            }}
                                            documentToDelete={documentToDelete}
                                        />
                                    </div>
                                }
                                {compareIsOpen &&
                                    <CompareDocumentModal compareIsOpen={compareIsOpen} compareOnClose={compareOnClose} isCompleted={isCompleted} title1={title1} filename1={filename1} title2={title2} filename2={filename2} s3_link={url} setActiveTab={setActiveTab} />
                                }
                            </div>
                            <div className={clsx('tab-pane', { active: activeTab === 'compareDocuments' })}>
                                <DocDiffTable diffFiles={diffFiles} uploadedFiles={uploadedFiles} setActiveTab={setActiveTab} setDiffDeleteConfirmation={setDiffDeleteConfirmation} setDiffToDelete={setDiffToDelete} />
                                <DeleteConfirmationModal
                                    isOpen={diffDeleteConfirmation}
                                    onRequestClose={() => {
                                        setDiffDeleteConfirmation(false);
                                        setMessage('');
                                        setDiffToDelete(null);
                                    }}
                                    onDelete={(document) => {
                                        handleDiffDelete(document);
                                        setDiffDeleteConfirmation(false);
                                    }}
                                    documentToDelete={diffToDelete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyDocuments;
// TableRowComponent.jsx
import React from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
type Recall = {
    fulldate: string;
    openfda_device_class: string;
    openfda_device_name: string;
    openfda_medical_specialty_description: string;
    product_code: string;
    product_description: string;
    product_res_number: string;
    recall_count: string;
    recall_status: string;
    recalling_firm: string;
    res_event_number: string;
    root_cause_description: string;
    initiated_date: string;
    action: string;
    reason_for_recall: string;
    distribution_pattern: string;
    regulation_number: string;
    posted_date

}
interface TableRowRecallsProps {
    items: Recall[];
}

const TableRowRecalls: React.FC<TableRowRecallsProps> = React.memo(({ items }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedRecall, setSelectedRecall] = useState<Recall>();

    const handleClose = () => {
        setShowModal(false);
        setSelectedRecall(undefined);
    };

    const handleShow = (recall) => {
        setSelectedRecall(recall);
        setShowModal(true);
    };
    return (
        <>
            {items.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-dark fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (items.map((item: Recall, index) => (
                <tr className='bg-hover-light-primary' key={index} onClick={() => handleShow(item)}>
                    <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                        {item.openfda_device_name}
                    </td>
                    {/* <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.id}
                </td> */}
                    <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                        {item.recalling_firm}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.openfda_medical_specialty_description}
                    </td>
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.product_code}
                    </td>
                    <td className='text-gray-800 fw-bold text-center text-hover-dark mb-1 fs-6 mt-2 text-wrap'>
                        {item.openfda_device_class}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.root_cause_description}
                    </td>

                    <td className='text-center text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.product_res_number}
                    </td>
                    <td className='text-gray-800 text-end fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {new Date(item.initiated_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>
                    {/* <td className='text-end'>
                            <Button variant="" className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary text-primary' onClick={() => handleShow(item)} style={{ cursor: 'pointer' }}>
                              Details
                            </Button>
                          </td> */}
                </tr>
            )))}

            <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="custom-modal-size"
                size="lg"
                centered
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-4 text-gray-600'>Recall Details for Recall: {selectedRecall?.product_res_number}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='fs-6 text-gray-700' style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>


                        <div className="row">
                            <div className="col-12">
                                {selectedRecall?.openfda_device_name && <p><strong>Device Name:</strong> {selectedRecall?.openfda_device_name}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedRecall?.recalling_firm && <p><strong>Recalling Firm:</strong> {selectedRecall?.recalling_firm}</p>}
                            </div>
                        </div>
                        {selectedRecall?.product_description && <p><strong>Product Description:</strong> {selectedRecall?.product_description}</p>}
                        {selectedRecall?.product_code && <p><strong>Product Code:</strong> {selectedRecall?.product_code}</p>}

                        {selectedRecall?.openfda_device_class && <p><strong>Device Class:</strong> {selectedRecall?.openfda_device_class}</p>}

                        <div className="row">
                            <div className="col-12">
                                {selectedRecall?.reason_for_recall && <p><strong>Reason for recall:</strong> {selectedRecall?.reason_for_recall}</p>}
                            </div>

                        </div>
                        {selectedRecall?.action && <p><strong>Action:</strong> {selectedRecall?.action}</p>}
                        {selectedRecall?.distribution_pattern && <p><strong>Distribution Pattern:</strong> {selectedRecall?.distribution_pattern}</p>}
                        {selectedRecall?.recall_status && <p><strong>Recall Status:</strong> {selectedRecall?.recall_status}</p>}
                        <div className="row">

                            <div className="col-12">
                                {selectedRecall?.regulation_number && <p><strong>Regulation Number:</strong> {selectedRecall?.regulation_number}</p>}
                            </div>
                        </div>
                        {/* {selectedRecall?.distribution_pattern && <p><strong>Distribution Pattern:</strong> {selectedRecall?.distribution_pattern}</p>} */}
                        <div className="row">
                            <div className="col-12">
                                {selectedRecall?.root_cause_description && <p><strong>Root Cause:</strong> {selectedRecall?.root_cause_description}</p>}

                            </div>

                        </div>
                        {selectedRecall?.posted_date && <p><strong>Posted Date: </strong>{new Date(selectedRecall.posted_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>}
                        {selectedRecall?.initiated_date && <p><strong>Initiated Date: </strong> {new Date(selectedRecall.initiated_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>}



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal >
        </>
    );
});

export default TableRowRecalls;
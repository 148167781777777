// TableRowComponent.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';


type Document = {
    advisory_committee_description: string;
    applicant: string;
    decision_date: string;
    generic_name: string;
    openfda_device_class_id: string;
    pma_number: string;
    product_code: string;
    recall_count: string;
    state: string;
    supplement_number: string;
    trade_name: string;


}
interface TableRowPMAProps {
    items: Document[];
}

const TableRowPMA: React.FC<TableRowPMAProps> = React.memo(({ items }) => {
    const navigate = useNavigate();
    const replacements = {
        '': '™',
        '': '"',
        '': '"',
        '®': '®',
        '©': '©',
        '': '—',
        '': '–',
        '': '•',
        '': '\'',
        '': '\'',
        '': '…',
        '': '¨',
        '': '‰',
        '': '‹',
        '': '›',
        '': '«',
        '': '»',
    }
    return (
        <>
            {items?.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-gray-600 fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (items?.map((item: Document, index) => (
                <tr className='bg-hover-light-primary' key={index} onClick={() => navigate(`/device-intelligence/pma/${item.pma_number}`, { state: { prevPage: 'home' } })}>
                    <td className='text-gray-800 fw-bold text-hover-dark text-hover-primary fs-6 mt-2'>
                        {item.pma_number}
                    </td>

                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {Object.keys(replacements).reduce((str, key) => {
                            return str.replace(new RegExp(key, 'g'), replacements[key]);
                        }, item?.trade_name || '')}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.applicant}
                    </td>
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.advisory_committee_description}
                    </td>
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.product_code}
                    </td>
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.openfda_device_class_id}
                    </td>
                    {/* <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                                                        {item.program_id}
                                                    </td> */}





                    <td className='text-gray-800 fw-bold text-end text-hover-dark mb-1 fs-6 mt-2'>
                        {new Date(item?.decision_date)?.toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>


                </tr>
            )))}
        </>
    );
});

export default TableRowPMA;
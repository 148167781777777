import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import DataOpsToolbar from "./DataOpsToolbar";
import clsx from 'clsx';

const RegulatoryDetails = () => {
    const { guid, type } = useParams();
    const [runHistory, setRunHistory] = useState(null);
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);
    const [histories, setHistory] = useState([]);
    const [currentHistory, setCurrentHistory] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        id: '',
        title: '',
        program: '',
        type: ''
    });

    const objectTypesConfig = {
        1: {
            headers: ['Docket ID', 'Program', 'Docket Type', 'Docket Title'],
            attributes: ['id', 'program', 'docketType', 'title'],
            widths: ['13%', '12%', '12%', '56%']
        },
        2: {
            headers: ['Document ID', 'Program', 'Document Type', 'Document Title'],
            attributes: ['id', 'program', 'documentType', 'title'],
            widths: ['18%', '13%', '14%', '53%']
        },
        3: {
            headers: ['Comment ID', 'Program', 'Category', 'Comment'],
            attributes: ['id', 'program', 'category', 'comment'],
            widths: ['17%', '13%', '15%', '53%']
        },
        4: {
            headers: ['Attachment ID', 'Comment ID', 'Program', 'Attachment Title'],
            attributes: ['id', 'commentId', 'program', 'title'],
            widths: ['16%', '16%', '9%', '55%']
        },
        5: {
            headers: ['Attachment ID', 'Document ID', 'Program', 'Attachment Title'],
            attributes: ['id', 'docId', 'program', 'title'],
            widths: ['16%', '16%', '9%', '55%']
        },
        8: {
            headers: ['Document ID', 'Program', 'Type', 'Document Title'],
            attributes: ['id', 'program', 'subtype', 'title'],
            widths: ['18%', '13%', '14%', '53%']
        }
    };

    const firstAttribute = objectTypesConfig[1].attributes[0];
    const [sortConfig, setSortConfig] = useState({ key: firstAttribute, direction: 'desc' });

    const typeNames = {
        1: 'Dockets',
        2: 'Documents',
        3: 'Comments',
        4: 'Comment Attachments',
        5: 'Document Attachments',
        8: 'Guidance Documents'
    };

    const tableStyle = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    };

    const fetchHistory = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/reg/v1/superadmin/run_history/${guid}/${type}`);
            let details = JSON.parse(response.data.details);
            const runHistory = JSON.parse(response.data.run_history);
            // console.log('History:', details)
            setRunHistory(runHistory[0]);
            // console.log('Run History:', runHistory)

            // Sort the details
            details = details.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });

            setHistory(details);
            setFilteredHistory(details);
        } catch (error) {
            console.error('Error fetching histories:', error);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchHistory();
        // eslint-disable-next-line
    }, [guid, type]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }, [currentPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(filteredHistory.length / itemsPerPage));
    }, [filteredHistory, itemsPerPage]);

    useEffect(() => {
        setCurrentHistory(filteredHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredHistory, currentPage, itemsPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleHeaderClick = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const parseDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { timeZone: 'UTC' });
    };

    const handleFilterChange = (filter, value) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = histories;
        if (filters.program !== "") {
            filteredData = filteredData.filter(history =>
                history.program.toLowerCase().includes(filters.program.toLowerCase())
            );
        }

        if (filters.title !== "") {
            filteredData = filteredData.filter(history =>
                history.title.toLowerCase().includes(filters.title.toLowerCase())
            );
        }

        if (Number(type) === 1 || Number(type) === 2 || Number(type) === 8) {
            if (filters.type !== "") {
                const typeAttribute = objectTypesConfig[Number(type)].attributes.find(attr => attr.toLowerCase().includes('type'));

                if (typeAttribute) {
                    filteredData = filteredData.filter(history =>
                        history[typeAttribute].toLowerCase().includes(filters.type.toLowerCase())
                    );
                }
            }
        }

        if (Number(type) === 3) {
            if (filters.type !== "") {
                filteredData = filteredData.filter(history =>
                    history.category.toLowerCase().includes(filters.type.toLowerCase())
                );
            }
        }

        if (filters.id !== "") {
            if (Number(type) !== 3 && Number(type) !== 4) {
                filteredData = filteredData.filter(history =>
                    history.id.toLowerCase().includes(filters.id.toLowerCase())
                );
            } else {
                const secondAttribute = objectTypesConfig[Number(type)].attributes[1];
                filteredData = filteredData.filter(history =>
                    history[secondAttribute].toLowerCase().includes(filters.id.toLowerCase())
                );
            }
        }


        if (searchInput !== "") {
            const searchInputLower = searchInput.toLowerCase();
            filteredData = filteredData.filter(history =>
                objectTypesConfig[type].attributes.some(attribute =>
                    history[attribute]?.toString().toLowerCase().includes(searchInputLower)
                )
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        setLoading(false);
    }

    const handleResetFilters = () => {
        setFilters({
            id: '',
            title: '',
            program: '',
            type: ''
        });
        setFilteredHistory(histories);
    }

    useEffect(() => {
        let filteredData = histories;

        if (filters.program !== "") {
            filteredData = filteredData.filter(history =>
                history.program.toLowerCase().includes(filters.program.toLowerCase())
            );
        }

        if (filters.title !== "") {
            filteredData = filteredData.filter(history =>
                history.title.toLowerCase().includes(filters.title.toLowerCase())
            );
        }

        if (Number(type) === 1 || Number(type) === 2 || Number(type) === 8) {
            if (filters.type !== "") {
                const typeAttribute = objectTypesConfig[Number(type)].attributes.find(attr => attr.toLowerCase().includes('type'));

                if (typeAttribute) {
                    filteredData = filteredData.filter(history =>
                        history[typeAttribute].toLowerCase().includes(filters.type.toLowerCase())
                    );
                }
            }
        }

        if (Number(type) === 3) {
            if (filters.type !== "") {
                filteredData = filteredData.filter(history =>
                    history.category.toLowerCase().includes(filters.type.toLowerCase())
                );
            }
        }

        if (filters.id !== "") {
            if (Number(type) !== 3 && Number(type) !== 4) {
                filteredData = filteredData.filter(history =>
                    history.id.toLowerCase().includes(filters.id.toLowerCase())
                );
            } else {
                const secondAttribute = objectTypesConfig[Number(type)].attributes[1];
                filteredData = filteredData.filter(history =>
                    history[secondAttribute].toLowerCase().includes(filters.id.toLowerCase())
                );
            }
        }


        if (searchInput !== "") {
            const searchInputLower = searchInput.toLowerCase();
            filteredData = filteredData.filter(history =>
                objectTypesConfig[type].attributes.some(attribute =>
                    history[attribute]?.toString().toLowerCase().includes(searchInputLower)
                )
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    useEffect(() => {
        const sortedHistory = [...filteredHistory].sort((a, b) => {
            if (a[sortConfig.key] === null) return sortConfig.direction === 'asc' ? 1 : -1;
            if (b[sortConfig.key] === null) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredHistory(sortedHistory);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);



    return (
        <div>
            <div className="mt-n4 ms-1 pb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWguidth: '100%' }}>
                <div style={{ marginRight: '20px', wguidth: '250px' }}>
                    <Link
                        to={{
                            pathname: "/super-admin/data-operations/regulatory"
                        }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Regulations Run History
                    </Link>
                </div>
            </div>
            <div className='card mt-4 mb-4'>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading &&
                                <div>
                                    <div className="d-flex align-items-center ">
                                        <h4 className='text-gray-700 fw-bold'>Run GUID: {guid}</h4>
                                    </div>
                                    {runHistory && (
                                        <>
                                            <div className="d-flex align-items-center mb-n2">
                                                <h5 className="card-title text-gray-600" >Object Type: <span className="" >{typeNames[type]}</span></h5>
                                                <span className="card-title px-2" >|</span>
                                                <h5 className="card-title text-gray-600">Run Date: <span className="">{parseDate(runHistory.run_date)}</span></h5>
                                            </div>
                                            {/* <div className="d-flex align-items-center mb-n2">
                                                <h5 className="card-title text-gray-600">Start Date: <span className="">{parseDate(runHistory.start_date)}</span></h5>
                                                <span className="card-title px-2" >|</span>
                                                <h5 className="card-title text-gray-600">End Date: <span className="">{parseDate(runHistory.end_date)}</span></h5>
                                            </div> */}
                                        </>
                                    )}
                                </div>
                            }
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Loading Run History Details...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                View PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div>
                                    <DataOpsToolbar
                                        isLoading={loading}
                                        searchInput={searchInput}
                                        setSearchInput={setSearchInput}
                                        filters={filters}
                                        activeTab="reg-details"
                                        handleFilterChange={handleFilterChange}
                                        handleApplyFilters={handleApplyFilters}
                                        handleResetFilters={handleResetFilters}
                                        objType={Number(type)}
                                    />
                                    <div className='table-responsive mb-5'>
                                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                                            style={tableStyle}>
                                            <thead className="text-muted fw-bolder fs-7 gs-0">
                                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200 text-start'>
                                                    {objectTypesConfig[type].headers.map((header, index) => (
                                                        <th key={index} className={`${header === 'Program' ? 'text-center' : header === 'Actions' ? 'text-end me-5' : 'text-start'}`} style={{ whiteSpace: 'nowrap', width: objectTypesConfig[type].widths[index], cursor: 'pointer' }} onClick={() => handleHeaderClick(objectTypesConfig[type].attributes[index])}>
                                                            {header}
                                                            {header !== 'Actions' && (
                                                                <span className={`sorting-icon ${sortConfig.key === objectTypesConfig[type].attributes[index] ? sortConfig.direction : ''}`}>
                                                                    {' '}<i className={`bi ${sortConfig.key === objectTypesConfig[type].attributes[index] ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === objectTypesConfig[type].attributes[index] ? 'visible' : 'hidden' }}></i>
                                                                </span>
                                                            )}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            {isLoading ? (
                                                <tbody className='text-gray-600 fw-bold'>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                Loading...
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody className='text-gray-600 fw-bold'>
                                                    {currentHistory.length === 0 ? (
                                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                                            <td colSpan={4} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found. Please try again.</td>
                                                        </tr>
                                                    ) : (
                                                        currentHistory.map((history) => (
                                                            <tr key={history.id} className='align-items-center bg-hover-light-primary' style={{ cursor: 'default' }}>
                                                                {objectTypesConfig[type].attributes.map((attribute, index) => {
                                                                    let content = history[attribute];
                                                                    if (objectTypesConfig[type].headers[index] === 'Document ID') {
                                                                        content = <Link to={`/document/${history[attribute]}`}>{history[attribute]}</Link>;
                                                                    }
                                                                    if (attribute === 'comment') {
                                                                        content = (
                                                                            <div style={{
                                                                                display: '-webkit-box',
                                                                                WebkitBoxOrient: 'vertical',
                                                                                WebkitLineClamp: 3,
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis'
                                                                            }}>
                                                                                {history[attribute]}
                                                                            </div>
                                                                        );
                                                                    }
                                                                    // } else if (objectTypesConfig[type].headers[index] === 'Comment ID') {
                                                                    //     content = <Link to={`/comment/${history[attribute]}`}>{history[attribute]}</Link>;
                                                                    // }
                                                                    return (
                                                                        <td key={index} className={`text-${attribute === 'program' ? 'center' : 'start'}`} style={{ whiteSpace: 'normal' }}>
                                                                            {content}
                                                                        </td>
                                                                    );
                                                                })}
                                                                {/* <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6 me-5' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/super-admin/data-operations/regulatory/${history.run_guid}/${history.object_type}`,
                                                                        }}
                                                                        state={{ runDate: history.run_date }}
                                                                    >
                                                                        Details
                                                                    </Link>
                                                                </td> */}
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    {totalPages > 1 &&
                                        <div className='card-footer mb-n7'>
                                            <div className='row'>
                                                <div className='d-flex justify-content-center mt-2'>
                                                    <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                                        <ul className='pagination justify-content-center'>
                                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                <button className='page-link' onClick={prevPage} disabled={currentPage === 1}>
                                                                    Previous
                                                                </button>
                                                            </li>
                                                            {Array.from({ length: totalPages }, (_, i) => i + 1)
                                                                .slice(
                                                                    currentPage <= 3 ? 0 : currentPage - 3,
                                                                    currentPage <= 3 ? 5 : currentPage + 2
                                                                )
                                                                .map(pageNumber => (
                                                                    <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                                                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
                                                                    </li>
                                                                ))}
                                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                                <button className='page-link' onClick={nextPage} disabled={currentPage === totalPages}>
                                                                    Next
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className={clsx('tab-pane', { active: tab === 'PDF' })}>
                                <h3>Show PDF</h3>
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default RegulatoryDetails;
import React, { useState, useEffect, useCallback } from 'react';
// import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
// import CommentFilter from './CommentFilter';
import { useAuth } from '../../modules/auth';
import { CommentsListPagination } from './CommentsListPagination';
import CommentsTable from './CommentsTable';
import SearchBar from '../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
const Comments = ({ document, }) => {

    const [filteredComments, setFilteredComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'category', direction: 'asc' });
    const [searchInput, setSearchInput] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const { currentUser, logout } = useAuth();
    const [comments, setComments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(document)
    const [isLoading, setIsLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    const fetchComments = useCallback((documentId) => {
        setIsLoading(true);
        axios.get(`/reg/v1/comment/${documentId}`).then((response) => {
            const { data } = response;
            if (data && data.length > 0) {
                setComments(data);
                setFilteredComments(data); // Set filteredComments here
            } else {
                setComments([]);
                setFilteredComments([]); // And here
            }
            setIsLoading(false);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error("Error fetching comments:", error);
            }

        })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, logout]);

    const sortComments = (comments, sortConfig) => {
        return comments.sort((a, b) => {
            if (a[sortConfig.key] === null || a[sortConfig.key] === '' || typeof a[sortConfig.key] === 'undefined') {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            if (b[sortConfig.key] === null || b[sortConfig.key] === '' || typeof b[sortConfig.key] === 'undefined') {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    useEffect(() => {
        if (selectedDocument.id) {
            fetchComments(selectedDocument.id);
            // console.log("selectedDocument", selectedDocument)
        }
        else {
            setSelectedDocument(null);
        }
    }, [selectedDocument, fetchComments]);

    useEffect(() => {
        // If the search input is empty, set filteredDockets to all dockets
        if ((searchInput || '').trim() === '') {
            setFilteredComments(comments);
        } else {
            // Otherwise, filter dockets based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = comments.filter((comment) => {
                return (
                    (comment.id && comment.id.toLowerCase().includes(searchTerm)) ||
                    (comment.firstName && comment.firstName.toLowerCase().includes(searchTerm)) ||
                    (comment.lastName && comment.lastName.toLowerCase().includes(searchTerm)) ||
                    (comment.organization && comment.organization.toLowerCase().includes(searchTerm)) ||
                    (comment.comment && comment.comment.toLowerCase().includes(searchTerm)) ||
                    (comment.category && comment.category.toLowerCase().includes(searchTerm))
                );
            });
            setFilteredComments(filtered);

        }
    }, [comments, searchInput]);

    const sortedComments = sortComments(filteredComments, sortConfig);

    // const applyFilters = () => {
    //     let filtered = comments.filter((comment) =>
    //         (!filters.startDate || new Date(comment.modifyDate).getTime() >= new Date(filters.startDate).getTime()) &&
    //         (!filters.endDate || new Date(comment.modifyDate).getTime() <= new Date(filters.endDate).getTime())
    //     );
    //     setFilteredComments(filtered);
    // };
    // const handleFilterChange = (key, value) => {
    //     setFilters(prevFilters => ({ ...prevFilters, [key]: value }));

    // };
    // const handleApplyFilters = () => {
    //     applyFilters();
    // };
    // const handleResetFilters = () => {
    //     // Reset the filters state
    //     setFilters({
    //         startDate: '',
    //         endDate: '',
    //     });
    //     // Reset the filtered dockets
    //     setFilteredComments(comments);
    // };

    const handleHeaderClick = (key) => {
        let newDirection = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            newDirection = 'desc';
        }
        setSortConfig({ key, direction: newDirection });
    }

    // changing date-time format from international to local
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    };
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredComments?.length / Math.max(1, itemsPerPage)));
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [filteredComments, currentPage, totalPages, itemsPerPage]);

    // const totalPages = Math.ceil(filteredComments.length / COMMENTS_PER_PAGE);

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div className='d-flex flex-column flex-column-fluid mt-n2'>
                {/* <div className="d-flex flex-row-fluid  px-5 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap d-flex align-items-center justify-content-center" style={{ color: "#4d4d4d" }}>
                            <KTIcon iconName='notepad' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' style={{ transform: 'translateY(60px)' }} />
                            {selectedDocument ?
                                // `Comments for Document: ${selectedDocument.id}` 
                                `${selectedDocument.title}`
                                :
                                `Error`
                            }
                        </h1>
                        {isLoading &&
                            <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
                                <div className="spinner-border text-primary fs-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div> */}
                {/* <div className="card-title m-3 d-flex align-items-center">

                    <KTIcon iconName='message-text-2' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' />

                    <div className="d-flex align-items-center"> 
                        <h4 className="d-md-block text-wrap" style={{ color: "#4d4d4d", margin: '0', padding: '0', marginRight: '10px', marginTop: '1px' }}>
    
                            {selectedDocument ?
                                // `Comments for Document: ${selectedDocument.id}` 
                                `Comments for Document: ${selectedDocument.id}`
                                :
                                `Error`
                            }
                        </h4>
                        {isLoading &&
                            <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
                                <div className="spinner-border text-primary fs-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }

                    </div>
                </div> */}


                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="d-flex flex-wrap gap-3 flex-column-auto  align-items-center ms-2 px-5 mb-3 ">
                            <div className="col-7">
                                <SearchBar
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    placeholder={"Search Comments"}
                                />
                            </div>
                            <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                                <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                                <input
                                    type="number"

                                    value={itemsPerPage}
                                    onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                    className='form-control form-control-solid ms-2 border border-1'
                                    style={{ width: '90px', backgroundColor: '#f1faff' }}
                                />


                            </div>
                            {/* <div className="d-flex flex-column-auto  ms-4  ">
                        <button
                            type='button'
                            className='btn btn-light-primary  btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <CommentFilter
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div>  */}
                        </div>



                        <CommentsTable
                            filteredComments={sortedComments}
                            currentPage={currentPage}
                            COMMENTS_PER_PAGE={Math.max(itemsPerPage, 1)}
                            handleHeaderClick={handleHeaderClick}
                            sortConfig={sortConfig}
                            currentUser={currentUser}
                            formatDate={formatDate}
                        />
                        {/* )} */}

                        {(
                            totalPages >= 1 ? (
                                <div className="d-flex justify-content-center align-items-center">

                                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-7">
                                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                                        <input
                                            type="number"
                                            value={itemsPerPage}
                                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                            className='form-control form-control-solid ms-2 border border-1'
                                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                                        />
                                    </div>
                                    <CommentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                </div>
                            ) : (
                                <div className="mb-4"></div>
                            )
                        )}


                    </div>
                )}
            </div >
        </div >

    );
};

export default Comments;
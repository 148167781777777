import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../_metronic/helpers';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

function SearchBarWrapper({ searchInput, setSearchInput }) {
    const [inputValue, setInputValue] = useState(searchInput);
    const debouncedSearchTerm = useDebounce(inputValue, 300); // 300ms delay

    // Update the search input in the parent component when the debounced value changes
    useEffect(() => {
        setSearchInput(debouncedSearchTerm);
    }, [debouncedSearchTerm, setSearchInput]);

    return (
        <div className="flex-column-auto align-items-center">
            <div className="d-flex align-items-center position-relative my-1">
                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                <input
                    type="search"
                    id="form1"
                    className="form-control form-control-solid w-100 ps-14 border border-1"
                    placeholder="Search"
                    aria-label="Search"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)} 
                    maxLength={200}
                    style={{ backgroundColor: '#f1faff' }}
                />
            </div>
        </div>
    );
}

export default SearchBarWrapper;

import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import DevicesFilter from './DevicesFilter';
import { DevicesListPagination } from './DevicesListPagination';
import DevicesTable from './DevicesTable';
import Cookies from 'js-cookie';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { MixedWidgetAdverse } from '../Home/MixedWidgetAdverse';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import SearchBar from '../../components/SearchBar';
// import { Modal } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import Select from 'react-select';

const AdverseEvents = () => {
    const [devices, setDevices] = React.useState([]);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state ? location.state.searchTerm : '');
    const [isLoading, setIsLoading] = useState(true);
    const interests = useSelector(state => state.account.interests);
    // const [selectedState, setSelectedState] = useState(['interests', ...interests.committees]);
    // const [allAdvisoryCommittees, setAllAdvisoryCommittees] = useState([]);
    // const [advisoryCommittees, setAdvisoryCommittees] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    const [totalPages, setTotalPages] = useState(0);
    // const itemsPerPage = 10;
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [sortConfig, setSortConfig] = useState({ key: 'date_received', direction: 'desc' });
    // const [filterCode, setFilterCode] = useState('');
    // const [filterDeviceName, setFilterDeviceName] = useState('');
    // const [filterBrand, setFilterBrand] = useState('');
    // const [filterManufacturer, setFilterManufacturer] = useState('');
    // const [filterSpecialty, setFilterSpecialty] = useState('');
    //const [filterDecisionDateRange, setFilterDecisionDateRange] = useState(Cookies.get('selectedDays') || '7');
    // const [showModal, setShowModal] = useState(false);
    // const [selectedEvent, setSelectedEvent] = useState(null);
    const [loadSort, setLoadSort] = useState(false);
    const [committees, setCommittees] = useState({});
    // const filter = false;
    // const [specialityOptions, setSpecialityOptions] = useState([]);
    // const [specialityOptions] = useState(interests.committees);
    // const [productCodeOptions, setProductCodeOptions] = useState([]);
    //const [days, setDays] = useState(parseInt(Cookies.get('selectedDays'), 10) || 7);
    const [quarter, setQuarter] = useState('2024q2');
    const firstRenderDays = useRef(true);
    const firstRenderSearchTerm = useRef(true);

    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    // const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);
    // const handleDeviceClassChange = (event) => {
    //     const { value, checked } = event.target;
    //     let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
    //     setSelectedDeviceClasses(currentClasses => {
    //         if (checked) {
    //             if (!currentClasses.includes(newValue)) {
    //                 return [...currentClasses, newValue];
    //             }
    //             return currentClasses;
    //         } else {
    //             return currentClasses.filter(item => item !== newValue);
    //         }
    //     });
    // };

    // const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    // const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    // const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);
    const prevSearchInputRef = useRef();

    // const handleSpecialityChange = (event) => {
    //     const { value, checked } = event.target;

    //     // Handle "All" option separately
    //     if (value === 'All') {
    //         if (checked) {
    //             setSelectedSpecialities(specialityOptions); // Select all options
    //         }
    //         else {
    //             setSelectedSpecialities([]); // Deselect all options
    //         }
    //     } else {
    //         setSelectedSpecialities((prevSelectedSpecialities) => {
    //             if (checked && !prevSelectedSpecialities.includes(value)) {
    //                 // If selecting an option and all other options are selected, add "All" to the selection
    //                 const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
    //                 return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
    //             } else if (!checked) {
    //                 // If deselecting an option, also remove "All" from the selection
    //                 return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
    //             }
    //             return prevSelectedSpecialities;
    //         });
    //     }
    // };

    // const [productCodeDropdownOpen, setProductCodeDropdownOpen] = useState(false);
    // const toggleProductCodeDropdown = () => setProductCodeDropdownOpen(!productCodeDropdownOpen);
    // const [selectedProductCodes, setSelectedProductCodes] = React.useState([]);

    // const handleProductCodeChange = (event) => {
    //     const { value, checked } = event.target;

    //     // Handle "All" option separately
    //     if (value === 'All') {
    //         if (checked) {
    //             setSelectedProductCodes(productCodeOptions); // Select all options
    //         }
    //         else {
    //             setSelectedProductCodes([]); // Deselect all options
    //         }
    //     } else {
    //         setSelectedProductCodes((prevSelectedProductCodes) => {
    //             if (checked && !prevSelectedProductCodes.includes(value)) {
    //                 // If selecting an option and all other options are selected, add "All" to the selection
    //                 const allSelected = [...prevSelectedProductCodes, value].length === productCodeOptions.length;
    //                 return allSelected ? [...prevSelectedProductCodes, value, 'All'] : [...prevSelectedProductCodes, value];
    //             } else if (!checked) {
    //                 // If deselecting an option, also remove "All" from the selection
    //                 return prevSelectedProductCodes.filter((productCode) => productCode !== value && productCode !== 'All');
    //             }
    //             return prevSelectedProductCodes;
    //         });
    //     }
    // };

    // const dropdownRef = useRef(null);
    // const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (dropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [dropdownOpen]);


    // const dropdownRef2 = useRef(null);
    // const handleClickOutsideSpeciality = (event) => {
    //     if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
    //         setSpecialityDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (specialityDropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutsideSpeciality);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     };
    // }, [specialityDropdownOpen]);

    // const dropdownRef3 = useRef(null);
    // const handleClickOutsideProductCode = (event) => {
    //     if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
    //         setProductCodeDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (productCodeDropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutsideProductCode);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutsideProductCode);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsideProductCode);
    //     };
    // }, [productCodeDropdownOpen]);


    // const handleApplyFilters = () => {
    //     setCurrentPage(1);
    // };
    // const handleResetFilters = () => {
    //     setFilterCode('');
    //     setFilterDeviceName('');
    //     setFilterBrand('');
    //     setFilterManufacturer('');
    //     setFilterSpecialty('');
    //     setFilterDecisionDateRange('30');
    //     setCurrentPage(1);
    // };

    // if (filter) {
    //     handleApplyFilters();
    //     handleResetFilters();
    // }

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    // useEffect(() => {
    //     Cookies.set('selectedDays', filterDecisionDateRange);
    // }, [filterDecisionDateRange]);

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [selectedDeviceClasses, selectedSpecialities, selectedProductCodes]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    // useEffect(() => {
    //     const fetchProductCodes = () => {
    //         axios.get('/reg/v1/fda/product_codes')
    //             .then(response => {
    //                 const options = response.data.map(codes =>
    //                     `${codes.product_code}`,
    //                 );
    //                 setProductCodeOptions(options);
    //                 setSelectedProductCodes(options);
    //             });
    //     };
    //     fetchProductCodes();
    // }, []);

    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);

    // const handleShow = (event) => {
    //     setSelectedEvent(event);
    //     setShowModal(true);
    // };

    // const handleClose = () => {
    //     setShowModal(false);
    //     setSelectedEvent(null);
    // };

    // const [custom, setCustom] = useState(false);
    const navigate = useNavigate();
    // useEffect(() => {
    //     const fetchDevices = async () => {
    //         setIsLoading(true);
    //         try {
    //             const response = await axios.get(`/reg/v1/fda/adverse_events/by_days/365`);
    //             let fetchedDevices = response.data;

    //             fetchedDevices.sort((a, b) => {
    //                 let aValue = a[sortConfig.key];
    //                 let bValue = b[sortConfig.key];

    //                 if (sortConfig.key === 'date_received') {
    //                     aValue = new Date(aValue).getTime();
    //                     bValue = new Date(bValue).getTime();
    //                 } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    //                     aValue = aValue.toLowerCase();
    //                     bValue = bValue.toLowerCase();
    //                 }

    //                 if (aValue < bValue) {
    //                     return sortConfig.direction === 'asc' ? -1 : 1;
    //                 }
    //                 if (aValue > bValue) {
    //                     return sortConfig.direction === 'asc' ? 1 : -1;
    //                 }
    //                 return 0;
    //             });

    //             setDevices(fetchedDevices);
    //         } catch (error) {
    //             console.log('Error fetching devices:', error);
    //         }
    //         setIsLoading(false);
    //     };
    //     fetchDevices();
    //     // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     setLoadSort(true);
    //     let sortedDevices = [...devices];
    //     sortedDevices = sortedDevices.sort((a, b) => {
    //         let aValue = a[sortConfig.key];
    //         let bValue = b[sortConfig.key];

    //         if (sortConfig.key === 'date_received') {
    //             aValue = new Date(aValue).getTime();
    //             bValue = new Date(bValue).getTime();
    //         } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    //             aValue = aValue.toLowerCase();
    //             bValue = bValue.toLowerCase();
    //         }

    //         if (aValue < bValue) {
    //             return sortConfig.direction === 'asc' ? -1 : 1;
    //         }
    //         if (aValue > bValue) {
    //             return sortConfig.direction === 'asc' ? 1 : -1;
    //         }
    //         return 0;
    //     });
    //     setDevices(sortedDevices);
    //     setLoadSort(false);
    //     // eslint-disable-next-line
    // }, [sortConfig]);



    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [searchTerm, filterCode, filterDeviceName, filterBrand, filterManufacturer, filterSpecialty, filterDecisionDateRange]);

    useEffect(() => {
        if (firstRenderDays.current) {
            firstRenderDays.current = false;
            return;
        }
        setSearchTerm('');
        setCurrentPage(1);
    }, [quarter]);

    useEffect(() => {
        if (firstRenderSearchTerm.current) {
            firstRenderSearchTerm.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setCurrentPage(1);
            if ((searchTerm || '').trim() !== '') {
                setSortConfig({ key: '', direction: '' });
            } else {
                setSortConfig({ key: 'date_received', direction: 'desc' });
            }
        }, 0);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        setLoadSort(true);
    }, [quarter]);

    useEffect(() => {
        prevSearchInputRef.current = searchTerm;
    }, [searchTerm]);

    useEffect(() => {
        // if (selectedProductCodes.length === 0) {
        //     return;
        // }

        const delayDebounceFn = setTimeout(async () => {
            const fetchNewDevices = async () => {
                setIsLoading(true);
                //setLoadSort(true);

                // const committees = selectedSpecialities.map(item => {
                //     const match = item.match(/\(([^)]+)\)/);
                //     return match ? match[1] : null;
                // }).filter(Boolean);

                // const sortedCommittees = committees.sort((a, b) => a.localeCompare(b));
                // const sortedProductCodes = selectedProductCodes.sort((a, b) => a.localeCompare(b));
                // const sortedDeviceClasses = selectedDeviceClasses.sort((a, b) => a - b);

                // const filters = { device_classes: sortedDeviceClasses, committees: sortedCommittees, product_codes: sortedProductCodes };
                const filters = {};
                const filtersJson = JSON.stringify(filters);

                try {
                    if ((searchTerm || '').trim() === '') {
                        const response = await axios.post(
                            `/reg/v1/fda/preference/adverse_events/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}&days=${quarter}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.total_count / Math.max(1, itemsPerPage)));
                        }
                    } else {
                        const response = await axios.post(`/reg/v1/search/search/adverse-event?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchTerm}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }

                        );
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch new devices:", error);
                } finally {
                    setIsLoading(false);
                    setLoadSort(false);
                }
            };

            fetchNewDevices();
        }, 100);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, sortConfig.direction, sortConfig.key, quarter, searchTerm]);

    // const matchesSearchTerm = (event, searchTerms) =>
    //     searchTerms.some(term =>
    //         event.report_number?.toLowerCase().includes(term) ||
    //         event.device_name?.toLowerCase().includes(term) ||
    //         event.brand_name?.toLowerCase().includes(term) ||
    //         event.generic_name?.toLowerCase().includes(term) ||
    //         event.manufacturer_name?.toLowerCase().includes(term) ||
    //         event.device_report_product_code?.toLowerCase().includes(term) ||
    //         event.patient_problems?.toLowerCase().includes(term) ||
    //         event.openfda_medical_specialty_description?.toLowerCase().includes(term) ||
    //         event.openfda_device_class?.toLowerCase().includes(term)
    //     );

    // const matchesCommittee = (event, committees) =>
    //     committees.some(committee => {
    //         const match = committee.match(/\(([^)]+)\)/);
    //         const code = match ? match[1] : committee;
    //         return code === event.openfda_medical_specialty_description;
    //     });

    // const filteredDevices = devices.filter(device => {
    //     const searchTerms = searchTerm.toLowerCase().split(/[\s,]+/).filter(Boolean);
    //     const matchesSearch = !searchTerm || matchesSearchTerm(device, searchTerms);
    //     const matchesInterests = (selectedSpecialities.length === 0 || matchesCommittee(device, selectedSpecialities)) &&
    //         (selectedDeviceClasses.length === 0 || selectedDeviceClasses.includes(Number(device.openfda_device_class)));
    //     // Apply filters
    //     const matchesBrand = !filterBrand || device.brand_name.toLowerCase().includes(filterBrand.toLowerCase());
    //     const matchesManufacturer = !filterManufacturer || device.manufacturer_name.toLowerCase().includes(filterManufacturer.toLowerCase());
    //     const matchesCode = !filterCode || device.device_report_product_code.toLowerCase().includes(filterCode.toLowerCase());
    //     const matchesDeviceName = !filterDeviceName || device.generic_name.toLowerCase().includes(filterDeviceName.toLowerCase());
    //     const matchesSpecialty = !filterSpecialty || device.openfda_medical_specialty_description.toLowerCase().includes(filterSpecialty.toLowerCase());
    //     const currentDate = new Date();
    //     const decisionDate = new Date(device.date_received);
    //     decisionDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
    //     const matchesDecisionDateRange = !filterDecisionDateRange || (decisionDate >= new Date(Date.now() - Number(filterDecisionDateRange) * 24 * 60 * 60 * 1000));
    //     return matchesInterests && matchesSearch && matchesBrand && matchesManufacturer && matchesCode && matchesDeviceName && matchesSpecialty && matchesDecisionDateRange;
    // });

    // useEffect(() => {
    //     setTotalPages(Math.ceil(filteredDevices.length / Math.max(1, itemsPerPage)));
    //     if (currentPage > totalPages) {
    //         setCurrentPage(1);
    //     }
    // }, [filteredDevices, currentPage, totalPages, itemsPerPage]);

    const handleHeaderClick = (key) => {
        let direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };

    // const currentDevices = filteredDevices.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage));

    // const processDataForDaysVsDeviceClass = (data) => {
    //     const deviceCounts = data.reduce((acc, item) => {
    //         const deviceclass = item.openfda_device_class;
    //         if (!acc[deviceclass]) {
    //             acc[deviceclass] = 0;
    //         }
    //         acc[deviceclass]++;
    //         return acc;
    //     }, {});

    //     const seriesData = Object.entries(deviceCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));

    //     return seriesData;

    // };

    // const MyChartComponent = ({ data }) => {
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             min: 0,
    //             title: {
    //                 text: 'Device Class'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Adverse Events'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `Class ${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: processDataForDaysVsDeviceClass(data)
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Adverse Events</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     )
    // };

    // const processDataForDeviceClassVsAdvisoryCommittee = (data) => {
    //     const result = {};
    //     data.forEach(item => {
    //         if (item.openfda_device_class && item.openfda_device_class > 0) {
    //             const deviceClass = `Class ${item.openfda_device_class}`;
    //             const committee = item.openfda_medical_specialty_description;
    //             if (!result[deviceClass]) {
    //                 result[deviceClass] = {};
    //             }
    //             if (!result[deviceClass][committee]) {
    //                 result[deviceClass][committee] = 0;
    //             }
    //             result[deviceClass][committee]++;
    //         }
    //     });

    //     const seriesData = Object.keys(result).map(deviceClass => ({
    //         name: deviceClass,
    //         data: Object.values(result[deviceClass]),
    //         stack: 'deviceClass'
    //     }));

    //     const categories = [...new Set(data.map(item => item.openfda_medical_specialty_description))];

    //     return { seriesData, categories };
    // };

    // const DeviceClassVsAdvisoryCommitteeChart = ({ data }) => {
    //     const { seriesData, categories } = processDataForDeviceClassVsAdvisoryCommittee(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'bar'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             categories: categories
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Adverse Events'
    //             }
    //         },
    //         plotOptions: {
    //             bar: {
    //                 stacking: 'normal'
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Device Class vs. Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };

    // const processDataForAdvisoryCommitteeVsDays = (data) => {
    //     const committeeCounts = data.reduce((acc, item) => {
    //         const committee = item.openfda_medical_specialty_description; // Assuming 'committee' is the field name
    //         if (!acc[committee]) {
    //             acc[committee] = 0;
    //         }
    //         acc[committee]++;
    //         return acc;
    //     }, {});

    //     // Convert the counts object to an array suitable for Highcharts
    //     const seriesData = Object.entries(committeeCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));
    //     // const categories = [...new Set(data.map(item => item.review_advisory_committee))];
    //     return { seriesData };
    // };

    // const AdvisoryCommitteeVsDaysChart = ({ data }) => {

    //     const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             title: {
    //                 text: 'Specialty'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Adverse Events'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`}>
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Adverse Events by Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };


    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <KTIcon iconName='calendar-remove' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                    <span>Adverse Events (MAUDE)</span>
                                    {/* {isLoading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )} */}
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div className="d-flex flex-row-fluid px-10 align-items-center">
                        <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    </div>
                    {committees && interests.device_classes && (
                        <div className="d-flex flex-row-fluid px-10">
                            {Object.keys(committees).length > 0 && (
                                <div style={{ position: 'relative', paddingRight: '7px' }}>
                                    <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                        {Object.entries(committees).map(([key, value], index) => (
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {value}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{index > 0 && ', '}{key}</span>
                                            </OverlayTrigger>
                                        ))}
                                    </h6>
                                    {interests.device_classes.length > 0 && (
                                        <div style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '5%',
                                            bottom: '5%',
                                            width: '1px',
                                            backgroundColor: '#4d4d4d'
                                        }}></div>
                                    )}
                                </div>
                            )}
                            {interests.device_classes.length > 0 && (
                                <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                    <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* <div className="d-flex flex-column px-10 align-items-start">
                    {(filterCode || filterDeviceName || filterBrand || filterManufacturer || filterSpecialty || filterDecisionDateRange) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterCode && <li><h6 className="d-md-block text-wrap">Product Code: {filterCode}</h6></li>}
                                {filterDeviceName && <li><h6 className="d-md-block text-wrap">Device Name: {filterDeviceName}</h6></li>}
                                {filterBrand && <li><h6 className="d-md-block text-wrap">Brand: {filterBrand}</h6></li>}
                                {filterManufacturer && <li><h6 className="d-md-block text-wrap">Manufacturer: {filterManufacturer}</h6></li>}
                                {filterSpecialty && <li><h6 className="d-md-block text-wrap">Specialty: {filterSpecialty}</h6></li>}
                                {filterDecisionDateRange && <li><h6 className="d-md-block text-wrap">Received Date Range: Past {filterDecisionDateRange === '365' ? '1 Year' : `${filterDecisionDateRange} Days`}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div> */}
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto ms-8 mt-4">
                    <ul className='nav mb-4'>
                        {/* <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 7 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(7); setDays(7); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                7 Days
                            </button>
                        </li> */}

                        {/* <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 30 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(30); setDays(30); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                30 Days
                            </button>
                        </li> */}
                        {/* <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 90 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(90); setDays(90); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                90 Days
                            </button>
                        </li> */}
                        {/* <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 180 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(180); setDays(180); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                180 Days
                            </button>
                        </li> */}
                        {/* <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 365 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(365); setDays(365); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                1 Year
                            </button>
                        </li> */}
                        {/* <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 0 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(0); setDays(0); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                All
                            </button>
                        </li> */}
                        {/* <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${quarter === '2024q3' ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setQuarter('2024q3') }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                2024 Q3
                            </button>
                        </li> */}
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${quarter === '2024q2' ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setQuarter('2024q2') }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                2024 Q2
                            </button>
                        </li>
                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${quarter === '2024q1' ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setQuarter('2024q1'); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                2024 Q1
                            </button>
                        </li>

                    </ul>

                    {/* <div className='mb-4 dropdown ms-7' ref={dropdownRef}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Device Class
                        </button>
                        {dropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleSpecialityDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Specialty
                        </button>
                        {specialityDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {specialityOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef3}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownProductCodeButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleProductCodeDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Product Code
                        </button>
                        {productCodeDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownProductCodeButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='productCodeAll' name='productCodeOption' value='All' checked={selectedProductCodes.length === productCodeOptions.length} onChange={handleProductCodeChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='productCodeAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {productCodeOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`productCode${index}`} name='productCodeOption' value={option} checked={selectedProductCodes.includes(option)} onChange={handleProductCodeChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`productCode${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div> */}
                </div>

                {/* <MixedWidgetAdverse
                    className='card-xxl-stretch pt-3'
                    chartHeight='240px'
                    days={days}
                    doctype={'Adverse Events'}
                    activity={"all"}
                    scale="logarithmic"
                    showChart={true}
                /> */}



                {/* {filteredDevices.length > 0 && (
                    <div className='row g-5 justify-content-center mt-1 mb-4'>
                        <div className='col-xxl-4'>
                            <AdvisoryCommitteeVsDaysChart data={filteredDevices} />
                        </div>
                        <div className='col-xxl-4'>
                            <DeviceClassVsAdvisoryCommitteeChart data={filteredDevices} />
                        </div>
                        <div className='col-xxl-4'>
                            <MyChartComponent data={filteredDevices} />
                        </div>
                    </div>
                )} */}

                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-2`}>
                    <div className="col-6">
                        <SearchBar
                            searchInput={searchTerm}
                            setSearchInput={setSearchTerm}
                            placeholder={"Search Adverse Events"}
                        />
                    </div>
                    {/* <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DevicesFilter
                            filterCode={filterCode}
                            setFilterCode={setFilterCode}
                            filterDeviceName={filterDeviceName}
                            setFilterDeviceName={setFilterDeviceName}
                            filterBrand={filterBrand}
                            setFilterBrand={setFilterBrand}
                            filterManufacturer={filterManufacturer}
                            setFilterManufacturer={setFilterManufacturer}
                            filterSpecialty={filterSpecialty}
                            setFilterSpecialty={setFilterSpecialty}
                            filterDecisionDateRange={filterDecisionDateRange}
                            setFilterDecisionDateRange={setFilterDecisionDateRange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div> */}
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
            </div>
            {/* {totalPages >= 1 && ( */}
            {isLoading ? <HourGlass /> : (<DevicesTable
                loadSort={loadSort}
                currentDevices={devices}
                handleHeaderClick={handleHeaderClick}
                sortConfig={sortConfig}
                navigate={navigate}
                searchTerm={searchTerm}
                currentPage={currentPage}
            // handleShow={handleShow}
            />)}
            {/* )} */}

            {totalPages >= 1 && (
                <div className="d-flex justify-content-center">

                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-5">
                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>

                    <DevicesListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </div>
            )}
            {/* <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="custom-modal-size"
                size="lg"
                centered
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Adverse Event Details for Device: {selectedEvent?.generic_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <div className="row">
                            <div className="col-md-6">
                                {selectedEvent?.report_number && <p><strong>Report Number:</strong> {selectedEvent?.report_number}</p>}
                            </div>
                            <div className="col-md-6">
                                {selectedEvent?.generic_name && <p><strong>Device Name:</strong> {selectedEvent?.generic_name}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p><strong>Event Dates:</strong>
                                    <ul>
                                        <li><strong>Received Date:</strong> {new Date(selectedEvent?.date_received).toLocaleDateString()}</li>
                                        {selectedEvent?.expiration_date_of_device && <li><strong>Posted on:</strong> {new Date(selectedEvent?.expiration_date_of_device).toLocaleDateString()}</li>}
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.brand_name && <p><strong>Brand Name:</strong> {selectedEvent?.brand_name}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.device_operator && <p><strong>Device Operator:</strong> {selectedEvent?.device_operator}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.device_report_product_code && <p><strong>Product Code:</strong> {selectedEvent?.device_report_product_code}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.manufacturer_name && <p><strong>Manufacturer Name:</strong> {selectedEvent?.manufacturer_name}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.device_sequence_number && <p><strong>Device Sequence Number:</strong> {selectedEvent?.device_sequence_number}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedEvent?.patient_problems && <p><strong>Patient Problems:</strong> {selectedEvent?.patient_problems}</p>}
                            </div>
                        </div>
                        {selectedEvent?.additional_info_contact && <p><strong>Additional Info Contact:</strong> {selectedEvent?.additional_info_contact}</p>}
                        {selectedEvent?.openfda_device_name && <p><strong>OpenFDA Device Name:</strong> {selectedEvent?.openfda_device_name}</p>}
                        {selectedEvent?.openfda_medical_specialty_description && <p><strong>OpenFDA Medical Specialty Description:</strong> {selectedEvent?.openfda_medical_specialty_description}</p>}
                        <div className="row">
                            <div className="col-md-6">
                                {selectedEvent?.openfda_device_class && <p><strong>OpenFDA Device Class:</strong> {selectedEvent?.openfda_device_class}</p>}
                            </div>
                            <div className="col-md-6">
                                {selectedEvent?.openfda_regulation_number && <p><strong>OpenFDA Regulation Number:</strong> {selectedEvent?.openfda_regulation_number}</p>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal> */}
        </div>
    );
}
export default AdverseEvents;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

const RegistrationDetails = () => {
    // const { id } = useParams();
    // const [device, setDevice] = useState([]);
    const [tab, setTab] = useState('Details');
    const isLoading = false;
    const location = useLocation();
    const device = location.state ? location.state.device : {};
    const searchTerm = location.state ? location.state.searchTerm : '';
    const currentPage = location.state ? location.state.currentPage : 1;

    // console.log("reg details", state);
    // useEffect(() => {    
    //     if (location.state && location.state.device) {
    //         console.log(location.state.device);
    //         setDevice(location.state.device);
    //     }
    // }, [location]);


    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={"/device-intelligence/registration"}
                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Registrations List
                    </Link>
                </div>
            </div>


            {(device.k_pma_number !== "0") && (
                <div className='card mt-4 mb-4'>
                    <div className='card-body d-flex align-items-center'>
                        <div className='position-relative flex-wrap'>
                            <div>
                                {!isLoading && <h4 className='text-gray-700 fw-bold'>Number: {device.k_pma_number}</h4>}
                                {isLoading && (
                                    <div className="d-flex align-items-center">
                                        <div>Registration Loading...</div>
                                        <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Product Details
                            </h5>
                        </li>
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Agent' })}
                                onClick={() => setTab('Agent')}
                                role='tab'
                            >
                                Agent
                            </h5>
                        </li>
                    </ul>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {device.proprietary_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Proprietary Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.proprietary_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.establishment_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Establishment Type</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.establishment_type}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.firm_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Firm Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.firm_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.initial_importer_flag && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Importer</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.initial_importer_flag}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.product_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product code</span>
                                            </div>
                                            <div className="col-10">

                                                <Link to={`/device-intelligence/classification/${device.product_code}`} target="_blank" rel="noopener noreferrer" className="fw-bold fs-5" style={{ color: 'primary' }}>{device.product_code}</Link>
                                            </div>
                                        </div>
                                    )}
                                    {device.device_class && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Class</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.device_class}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.device_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.device_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Specialty</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.state_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>State Code</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.state_code}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.country_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Country Code</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.country_code}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.product_release_date && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Release Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{new Date(device.product_release_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}{/*
                                    {device.recall_status && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Recall Status</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.recall_status}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.root_cause_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Root Cause</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.root_cause_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.distribution_pattern && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Distribution Pattern</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.distribution_pattern}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.reason_for_recall && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Reason for Recall</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.reason_for_recall}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.action && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Action</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.action}</span>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'Agent' })}>
                                <div className="card-body card-scroll">
                                    {device.us_agent_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Agent Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.us_agent_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.us_agent_email && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Agent Email</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.us_agent_email}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.us_agent_city && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Agent City</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.us_agent_city}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.us_agent_state && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Agent State</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.us_agent_state}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default RegistrationDetails;

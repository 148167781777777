import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import TableRow510 from './TableRow510';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

type Document = {
    review_advisory_committee: string;
    device_name: string;
    applicant: string;
    decision_date: string;
    k_number: string;
    product_code: string;

    //   document_title: string;
    //   modifyDate: string;
    //   program: string;
}
type Props = {
    days: number;
}
const TablesWidget510: React.FC<Props> = ({ days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState<Document[]>([]);
    const [itemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('decision_date')
    const [count, setCount] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const filtersJson = JSON.stringify({});
                const response = await axios.post(
                    `/reg/v1/fda/preference/devices/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`,
                    { filter: filtersJson },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                //const response = await axios.get(`/reg/v1/fda/preference/devices/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`);
                const totalCount = response.data.length > 0 ? response.data[0].k_number_count : 0;
                setCount(totalCount);
                const documents = response.data;
                setDetails(documents);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder]);

    const currentItems = details;
    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);
    }, [days])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // const PAGINATION_PAGES_COUNT = 5;


    // const sliceLinks = (totalPages, currentPage) => {
    //     let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

    //     if (pageLinks.length > PAGINATION_PAGES_COUNT) {
    //         let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
    //         let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

    //         if (start < 0) {
    //             end -= start;
    //             start = 0;
    //         }

    //         if (end >= pageLinks.length) {
    //             start -= end - pageLinks.length + 1;
    //             end = pageLinks.length - 1;
    //         }

    //         pageLinks = pageLinks.slice(start, end + 1);
    //     }

    //     return pageLinks;
    // }

    //const paginationLinks = sliceLinks(totalPages, currentPage);

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card `}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        510Ks
                                        {loading && <span className='spinner-border fs-8 text-gray-600 align-middle ms-2'></span>}
                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                                {/* <div className='card-toolbar'>
                <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName='category' className='fs-2' />
                </button>
            </div> */}
                            </div>
                        </div>
                        {/* end::Header */}
                        <div className='card-body px-8 '>
                            {/* begin::Table container */}
                            <div className='table-responsive '>
                                {/* begin::Table */}
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-1 gy-3 ' style={{ width: '100% !important' }}>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bolder text-gray-600 fs-5'>
                                            <th style={{ minWidth: '12%', width: '12%' }} onClick={() => handleSort('k_number')}>
                                                K Number
                                                <span className={`sorting-icon ${sortcol === 'k_number' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'k_number' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            {/* <th className ='min-w-150px'>ID</th> */}
                                            <th style={{ minWidth: '30%', width: '30%' }} onClick={() => handleSort('device_name')}>
                                                Device Name
                                                <span className={`sorting-icon ${sortcol === 'device_name' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'device_name' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('product_code')}>
                                                Product Code
                                                <span className={`sorting-icon ${sortcol === 'product_code' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'product_code' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('review_advisory_committee')}>
                                                Specialty
                                                <span className={`sorting-icon ${sortcol === 'review_advisory_committee' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'review_advisory_committee' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '20%', width: '20%' }} onClick={() => handleSort('applicant')}>
                                                Company
                                                <span className={`sorting-icon ${sortcol === 'applicant' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'applicant' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            {/* <th className='min-w-120px'>Program </th> */}
                                            <th style={{ minWidth: '25%', width: '25%' }} className='text-end' onClick={() => handleSort('decision_date')}>
                                                Decision Date
                                                <span className={`sorting-icon ${sortcol === 'decision_date' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'decision_date' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>


                                            {/* <th className='min-w-60px text-end'>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (

                                            <tr>
                                                <td>
                                                    <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>
                                                    </span>
                                                </td>
                                            </tr>

                                        ) : (
                                            <TableRow510 items={currentItems} />


                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 && (
                    <div className='card-footer border-1 p-0 mt-6 mb-2'>
                        <div className='row'>
                            <div className='d-flex justify-content-center'>
                                <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                        color="primary"
                                        showFirstButton
                                        showLastButton
                                        size="large"
                                        renderItem={(item) => (
                                            <PaginationItem
                                                {...item}
                                                className={clsx({
                                                    'Mui-selected': item.page === currentPage && item.type !== 'first' && item.type !== 'last',
                                                })}
                                                sx={{
                                                    border: item.page === currentPage && !item.disabled ? '1px solid' : 'none',
                                                    color: item.page === currentPage ? 'text.primary' : 'text.primary', // Change to your desired hex color
                                                    '&.Mui-selected': {
                                                        backgroundColor: 'linear-gradient(to bottom, #2C81AF 50%, #2C81AF 50%)' // Change to your desired hex color
                                                    },
                                                }}
                                                disabled={
                                                    ((item.type === 'first' || item.type === 'previous') && currentPage === 1) ||
                                                    ((item.type === 'last' || item.type === 'next') && currentPage === totalPages)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export { TablesWidget510 }
// TableRowComponent.jsx
import React from 'react';

type Document = {
    company_name: string;
    issuing_office: string;
    letter_issue_date: string;
    posted_date: string;
    id: string;
    program_id: string;
    subject: string;
    warning_letter_url: string;

}
interface TableRowComponentWarningProps {
    items: Document[];
}

const TableRowComponentWarning: React.FC<TableRowComponentWarningProps> = React.memo(({ items }) => {
    return (
        <>
            {items?.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-gray-600 fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (
                items?.map((item) => (
                    <tr className='bg-hover-light-primary' key={item.id} style={{ cursor: 'default' }}>
                        <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                            {item.company_name}
                        </td>
                        <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                            {item.subject}
                        </td>
                        <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                            {item.issuing_office}
                        </td>
                        <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                            {new Date(item.letter_issue_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                        </td>
                        <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                            {new Date(item.posted_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                        </td>
                        <td className='fw-bold text-hover-primary text-end text-primary text-underline mb-1 fs-6 mt-2'>
                            <a href={item.warning_letter_url} target="_blank" rel="noopener noreferrer" className='text-primary fw-bold text-hover-primary text-underline mb-1 fs-6'>Link</a>
                        </td>
                    </tr>
                ))
            )}
        </>
    );
});

export default TableRowComponentWarning;
import React from 'react'
import FullWindow from './FullWindow';


function PdfViewer({ documentId }) {

  const isInternational = sessionStorage.getItem('isInternational') === 'true';
  return (
    <div className="app-man flex-column flex-row-fluid w-100 h-100" id="kt_app_main">
      <div className="d-flex flex-column flex-column-fluid w-100 h-100">
        <FullWindow documentId={documentId} isInternational={isInternational} />
      </div>
    </div>
  );
};



export default PdfViewer;
import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import FileViewer from "./FileViewer";

const Preamble = () => {
    const [expandCollapse, setExpandCollapse] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const expandAll = () => {
        setIsLoading(true);
        setTimeout(() => {
            const detailsElements = document.querySelectorAll('details');
            detailsElements.forEach(details => {
                details.open = true;
            });
            setIsLoading(false);
        }, 50);
    };

    const collapseAll = () => {
        setIsLoading(true);
        setTimeout(() => {
            const detailsElements = document.querySelectorAll('details');
            detailsElements.forEach(details => {
                details.open = false;
            });
            setIsLoading(false);
        }, 50);
    };

    useEffect(() => {
        if (expandCollapse === 'expand') {
            expandAll();
        } else if (expandCollapse === 'collapse') {
            collapseAll();
        }
    }, [expandCollapse]);

    return (
        <div>
            <div className="d-flex flex-column px-5 py-0" >
                <div className="d-flex flex-column-auto mb-2 mt-2 ">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName='book-open' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>FDA Preamble</span>
                            {isLoading && (
                                <div className="spinner-border text-primary ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </div>
                    </h1>
                </div>
            </div>
            <div className="card p-3 mx-auto text-wrap"
                style={{
                    borderRadius: '10px',
                    boxShadow: 'none',
                    height: 'calc(120vh)',
                    border: 'none',
                    scrollbarWidth: 'none',
                }}
            >
                <FileViewer
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    expandAll={expandAll}
                    collapseAll={collapseAll}
                    setExpandCollapse={setExpandCollapse}
                />
            </div>
        </div>
    )
}
export default Preamble;
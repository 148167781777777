/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
// import { useSelector } from 'react-redux';
//import { Dropdown1 } from '../../../../_metronic/partials'
import axios from 'axios';
import { useAuth } from '../../../modules/auth';
import { useSelector, useDispatch } from 'react-redux';

const ProfileHeader2: React.FC = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const orgData = useSelector((state: any) => state.account.organization);
    // const avatarUrl = useSelector((state: any) => {
    //     const url = state.account.avatarUrl;
    //     return url && url !== 'none' ? url : toAbsoluteUrl('/media/svg/avatars/blank.svg');
    // });
    const avatarUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = React.useState(true);
    // Fetch organization data from the backend

    useEffect(() => {
        setIsLoading(true);
        if (!orgData || orgData.name === '' || orgData.name === undefined) {
            axios.get(`/reg/v1/superadmin/organization/${currentUser?.organization}`)
                .then((response) => {
                    const data = response.data[0];
                    dispatch({ type: 'UPDATE_ORGANIZATION', organization: data });
                    setIsLoading(false); // Move this line here
                })
                .catch((error) => {
                    console.error("Error fetching organization data:", error);
                    setIsLoading(false); // And here
                });
        } else {
            setIsLoading(false); // If orgData already exists, set loading to false immediately
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={avatarUrl} alt='Metronic' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className={`text-gray-800 text-hover-primary fw-bolder me-1 ${isLoading ? 'fs-4' : 'fs-2'}`}>
                                        {!isLoading ? 'Lexim AI' : 'Organization Details Loading...'}
                                    </a>
                                    {isLoading && (
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="spinner-border ms-1 me-3" role="status" style={{ width: '1.75rem', height: '1.75rem', borderWidth: '0.2em' }}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <a href='#'>
                                            <KTIcon iconName='verify' className='fs-1 text-primary' />
                                        </a>
                                    )}
                                </div>
                                {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='home' className='fs-4 me-1' />
                                        Organization Type -
                                        {orgData && orgData.industry ? orgData.industry : ' '}
                                    </a>
                                </div> */}
                                {!isLoading && (
                                    <div>
                                        <div className='d-flex flex-wrap fw-bold fs-6 mt-1 mb-1 pe-2'>

                                            <a
                                                href='#'
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTIcon iconName='geolocation' className='fs-2 me-2' />
                                                Address: {' '}
                                                {orgData &&
                                                    [
                                                        orgData.address,
                                                        orgData.city,
                                                        orgData.state,
                                                        orgData.region,
                                                        orgData.country,
                                                    ]
                                                        .map((item) => item && item.trim()) // Remove whitespace from start and end of strings
                                                        .filter((item) => item) // Filter out null, empty, and whitespace-only items
                                                        .join(', ')}                                  </a>
                                            <a
                                                href='#'
                                                className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                            >
                                                <KTIcon iconName='sms' className='fs-2 me-2' />
                                                {orgData && orgData.website ? orgData.website : 'www.lexim.ai'}
                                            </a>
                                        </div>
                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-1 pe-2'>
                                            <a
                                                href='#'
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTIcon iconName='profile-circle' className='fs-2 me-2 mb-1' />
                                                Super Admin
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>

                        {/* <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='user-tick' className='fs-1 text-success me-2' />

                                            <div className=' fw-bold fs-6 text-400'>10</div>
                                            <div className='mx-2 fw-bold fs-6 text-400'>Users</div>
                                        </div>


                                    </div>




                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/organizations' && 'active')
                                }
                                to='/super-admin/organizations'
                            >
                                Organizations
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/users' && 'active')
                                }
                                to='/super-admin/users'
                            >
                                Users
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/settings' && 'active')
                                }
                                to='/super-admin/settings'
                            >
                                Settings
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/support' && 'active')
                                }
                                to='/super-admin/support'
                            >
                                Support
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/announcements' && 'active')
                                }
                                to='/super-admin/announcements'
                            >
                                Announcements
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/configurations' && 'active')
                                }
                                to='/super-admin/configurations'
                            >
                                Configuration
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname.startsWith('/super-admin/data-operations/') && 'active')
                                }
                                to='/super-admin/data-operations'
                            >
                                Data Operations
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/upload-warning-letters' && 'active')
                                }
                                to='/super-admin/upload-warning-letters'
                            >
                                Upload Warning Letters
                            </Link>
                        </li>
                        {/* <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/support' && 'active')
                                }
                                to='/super-admin/support'
                            >
                                Support
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/super-admin/billing' && 'active')
                                }
                                to='/super-admin/billing'
                            >
                                Billing
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { ProfileHeader2 }
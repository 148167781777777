/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import axios from 'axios';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import SortFilterWrapper from '../../components/SortFilterWrapper';
import SearchBar from '../../components/SearchBar';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import PaginationWrapper from '../../components/PaginationWrapper';


function Country({ country, region_code }) {

    const [searchInput, setSearchInput] = useState('');
    const [events, setEvents] = useState([]);
    const [showEvents, setShowEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'publish_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');

    const formatCountry = (name) => {
        if (name === 'eu') return 'EU';
        return name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const givenCountry = formatCountry(region_code);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null)
        try {
            const response = await axios.get(`/reg/v1/document/international_doc/${givenCountry}`, {
                params: {
                    'search_input': searchInput
                }
            });
            const data = await response.data
            setEvents(data);
            setTotalPages(Math.ceil(data.length / itemsPerPage));
        }
        catch (error) {
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const changeShowData = () => {
        const initialIndex = Math.max((currentPage - 1) * itemsPerPage, 0);
        const lastIndex = initialIndex + itemsPerPage;
        setShowEvents(events.slice(initialIndex, lastIndex));
    }



    const onApplySort = () => {
        if (!events || events.length === 0 || isLoading) {
            return;
        }

        let sortedEvents = [...events];
        if (sortConfig.direction === 'asc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setEvents(sortedEvents);
        changeShowData();
    };

    const onResetSort = () => {
        if (!events || events.length === 0 || isLoading) {
            return;
        }
        let sortedEvents = [...events];
        sortedEvents.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'publish_date', direction: 'desc' });
        setEvents(sortedEvents);
        changeShowData();
    };


    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC'
        });
    }

    // const getUrl = (s3_link) => {
    //     const domainParts = new URL(s3_link).hostname.split('.');
    //     return domainParts[domainParts.length - 3].toUpperCase();
    // }

    useEffect(() => {
        setTotalPages(Math.ceil(events.length / itemsPerPage))
        changeShowData();
    }, [currentPage, itemsPerPage, events])

    useEffect(() => {
        fetchData();
        currentPage !== 1 && setCurrentPage(1);
    }, [searchInput, country])



    const sortFields = [['Publish Date', 'publish_date']]
    const topRow = 'title';
    const secondaryRow = [['Document Type', 'documentType']];
    const thirdRow = [['Agency', 'long_name']];
    const bottomRow = [['Publish Date', 'publish_date', formatDate]];
    const summaryRow = 'mini_summary';
    const links = ['', 'id'];

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span>{formatCountry(country)}</span>
                            </div>
                        </h1>
                    </div>
                </div>
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                    <div className="col-7">
                        <SearchBar
                            searchInput={searchInput}
                            setSearchInput={setSearchInput}
                            placeholder={`Search ${formatCountry(country)} Documents`}
                        />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                        </button>
                        <SortFilterWrapper
                            sortFields={sortFields}
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                    <>
                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={0} />
                    </>
                </div>
                {error && <div className="error-message">{error.message}</div>}
                {isLoading ? <HourGlass /> :
                    (<DocumentListWrapper
                        filteredDocuments={showEvents}
                        topRow={topRow}
                        secondaryRow={secondaryRow}
                        thirdRow={thirdRow}
                        bottomRow={bottomRow}
                        summaryRow={summaryRow}
                        linkTo={'doc_id'}
                        links={links}
                        isInternational={true}
                        currentPage={currentPage}
                        searchInput={searchInput}
                    />
                    )}
                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                        <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }
            </div>
        </div>
    )
}

export default Country
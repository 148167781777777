import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';
import axios from 'axios';

type Props = {
    className: string;
    chartHeight: string;
    data?: DataItem[];
    title: string;
    activity: string;
    columns: { [key: string]: any; };
    scale: string;
    days: number;
    doctype: string;
    showChart?: boolean;
    summaryPage?: boolean;
};

interface DataItem {

    decision_date: string;
    entity_name: string;
    generic_name: string;
    pma_number: string;
    product_code: string;
    specialty: string;
    supplement_number: string;
    trade_name: string;
    device_class: string;

}
const processDataForAdvisoryCommitteeVsDays = (data) => {
    // Directly map the API data to the format expected by Highcharts
    const seriesData = data.filter(item => item.category === 'specialty').map(item => ({
        name: item.code, // Assuming you still want to use 'code' as the name
        data: [item.count] // Use 'count' directly
    })).sort((a, b) => a.name.localeCompare(b.name));
    return { seriesData };
};

const processDataForClassVsDays = (data) => {
    const seriesData2 = data.filter(item => item.category === 'device_class').map(item => ({
        name: item.code, // Use 'category' as the name
        data: [item.count] // Use 'count' directly
    })).sort((a, b) => a.name.localeCompare(b.name));

    return { seriesData2 };
};


const MixedWidgetPMA: React.FC<Props> = ({ className, chartHeight, title, days, doctype, showChart, summaryPage = false }) => {
    // Step 1: Group by Program
    const [data, setData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        setLoading(true);
        axios.get(`/reg/v1/fda/preference/pma/graph?days=${days}`)
            .then(response => {
                setLoading(false);
                console.log("response", response);
                setData(response.data); // Assuming response.data is an array of DataItem objects
            })
            .catch(error => { console.error('Error fetching data:', error); setLoading(false); });
    }, [days]);
    const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);


    const chartOptions = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Specialty'
            },
            labels: {
                enabled: false // This will hide the x-axis labels
            },
            width: '90%',
        },
        yAxis: {
            min: 1.0,
            type: 'logarithmic',
            title: {
                text: 'PMAs'
            },

        },
        plotOptions: {

            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,


            },
        },
        credits: {
            enabled: false
        }, legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart

            margin: 4,
            itemMarginBottom: 1,


        }, colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        tooltip: {
            useHTML: true, // Enable HTML in the tooltip
            formatter: function (this: Highcharts.PointLabelObject) {
                // Custom formatting for the tooltip content
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            },
            style: {
                width: '100px' as any, // Set a fixed width for the tooltip
                whiteSpace: 'normal' // Allow text to wrap within the tooltip
            }
        },
        series: seriesData
    };

    const { seriesData2 } = processDataForClassVsDays(data);

    const chartOptions2 = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Device Class'
            },
            labels: {
                enabled: false // This will hide the x-axis labels
            },
            width: '90%',
        },
        yAxis: {
            min: 1.0,
            type: 'logarithmic',
            title: {
                text: 'PMAs'
            },

        },
        plotOptions: {

            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,


            },
        },
        credits: {
            enabled: false
        }, legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart

            margin: 4,
            itemMarginBottom: 1,


        }, colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        tooltip: {
            useHTML: true, // Enable HTML in the tooltip
            formatter: function (this: Highcharts.PointLabelObject) {
                // Custom formatting for the tooltip content
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            },
            style: {
                width: '100px' as any, // Set a fixed width for the tooltip
                whiteSpace: 'normal' // Allow text to wrap within the tooltip
            }
        },
        series: seriesData2
    };


    return (
        <div>
            <div className={`row justify-content-center ${summaryPage ? 'g-5 mt-1 mb-4' : ''}`}>
                <div className={showChart ? "col-6" : "col"}>
                    <div className={`card ${className} `} >
                        {showChart && (
                            <>
                                <div className="card-header border-0 py-5 d-flex justify-content-center">
                                    <h3 className='card-title align-items-center justify-center flex-column'>
                                        <span className='card-label fw-bold fs-3 mb-1'>{doctype} by Specialty</span>
                                    </h3>
                                </div>
                                {loading ? (
                                    <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                        <span className='mb-3'>Loading...</span>
                                    </div>
                                ) : data.length > 0 ? (
                                    <div className='card-body d-flex flex-column'>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                    </div>
                                ) : (
                                    <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                        <span className='mb-3 fs-3'>No graph data available</span>
                                    </div>
                                )}
                            </>
                        )}
                        {!showChart && <div className='card-body d-flex flex-column'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        </div>}
                    </div>
                </div>
                {showChart &&
                    <div className="col-6">
                        <div className={`card ${className} `} >
                            <div className='card-header border-0 py-5 d-flex justify-content-center'>
                                <h3 className='card-title align-items-center justify-center flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>{doctype} by Device Class</span>
                                </h3>
                            </div>
                            {loading ? (
                                <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                    <span className='mb-3'>Loading...</span>
                                </div>
                            ) : data.length > 0 ? (
                                <div className='card-body d-flex flex-column'>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions2}
                                    />
                                </div>
                            ) : (
                                <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                    <span className='mb-3 fs-3'>No graph data available</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export { MixedWidgetPMA };
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';

const PMADetails = () => {
    const { id, supplement } = useParams();
    const [device, setDevice] = useState([]);
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const prevPage = location.state ? location.state.prevPage : null;
    const searchTerm = location.state ? location.state.searchTerm : '';
    const currentPage = location.state ? location.state.currentPage : 1;
    const replacements = {
        '': '™',
        '': '"',
        '': '"',
        '®': '®',
        '©': '©',
        '': '—',
        '': '–',
        '': '•',
        '': '\'',
        '': '\'',
        '': '…',
        '': '¨',
        '': '‰',
        '': '‹',
        '': '›',
        '': '«',
        '': '»',
        '¿': '®',
    }
    useEffect(() => {
        const fetchDevice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/fda/pma/id/${id}/${supplement}`);
                setDevice(response.data[0]);
                // console.log("device", response.data[0])
                setIsLoading(false);
            } catch (error) {
                console.log('Error fetching document:', error);
                setIsLoading(false);
            }
        };
        fetchDevice();
    }, [id, supplement]);

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={prevPage === "home" ? "/home" : "/device-intelligence/pma"}
                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        {prevPage === "home" ? "Home Page" : 'PMAs List'}
                    </Link>
                </div>
            </div>
            <div className='card mt-4 mb-4'>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>PMA Number: {device?.pma_number}</h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Loading PMA Details...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {device?.device_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{Object.keys(replacements).reduce((str, key) => {
                                                    return str.replace(new RegExp(key, 'g'), replacements[key]);
                                                }, device?.device_name)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.trade_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Trade Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{Object.keys(replacements).reduce((str, key) => {
                                                    return str.replace(new RegExp(key, 'g'), replacements[key]);
                                                }, device?.trade_name)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.generic_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Generic Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.generic_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.applicant && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Company</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.applicant}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.product_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Code</span>
                                            </div>
                                            <div className="col-10">
                                                <Link to={`/device-intelligence/classification/${device.product_code}`} target="_blank" rel="noopener noreferrer" className="fw-bold fs-5" style={{ color: 'primary' }}>{device.product_code}</Link>
                                            </div>
                                        </div>
                                    )}
                                    {device?.openfda_device_class && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Class</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.openfda_device_class}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.openfda_regulation_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Regulation Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.openfda_regulation_number}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.advisory_committee_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}> Specialty</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.advisory_committee_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.supplement_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Supplement Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.supplement_number}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.supplement_reason && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Supplement Reason</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.supplement_reason}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.date_received && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Date Received</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {new Date(device?.date_received).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {device?.decision_date && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Decision Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{new Date(device?.decision_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className={clsx('tab-pane', { active: tab === 'PDF' })}>
                                <h3>Show PDF</h3>
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default PMADetails;
// MyDocumentsTableRow.tsx
import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';

const MyDocumentsTableRow = ({
    uploadedFile,
    navigate,
    editingFile,
    setEditingFile,
    setName,
    setDescription,
    name,
    description,
    currentUser,
    updateDocument,
    fetchFiles,
    setDeleteConfirmation,
    setDocumentToDelete
}) => {
    return (
        <tr
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (!editingFile) {
                    navigate(`/apps/mydocuments/${uploadedFile}`,
                        {
                            state:
                            {
                                uploadedFile: uploadedFile
                            }
                        })
                }
            }}
        >
            <td className="text-start ps-6" onClick={(event) => event.stopPropagation()}>
                <button
                    type='button'
                    className='btn btn-icon btn-active-light-primary btn-sm ms-2'
                    data-bs-toggle='tooltip'
                    title={editingFile === uploadedFile ? 'Cancel' : 'Edit'}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (editingFile === uploadedFile) {
                            setEditingFile(null);
                        } else {
                            setEditingFile(uploadedFile);
                            setName(uploadedFile.name);
                            setDescription(uploadedFile.description);
                        }
                    }}
                >
                    {editingFile === uploadedFile ? (
                        <i className='fas fa-times fs-2 text-danger'></i>
                    ) : (
                        <KTIcon iconName='pencil' className='fs-2 duotone text-primary' />
                    )}
                </button>
            </td>
            <td className="text-start">
                {editingFile === uploadedFile ? (
                    <>
                        <textarea
                            rows={2}
                            maxLength={100}
                            value={name}
                            onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                // Update the name of the file
                                setName(event.target.value);
                            }}
                            onClick={(event) => event.stopPropagation()}
                            style={{ width: '100%', padding: '10px', overflow: 'auto', resize: 'none' }}
                        />
                        <div className="me-2" style={{ textAlign: 'right' }}>{name.length}/100</div>
                    </>
                ) : (
                    uploadedFile.name
                )}
            </td>
            <td className="text-start">
                {editingFile === uploadedFile ? (
                    <>
                        <textarea
                            rows={2}
                            maxLength={500}
                            //value={description}
                            defaultValue={description}
                            onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                // Update the description state variable
                                setDescription(event.target.value);
                            }}
                            onClick={(event) => event.stopPropagation()}
                            style={{ width: '100%', padding: '10px', overflow: 'auto' }}
                        />
                        <div className="me-2" style={{ textAlign: 'right' }}>{description.length}/500</div>
                    </>
                ) : (
                    uploadedFile.title
                )}
            </td>
            {/* <td className="text-start">{currentUser?.first_name} {currentUser?.last_name}</td> */}
            <td className="text-end">{new Date(uploadedFile.create_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
            <td className="text-end px-5" onClick={(event) => event.stopPropagation()}>
                {editingFile === uploadedFile ? (
                    <button
                        type='button'
                        className='btn btn-icon btn-active-light-primary btn-sm me-4'
                        data-bs-toggle='tooltip'
                        title='Confirm'
                        onClick={async (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            // Update the document on the server
                            await updateDocument(uploadedFile.id, name, description);
                            fetchFiles();
                            setEditingFile(null);
                        }}
                    >
                        <i className='fas fa-check fs-2 text-success'></i>
                    </button>
                ) : (
                    <button
                        type='button'
                        className='btn btn-icon btn-active-light-primary btn-sm me-4'
                        data-bs-toggle='tooltip'
                        title='Delete'
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setDeleteConfirmation(true);
                            setDocumentToDelete(uploadedFile); // Store the entire document object
                        }}
                    >
                        <KTIcon iconName='trash' className='fs-2 duotone text-danger' />
                    </button>
                )}
            </td>

        </tr>
    );
};

export default MyDocumentsTableRow;
import React, { useEffect, useRef, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers'
import SearchBar from '../../components/SearchBar';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap'
function TextFileViewer({ id }) {
    const [content, setContent] = useState('');
    useEffect(() => {
        fetch(`/files/${id}.txt`)
            .then(response => response.text())
            .then(data => {
                setContent(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });

    return (
        <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100"
            style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}>
            {content}
        </div>
    );
}

const SenateBills = () => {
    const { id } = useParams();
    const [searchInput, setSearchInput] = useState(id || '');
    const highlightedRef = useRef(null);
    useEffect(() => {
        if (highlightedRef.current) {
            highlightedRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [searchInput]);
    const [bills, setBills] = useState([
        {
            id: "2709",
            billnumber: "S.2709",
            type: "Senate",
            title: "Medical Device Recall Improvement Act",
            Status: "INTRODUCED IN SENATE",
            Commitee: "Senate Committee On Health, Education, Labor, And Pensions",
            LatestAction: "09-21-2023",
            part: "US Congress (118th)",
            summary: "<p><strong>Informing Consumers about Smart Devices Act</strong></p> <p>This bill requires manufacturers of internet-connected devices (e.g., smart appliances) that are equipped with a camera or microphone to disclose to consumers prior to purchase that a camera or microphone is part of the device.</p> <p>The bill does not apply to mobile phones, laptops, or other devices that a consumer would reasonably expect to include a camera or microphone.</p>",
            track: false
        },
        {
            id: "2115",
            billnumber: "S.2115",
            type: "Senate",
            title: "Medical Supply Chain Resiliency Act",
            Status: "INTRODUCED IN SENATE",
            Commitee: "Senate Committee On Finance",
            LatestAction: "06-22-2023",
            part: "US Congress (118th)",
            summary: "Summary is not available.",
            track: false

        },
        {
            id: "90",
            billnumber: "S.90",
            type: "Senate",
            title: "Informing Consumers about Smart Devices Act",
            Status: "REPORTED TO SENATE",
            Commitee: "Senate Committee On Commerce, Science, And Transportation",
            LatestAction: "11-28-2023",
            part: "US Congress (118th)",
            summary: "<p><strong>Informing Consumers about Smart Devices Act</strong></p> <p>This bill requires manufacturers of internet-connected devices (e.g., smart appliances) that are equipped with a camera or microphone to disclose to consumers prior to purchase that a camera or microphone is part of the device.</p> <p>The bill does not apply to mobile phones, laptops, or other devices that a consumer would reasonably expect to include a camera or microphone.</p>",
            track: false
        },
    ]);

    const handleTrack = (billnumber) => {
        setBills(prevBills => prevBills.map(bill =>
            bill.billnumber === billnumber ? { ...bill, track: !bill.track } : bill
        ));
    };

    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                <KTIcon iconName='book-square' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' style={{ backgroundColor: "#d9f2d9" }} /> Senate Bills
            </h1>
            <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} placeholder={"Search Senate Bills"} />
            <table className="table">
                <tbody>
                    {bills.map((bill) => (
                        <div
                            key={bill.billnumber}
                            ref={bill.billnumber === searchInput ? highlightedRef : null}
                            className="card card-custom mb-3" style={{ borderRadius: '10px' }}>
                            <div className="card-header d-flex justify-content-between align-items-center"
                                style={{ backgroundColor: bill.billnumber === searchInput ? "#d9f2d9" : "#f1faff" }}
                            >
                                <h2 className="card-title">{bill.billnumber}</h2>
                                <button
                                    className={`btn ${bill.track ? 'btn-success' : 'btn-primary'} h-26px w-140px btn-sm d-flex align-items-center justify-content-center`}
                                    // className="btn btn-primary h-26px  w-140px btn-sm d-flex align-items-center justify-content-center" 
                                    onClick={() => handleTrack(bill.billnumber)}>
                                    <i className="bi bi-check-circle-fill"></i>
                                    {bill.track ? 'Tracking' : 'Track'}
                                </button>
                            </div>
                            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="">
                                <Tab eventKey="details" title="Details">
                                    <div className="card-body" style={{ fontSize: '15px' }}>
                                        <p style={{ color: 'mediumblue' }}>{bill.Status}</p>
                                        <p>Type: {bill.type}</p>
                                        <h3>Title: {bill.title}</h3>
                                        <p>Committee: {bill.Commitee}</p>
                                        <p>Latest Action Date: {bill.LatestAction}</p>
                                        <p className='text-muted'>{bill.part}</p>
                                    </div>
                                </Tab>
                                <Tab eventKey="summary" title="Summary">
                                    {/* <SummaryViewer billnumber={bill.billnumber}></SummaryViewer> */}
                                    <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100 "
                                        style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: bill.summary }}>
                                    </div>
                                </Tab>
                                <Tab eventKey="text" title="Text">
                                    <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100 "
                                        style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}>
                                        <TextFileViewer id={bill.id} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SenateBills;

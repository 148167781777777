import React from 'react';



const SortFilter = ({
    sortConfig, setSortConfig, setSortDirection, onApplySort, onResetSort

}) => {
    const handleSortChange = (key, direction) => {
        //console.log("sort", sortConfig);
        //console.log(key, direction);
        setSortConfig({ key, direction });
        setSortDirection(direction);
    };
    return (
        <div className='menu menu-sub menu-sub-dropdown w-180px w-md-230px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Sort by</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-4'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-gray-700 fs-5 fw-bold">Field</th>
                                <th className="text-gray-700 fs-5 fw-bold">Asc</th>
                                <th className="text-gray-700 fs-5 fw-bold">Desc</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">ID</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="ascId"
                                            value="asc"
                                            checked={sortConfig.key === 'id' && sortConfig.direction === 'asc'}
                                            onChange={(e) => handleSortChange('id', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td >
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="descId"
                                            value="desc"
                                            checked={sortConfig.key === 'id' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('id', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr> */}
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Company Name</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderTitle"
                                            id="ascTitle"
                                            value="asc"
                                            checked={sortConfig.key === 'company_name' && sortConfig.direction === 'asc'}
                                            onChange={() => handleSortChange('company_name', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderTitle"
                                            id="descTitle"
                                            value="desc"
                                            checked={sortConfig.key === 'company_name' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('company_name', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Subject</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderType"
                                            id="ascType"
                                            value="asc"
                                            checked={sortConfig.key === 'subject' && sortConfig.direction === 'asc'}
                                            onChange={() => handleSortChange('subject', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderType"
                                            id="descType"
                                            value="desc"
                                            checked={sortConfig.key === 'subject' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('subject', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Issuing Office</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="ascId"
                                            value="asc"
                                            checked={sortConfig.key === 'issuing_office' && sortConfig.direction === 'asc'}
                                            onChange={(e) => handleSortChange('issuing_office', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td >
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="descId"
                                            value="desc"
                                            checked={sortConfig.key === 'issuing_office' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('issuing_office', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Posted Date</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderPosted"
                                            id="ascPosted"
                                            value="asc"
                                            checked={sortConfig.key === 'posted_date' && sortConfig.direction === 'asc'}
                                            onChange={() => handleSortChange('posted_date', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderPosted"
                                            id="descPosted"
                                            value="desc"
                                            checked={sortConfig.key === 'posted_date' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('posted_date', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Letter Issue Date</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderCommentStart"
                                            id="ascCommentStart"
                                            value="asc"
                                            checked={sortConfig.key === 'letter_issue_date' && sortConfig.direction === 'asc'}
                                            onChange={() => handleSortChange('letter_issue_date', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderCommentStart"
                                            id="descCommentStart"
                                            value="desc"
                                            checked={sortConfig.key === 'letter_issue_date' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('letter_issue_date', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Comment End Date</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="sortOrderCommentEnd"
                                    id="ascCommentEnd"
                                    value="asc"
                                    checked={sortConfig.key === 'commentEndDate' && sortConfig.direction === 'asc'}
                                    onChange={() => handleSortChange('commentEndDate', 'asc')}
                                />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="sortOrderCommentEnd"
                                    id="descCommentEnd"
                                    value="desc"
                                    checked={sortConfig.key === 'commentEndDate' && sortConfig.direction === 'desc'}
                                    onChange={() => handleSortChange('commentEndDate', 'desc')}
                                />
                                    </div>
                                </td>
                            </tr> */}

                            {/* Repeat for other fields */}
                        </tbody>
                    </table>

                </div>


                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetSort}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplySort}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SortFilter;

import { FC, useState, useEffect } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import QASuggestions from './QASuggestions';
import axios from 'axios';
import QaAnswer from '../../components/QAMessage/QaAnswer';
import QaQuestion from '../../components/QAMessage/QaQuestion';

interface QAInterfaceProps {
    documentId: string;
    subtype: string;
    documentType: string;
    error: boolean;
}

interface QAPair {
    question: string;
    answer: string;
    error: boolean;
}

const QAInterface: FC<QAInterfaceProps> = ({ documentId, subtype, documentType }) => {
    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [suggestedQuestions, setSuggestedQuestions] = useState<{ id: string; question: string }[]>([]);
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);

    // Fetch suggested questions from the API
    const fetchSuggestedQuestions = async () => {
        try {
            const response = await axios.get('/reg/v1/document/get_questions'); // Update with your actual API URL if needed
            setSuggestedQuestions(response.data); // Assuming response.data is an array of question objects
        } catch (error) {
            console.error('Error fetching suggested questions:', error);
        }
    };

    // Load suggested questions when the component mounts
    useEffect(() => {
        fetchSuggestedQuestions();
    }, []);

    const askQuestion = async () => {
        console.log('Question', question);
        const currentQuestion = question;
        setQAPairs(prevQAPairs => [
            { question: currentQuestion, answer: 'Thinking ...', error: false },
            ...prevQAPairs,
        ]);
        setQuestion('');

        try {
            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL}/answer-query/${subtype}/${documentType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: currentQuestion, document_id: documentId, sub_type: subtype, document_type: documentType }),
            });

            if (!response.body) {
                throw new Error('No response body');
            }

            const questionPosted = await axios.post('/reg/v1/document/add_question', {
                question: currentQuestion,
            });
            console.log(questionPosted, '--');

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    return;
                }

                text += new TextDecoder().decode(value);
                setQAPairs(prevQAPairs => {
                    const newQAPairs = [...prevQAPairs];
                    newQAPairs[0].answer = text;
                    return newQAPairs;
                });

                await readStream();
            };

            await readStream();
            
        } catch (error) {
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs];
                newQAPairs[0].answer = (error as Error).message;
                newQAPairs[0].error = true;

                return newQAPairs;
            });
        }
        fetchSuggestedQuestions();
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).catch(() => {
            alert('Failed to copy.');
        });
    };

    const downloadAnswer = (QAanswer: string) => {
        const blob = new Blob([QAanswer]);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Answer.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div className='card-body mt-5 ms-2 me-3' style={{ maxHeight: '85vh' }}>
            <QASuggestions setQuestion={setQuestion} askQuestion={askQuestion} />
            <div className='d-flex position-relative mb-2'>
                <textarea
                    className="form-control form-control-lg pt-3 pb-2"
                    placeholder="Please ask a question"
                    value={question}
                    onChange={(e) => {
                        setQuestion(e.target.value);
                    }}
                    onFocus={() => {
                        setIsDropDownVisible(true); // Show dropdown when focused
                    }}
                    onBlur={() => {
                        setIsDropDownVisible(false); // Hide dropdown when not focused
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && question.trim() !== '') {
                            askQuestion(); // Call askQuestion on Enter key press
                            e.preventDefault();
                        }
                    }}
                    style={{ paddingRight: '35px', resize: 'none' }} // Make room for the icon and disable resizing
                    rows={1} // Start with one row
                />
                {/* Dropdown for suggestions */}
                {isDropDownVisible && suggestedQuestions.length > 0 && (
                    <div className="dropdown-menu show position-absolute" style={{ top: '100%', left: 0, right: 0, zIndex: 1000 }}>
                        {suggestedQuestions.map((item,index) => (
                            item.question.toLowerCase().includes(question.toLowerCase()) &&(<div
                                key={index}
                                className="dropdown-item"
                                onMouseDown={() => {  // `onMouseDown` to avoid blur issue
                                    setQuestion(item.question.trim());
                                    setIsDropDownVisible(false);
                                }}
                            >
                                {item.question.trim()}
                            </div>)
                        ))}
                    </div>
                )}
                {question.trim() !== '' && (
                    <button
                        onClick={askQuestion}
                        className='position-absolute end-0 top-50 translate-middle-y'
                        style={{ background: 'none', border: 'none' }}
                    >
                        <KTIcon
                            iconName='arrow-right'
                            iconType="duotone"
                            className='fw-bold fs-1 ms-2'
                        />
                    </button>
                )}
            </div>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                {qaPairs.map((qaPair, index) => (
                    <div key={index} className='d-flex flex-column mt-3'>
                        <>
                            <QaAnswer qaPair={qaPair} copyToClipboard={copyToClipboard} downloadAnswer={downloadAnswer}/>
                        </>
                        <>
                            <QaQuestion qaPair={qaPair}/>
                        </>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { QAInterface };

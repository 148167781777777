import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { marked } from 'marked';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';

const FAQViewer = ({ iddocument, name }) => {
    const [textData, setTextData] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isLoading, setIsLoading] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const renderedDataRef = useRef();
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/document/mydocument/faq_link/${iddocument}`);
                if (response.headers['content-type'] === 'text/plain') {
                    const formattedText = marked(response.data);
                    setTextData(formattedText);
                    setAlert({ message: '', type: '' });
                } else {
                    const url = response.data.url;


                    if (url) {  // Check if url is not null
                        const textResponse = await axios.get(url);
                        if (response.headers['content-type'] === 'application/json') {
                            const formattedText = textResponse.data
                            setTextData(formattedText);
                            setAlert({ message: '', type: '' });
                        }
                    } else {
                        setAlert({ message: 'FAQ for this document is still being generated. Please check back later!', type: 'info' });  // Set textData to 'No file found' if url is null
                    }

                }

            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            logout();
                            navigate('/auth/login');
                            break;
                        case 400:
                            setAlert({ message: 'FAQ for this document is still being generated. Please check back later!', type: 'info' });
                            break;
                        default:
                            setAlert({ message: 'An error occurred', type: 'danger' });
                    }
                }
                else {
                    setAlert({ message: 'An error occurred!', type: 'danger' });
                }
            }
            setIsLoading(false);
        };

        fetchData();
    }, [iddocument, logout, navigate]);

    const renderData = (data) => {
        return Object.entries(data).map(([key, value], index) => {
            if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className="ps-1 card-title fs-5 fw-bold text-gray-800  text-justify  ">
                                <Col md={12} >{key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className="ps-1  mb-6 card-title fs-6 pb-2 text-justify " >
                                <Col md={12}>{renderData(value)}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className="ps-1 card-title fs-5 fw-bold  text-gray-800    text-justify ">
                                <Col md={12}>{index + 1}.  {key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className=" ps-1 mb-6 card-title fs-6 pb-2 text-justify " >
                                <Col md={12}>{value}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
        });
    }
    const handleDownload = () => {
        let textString = renderedDataRef.current.innerText;

        // Split the text into lines
        let lines = textString.split('\n');

        let questionCounter = 1;

        // Process each line
        lines = lines.map(line => {
            // Remove ** from the line
            line = line.replace(/\*\*/g, '');

            // Trim the line
            line = line.trim();

            // Add number to the line if it's a question
            if (line.endsWith('?')) {
                line = `${questionCounter}. ${line}`;
                questionCounter++;
            }

            // Return the processed line
            return line;
        });

        // Join the lines back together, adding extra line breaks between them
        textString = lines.join('\n\n');

        // Add some space at the top of the file
        textString = '\n\n\n' + textString;

        const blob = new Blob([textString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const baseName = name.replace(/\.[^/.]+$/, "");
        link.download = `${baseName} faq.txt`;
        link.click();
    };

    return (
        <>
            {/* {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    
                </div>
            )} */}
            {isLoading ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) :
                textData ? (
                    <Container className="w-100 text-wrap  vh-100"
                        style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap', marginLeft: 0 }}>
                        <div className="d-flex justify-content-end mb-4">
                            <Button className="btn btn-primary " onClick={handleDownload}><i className="bi bi-download fs-4 me-2"></i>Download</Button>
                        </div>
                        <div ref={renderedDataRef}>
                            {renderData(textData)}
                        </div>
                    </Container>
                ) : (
                    <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                        <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                            {alert.message}

                        </div>
                        {alert.type === 'info' && (
                            <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} />
                        )}                </div>
                )}
        </>

    );
};

export default FAQViewer;
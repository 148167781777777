import React from 'react'
import { useParams, useLocation } from 'react-router-dom';
import Country from './Country';

function CountryWrapper() {
  const { country } = useParams();
  const location = useLocation();
  const { state } = location;
  const region_code = state?.region_code || '';

  return (
    <>
      <Country country={country} region_code={region_code} />
    </>
  )
}

export default CountryWrapper;


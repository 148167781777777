import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { useAuth } from '../../modules/auth';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CommentDetails = (props) => {
    const { id: idParam } = useParams();
    const id = props.id || idParam;
    const location = useLocation();
    const documentId = props.documentId || (location.state && location.state.documentId);
    const [comment, setComment] = useState([]);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    };

    useEffect(() => {
        const fetchComment = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/comment/${documentId}`);
                const comment = response.data.find(item => item.id === id);
                setComment(comment);
                setIsLoading(false);
            } catch (error) {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Error fetching document:', error);
                    setIsLoading(false);
                }
            }
        };
        fetchComment();
    }, [id, documentId, logout, navigate]);

    const handleAttachmentClick = (id, filetype, type) => {

        const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
        window.open(url, '_blank');
    };

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={"/document/" + documentId}
                        state={{ activeTab: 'PublicSubmissions' }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Comments List
                    </Link>
                </div>
            </div>
            <div className='card mt-4 mb-4'>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>Comment ID: {id}</h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Loading Comment Details...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Attachments' })}
                                onClick={() => setTab('Attachments')}
                                role='tab'
                            >
                                Attachments
                            </h5>
                        </li>
                    </ul>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {comment.commentOnDocumentId && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Document ID</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{comment.commentOnDocumentId}</span>
                                            </div>
                                        </div>
                                    )}
                                    {comment.category && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Category</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{comment.category}</span>
                                            </div>
                                        </div>
                                    )}
                                    {((comment.firstName || comment.lastName) && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Author</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{`${comment.firstName || ''} ${comment.lastName || ''}`}</span>
                                            </div>
                                        </div>
                                    ))}
                                    {((!comment.firstName && !comment.lastName) && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Author</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">Anonymous</span>
                                            </div>
                                        </div>
                                    ))}
                                    {comment.organization && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Organization</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{comment.organization}</span>
                                            </div>
                                        </div>
                                    )}
                                    {comment.postedDate && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Posted Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{formatDate(comment.postedDate)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {comment.sentiment !== null && comment.sentiment !== undefined && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Sentiment</span>
                                            </div>
                                            <div className="col-10">
                                                {comment.sentiment < -0.25 ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-top`}>Negative</Tooltip>}
                                                    >
                                                        <span><KTIcon iconName='arrow-down' iconType="solid" className=' fw-bold fs-1 text-danger' /></span>
                                                    </OverlayTrigger>
                                                ) : comment.sentiment > 0.25 ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-top`}>Positive</Tooltip>}
                                                    >
                                                        <span ><KTIcon iconName='arrow-up' iconType="solid" className=' fw-bold fs-1 text-success' /></span>
                                                    </OverlayTrigger>
                                                ) : comment.sentiment === 0.0 ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-top`}>Neutral</Tooltip>}
                                                    >
                                                        <span><KTIcon iconName='minus' iconType="solid" className=' fw-bold fs-1 text-warning' /></span>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id={`tooltip-top`}>Neutral</Tooltip>}
                                                    >
                                                        <span><KTIcon iconName='minus' iconType="solid" className=' fw-bold fs-1 text-warning' /></span>
                                                    </OverlayTrigger>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {comment.comment && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Comment</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 " dangerouslySetInnerHTML={{ __html: comment.comment }}></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={clsx('tab-pane', { active: tab === 'Attachments' })}>
                                <div className="card-body card-scroll">
                                    {comment.attachments && comment.attachments.some(attachment => attachment.attachment_title) ? (
                                        comment.attachments.map((attachment, index) => (
                                            attachment.attachment_title && (
                                                <div className="mb-3 row" key={index}>
                                                    {/* <div className="col-1">
                                                        <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                            {attachment.sentiment === null ? (
                                                                ''
                                                            ) : attachment.sentiment < -0.25 ? (
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id={`tooltip-top`}>Negative</Tooltip>}
                                                                >
                                                                    <span><KTIcon iconName='arrow-down' iconType="solid" className=' fw-bold fs-1 text-danger' /></span>
                                                                </OverlayTrigger>
                                                            ) : attachment.sentiment > 0.25 ? (
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id={`tooltip-top`}>Positive</Tooltip>}
                                                                >
                                                                    <span ><KTIcon iconName='arrow-up' iconType="solid" className=' fw-bold fs-1 text-success' /></span>
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id={`tooltip-top`}>Neutral</Tooltip>}
                                                                >
                                                                    <span><KTIcon iconName='minus' iconType="solid" className=' fw-bold fs-1 text-warning' /></span>
                                                                </OverlayTrigger>
                                                            )}
                                                        </span>
                                                    </div> */}
                                                    <div className="col-1">
                                                        <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                            #{index + 1}
                                                        </span>
                                                    </div>
                                                    <div className="col-10">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <span className="card-title fw-bold fs-5" onClick={() => handleAttachmentClick(attachment.idattach, attachment.fileFormat, "comment")} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                                                            {attachment.attachment_title}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                        ))
                                    ) : (
                                        <div className="mb-3 row">
                                            {/* <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>
                                                    Attachments
                                                </span>
                                            </div> */}
                                            <div className="col-12">
                                                <h5 className="card-title text-gray-700 pb-0">No attachments found for given comment.</h5>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommentDetails;
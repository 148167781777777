// TableRowComponent.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
type Document = {
    review_advisory_committee: string;
    device_name: string;
    applicant: string;
    decision_date: string;
    k_number: string;
    product_code: string;

    //   document_title: string;
    //   modifyDate: string;
    //   program: string;
}
interface TableRow510Props {
    items: Document[];
}

const TableRow510: React.FC<TableRow510Props> = React.memo(({ items }) => {
    const navigate = useNavigate();
    const replacements = {
        '': '™',
        '': '"',
        '': '"',
        '®': '®',
        '©': '©',
        '': '—',
        '': '–',
        '': '•',
        '': '\'',
        '': '\'',
        '': '…',
        '': '¨',
        '': '‰',
        '': '‹',
        '': '›',
        '': '«',
        '': '»',
    }
    return (
        <>
            {items?.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-gray-600 fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (items?.map((item: Document, index) => (
                <tr className='bg-hover-light-primary' key={index} onClick={() => { navigate(`/device-intelligence/device/${item.k_number}`, { state: { prevPage: 'home' } }) }} >
                    <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                        {item.k_number}
                    </td>
                    {/* <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.id}
                </td> */}
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>


                        {
                            item.device_name && replacements ?
                                Object.keys(replacements).reduce((str, key) => {
                                    return str.replace(new RegExp(key, 'g'), replacements[key]);
                                }, item.device_name)
                                : ''
                        }
                    </td>
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.product_code}
                    </td>
                    {/* <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                                                        {item.program_id}
                                                    </td> */}
                    <td className='text-gray-800 text-center fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.review_advisory_committee}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.applicant}
                    </td>



                    <td className='text-gray-800 text-end fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {new Date(item.decision_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>


                </tr>
            )))}
        </>
    );
});

export default TableRow510;
import React from 'react'

function ItemsPerPageWrapper({itemsPerPage,setItemsPerPage,bottomSpace = 7}) {
  return (
    <div className={`d-flex flex-column-auto ms-4 d-flex align-items-center mb-${bottomSpace}`}>
        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
        <input
            type="number"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
            className='form-control form-control-solid ms-2 border border-1'
            style={{ width: '90px', backgroundColor: '#f1faff' }}
        />
    </div>
  )
}

export default ItemsPerPageWrapper
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import AnnouncementDisplay from '../../../../app/pages/Home/AnnouncementDisplay'
import { useAuth } from '../../../../app/modules/auth'
import {
  // defaultAlerts,
  // defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  // useIllustrationsPath,
} from '../../../helpers'
interface Notification {
  comment_id: string;
  content: string;
  create_date: number;
  docket_id: string;
  document_id: string;
  is_read: number;
  modified_date: number;
  notification_id: number;
  notification_type: string;
  user_id: number;
}
const HeaderNotificationsMenu: FC = () => {
  const [alerts, setAlerts] = useState<Notification[]>([]);
  const { currentUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`/reg/v1/user/notification/${currentUser?.id}`);
        // console.log(response.data);
        const sortedAlerts = response.data
          .filter((alert: Notification) => alert.is_read === 0)
          .sort((a: Notification, b: Notification) =>
            new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
          );
        setAlerts(sortedAlerts.slice(0, 10));
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };
    fetchNotifications();
  }, [currentUser?.id]);
  return (
    <div
      className={`menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px ${isOpen ? 'show' : ''}`}
      data-kt-menu='true'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Alerts
          {/* <span className='fs-8 opacity-75 ps-3'>24 reports</span> */}
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Notifications
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Announcements
            </a>
          </li>

          {/* <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li> */}
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {alerts.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-info}`)}>
                      {' '}
                      <KTIcon iconName="notification" className={`fs-2 text-primary`} />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    {alert.comment_id && <Link to={`/comment/${alert.comment_id}`}>{alert.comment_id}</Link>}
                    <div className='text-gray-400 fs-7'>{alert.content}</div>
                  </div>
                </div>

                <span className='badge badge-primary fs-8'>{new Date(alert.create_date).toLocaleDateString(undefined, { day: 'numeric', month: 'short' })}</span>
              </div>
            ))}
          </div>

          <div className='py-3 text-center border-top'>
            <Link
              to='/account/notifications'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTIcon iconName='arrow-right' className='fs-5' />
            </Link>
          </div>
        </div>

        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel' style={{ maxHeight: '420px', overflowY: 'auto' }}>
          <div className='d-flex flex-column px-9'>
            <div className='pt-5 pb-0'>
              <AnnouncementDisplay />
            </div>
            <div className='py-3 text-center border-top'>
              <Link
                to='/account/announcements'
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                View All <KTIcon iconName='arrow-right' className='fs-5' />
              </Link>
            </div>
            {/* <div className='pt-5 pb-0'>
            <h4 className='text-dark fw-bolder'><KTIcon iconName='update-file' className='fs-2 text-primary'/> Posted recently</h4>
            <hr/>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Today</h6>
              <div className='text-right mt-2 '>
                <Link to='/docket' className='btn btn-primary btn-sm'> 26 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Last 3 Days</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 71 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Last 7 Days</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 137 </Link>
              </div>
            </div>
            <h3 className='text-dark fw-bolder'> <KTIcon iconName='folder' className='fs-2 text-primary'/> Dockets</h3>
            <hr/>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Today</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 27 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Last 3 Days</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 78 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Last 7 Days</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 190 </Link>
              </div>
            </div>

            <h3 className='text-dark fw-bolder'><KTIcon iconName='time' className='fs-2 text-primary'/> Expected</h3>
            <hr/>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Today</h6>
              <div className='text-right mt-2 '>
                <Link to='/docket' className='btn btn-primary btn-sm'> 26 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Next 3 Days</h6>
              <div className='text-right mt-2'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 28 </Link>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <h6 className='mt-2'> Next 7 Days</h6>
              <div className='text-right mt-2 mb-3'>
                <Link to='/docket' className='btn btn-primary btn-sm'> 136 </Link>
              </div>
            </div>
            <div></div>
          </div> */}
            {/* <div className='text-center px-4'>
            <img className='mw-100 mh-200px' alt='metronic' src={useIllustrationsPath('1.png')} />
          </div> */}
          </div>
        </div>
        {/* 
      <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='py-3 text-center border-top'>
          <Link
            to='crafted/account/notifications'
            className='btn btn-color-gray-600 btn-active-color-primary'
            onClick={() => setIsOpen(false)}
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div> */}
      </div>
    </div>
  )
}

export { HeaderNotificationsMenu }

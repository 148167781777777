import React, { useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { useParams, useNavigate } from 'react-router-dom';
import SearchBar from "../../../components/SearchBar";
import UploadDocumentModal from "./UploadDocumentModal";
import Cookies from 'js-cookie';
import MyDocumentsTable from "./MyDocumentsTable";
import { useAuth } from "../../../modules/auth";
// import axios from "axios";


const vaultData = {
  "1": {
    "vault_id": 1,
    "type": "type",
    "parent_Id": 0,
    "name": "Main Vault",
    "doc_rank": 1,
    "status": "Draft",
    "sub_vaults": [
      {
        "vault_id": 2,
        "type": "type",
        "parent_Id": 1,
        "name": "Sub Vault A",
        "doc_rank": 2,
        "status": "Draft"
      },
      {
        "vault_id": 3,
        "type": "type",
        "parent_Id": 1,
        "name": "Sub Vault B",
        "doc_rank": 3,
        "status": "Draft"
      },
      {
        "vault_id": 4,
        "type": "type",
        "parent_Id": 1,
        "name": "Sub Vault C",
        "doc_rank": 4,
        "status": "Draft"
      }
    ]
  },
  "5": {
    "vault_id": 5,
    "type": "type",
    "parent_Id": 0,
    "name": "Secondary Vault",
    "doc_rank": 5,
    "status": "Draft",
    "sub_vaults": [
      {
        "vault_id": 6,
        "type": "type",
        "parent_Id": 5,
        "name": "Sub Vault D",
        "doc_rank": 6,
        "status": "Draft"
      },
      {
        "vault_id": 7,
        "type": "type",
        "parent_Id": 5,
        "name": "Sub Vault E",
        "doc_rank": 7,
        "status": "Draft"
      }
    ]
  },
  "8": {
    "vault_id": 8,
    "type": "type",
    "parent_Id": 0,
    "name": "Tertiary Vault",
    "doc_rank": 8,
    "status": "Draft",
    "sub_vaults": [
      {
        "vault_id": 9,
        "type": "type",
        "parent_Id": 8,
        "name": "Sub Vault F",
        "doc_rank": 9,
        "status": "Draft"
      },
      {
        "vault_id": 10,
        "type": "type",
        "parent_Id": 8,
        "name": "Sub Vault G",
        "doc_rank": 10,
        "status": "Draft"
      }
    ]
  }
}
const PAGINATION_PAGES_COUNT = 10;
const Collection = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
  const handleHeaderClick = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * PAGINATION_PAGES_COUNT;
  const endIndex = startIndex + PAGINATION_PAGES_COUNT;
  const navigate = useNavigate();
  const [editingFile, setEditingFile] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const { currentUser } = useAuth();
  const [searchInput, setSearchInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
  const [filteredFiles, setFilteredFiles] = useState(uploadedFiles);
  const [name, setName] = useState(editingFile ? editingFile.name : '');
  const [description, setDescription] = useState(editingFile ? editingFile.description : '');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  let vaultTitle = '';
  let subVaultTitle = '';

  for (const vault of Object.values(vaultData)) {
    console.log(`Checking vault: ${vault.name}`);
    for (const subVault of vault.sub_vaults) {
      console.log(`Checking subVault: ${subVault.name}`);
      if (subVault.vault_id === Number(id)) {
        console.log('Match found');
        vaultTitle = vault.name;
        subVaultTitle = subVault.name;
        break;
      }
    }

    if (vaultTitle && subVaultTitle) {
      break;
    }
  }

  // let files={};
  // setUploadedFiles(files);
  // setFilteredFiles(files);
  // setLoading(false);
  // setCurrentPage(1);
  console.log(message, isUploaded, deleteConfirmation);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateDocument = async (documentId, newName, newDescription) => {
    try {
      const response = '';
      // const response = await axios.patch(`/reg/v1/document/mydocuments/${currentUser?.id}`, {
      //     document_id: documentId,
      //     name: newName,
      //     description: newDescription,
      // });

      if (response.status !== 200) {
        throw new Error(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.error('Failed to update document:', error);
      setUploadedFiles([]);
      setCurrentPage(1);
      setLoading(false);
      setFilteredFiles([]);
    }
  }

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className="d-flex flex-row-fluid px-1">
          <div className="d-flex flex-column-auto align-items-center justify-content-center ">
            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
              <span>Vault: {vaultTitle} </span>
              <div className="d-flex align-items-center ms-4">
                {/* <KTIcon iconName='archive' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' /> */}
                <span>Collection: {subVaultTitle} </span>
                {loading && (
                  <div className="spinner-border text-primary ms-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            </h1>
          </div>
        </div>
        <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
          <div className="col-5">
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              placeholder={"Search My Collection"}
            />
          </div>
          <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
            <button
              type='button'
              className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='filter' className='fs-2' /> Filter
            </button>
          </div>
          <div className="d-flex flex-column-auto d-flex align-items-center py-1">
            <button
              type='button'
              className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
              onClick={handleOpenModal}
            >
              <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Document
            </button>
            <UploadDocumentModal isOpen={isModalOpen} onClose={handleCloseModal} setIsUploaded={setIsUploaded} setMessage={setMessage} />
          </div>
          <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
            <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
            <input
              type="number"

              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
              className='form-control form-control-solid ms-2 border border-1'
              style={{ width: '90px', backgroundColor: '#f1faff' }}
            />
          </div>
          <div>
            <MyDocumentsTable
              filteredFiles={filteredFiles}
              sortConfig={sortConfig}
              handleHeaderClick={handleHeaderClick}
              startIndex={startIndex}
              endIndex={endIndex}
              navigate={navigate}
              editingFile={editingFile}
              setEditingFile={setEditingFile}
              setName={setName}
              setDescription={setDescription}
              name={name}
              description={description}
              currentUser={currentUser}
              updateDocument={updateDocument}
              // fetchFiles={fetchFiles}
              setDeleteConfirmation={setDeleteConfirmation}
            // setDocumentToDelete={setDocumentToDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Collection;
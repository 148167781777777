import { toAbsoluteUrl } from '../../../_metronic/helpers'

const ComingSoon = () => {
  return (
    <div className='mb-3' style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`,
      height: '75vh'
    }}>
      <h1 className='fw-bolder fs-2hx text-white-900 mb-4'>Adverse Events Coming Soon!</h1>
      <div className='fw-semibold fs-6 text-white-500 mb-7'>This page is under maintenance and will be back shortly.</div>
      {/* <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />         */}
    </div>
  )
}

export default ComingSoon

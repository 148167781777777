import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import AnnouncementToolbar from "./AnnouncementToolbar";
import clsx from 'clsx';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

interface Announcement {
    id: number;
    start_date: string;
    end_date: string;
    title: string;
    content: string;
    priority: string;
    is_active: boolean;
    created_by: string;
}

const AnnouncementList = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [currentAnnouncements, setCurrentAnnouncements] = useState<Announcement[]>([]);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState<Announcement[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'start_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        priority: '',
        status: '',
    });

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        const month = date.getUTCMonth() + 1;  // Months are zero indexed, so +1
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }, [currentPage]);

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = announcements;

        if (filters.priority !== "") {
            filteredData = filteredData.filter(announcement =>
                announcement.priority && announcement.priority.toString() === filters.priority
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate);
            filteredData = filteredData.filter(announcement =>
                new Date(announcement.start_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate);
            filteredData = filteredData.filter(announcement =>
                new Date(announcement.end_date) <= endDate
            );
        }

        // Add a new condition to filter by status
        if (filters.status !== "") {
            filteredData = filteredData.filter(announcement =>
                (announcement.is_active ? "1" : "0") === filters.status
            );
        }

        setFilteredAnnouncements(filteredData);
        setCurrentPage(1);
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: '',
            endDate: '',
            priority: '',
            status: '',
        });
        setCurrentPage(1);
    };

    const fetchAnnouncements = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/reg/v1/superadmin/announcement');
            const sortedAnnouncements = response.data.sort((a: Announcement, b: Announcement) => {
                const startDateComparison = new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
                if (startDateComparison !== 0) {
                    return startDateComparison;
                } else {
                    return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
                }
            });
            setAnnouncements(sortedAnnouncements);
            setFilteredAnnouncements(sortedAnnouncements);
        } catch (error) {
            console.error('Error fetching announcements:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    useEffect(() => {
        setTotalPages(Math.ceil(filteredAnnouncements.length / itemsPerPage));
    }, [filteredAnnouncements, itemsPerPage]);

    useEffect(() => {
        setCurrentAnnouncements(filteredAnnouncements.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredAnnouncements, currentPage, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // const nextPage = () => {
    //     if (currentPage < totalPages) {
    //         setCurrentPage(currentPage + 1);
    //     }
    // };

    // const prevPage = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(currentPage - 1);
    //     }
    // }

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedAnnouncements = [...filteredAnnouncements].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredAnnouncements(sortedAnnouncements);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);

    useEffect(() => {
        let filteredData = announcements;

        if (searchInput !== "") {
            filteredData = filteredData.filter(announcement =>
                announcement.title.toLowerCase().includes(searchInput.toLowerCase()) ||
                announcement.content.toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        if (filters.priority !== "") {
            filteredData = filteredData.filter(announcement =>
                announcement.priority && announcement.priority.toString() === filters.priority
            );
        }

        if (filters.startDate !== "") {
            const startDate = parseDate(filters.startDate);
            filteredData = filteredData.filter(announcement =>
                parseDate(announcement.start_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = parseDate(filters.endDate);
            filteredData = filteredData.filter(announcement =>
                parseDate(announcement.start_date) <= endDate
            );
        }

        // Add a new condition to filter by status
        if (filters.status !== "") {
            filteredData = filteredData.filter(announcement =>
                (announcement.is_active ? "1" : "0") === filters.status
            );
        }
        setFilteredAnnouncements(filteredData);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    return (
        <div>
            <AnnouncementToolbar
                isLoading={loading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                fetchAnnouncements={fetchAnnouncements}
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
            />
            {!loading &&
                <div>
                    <div className='table-responsive mb-5'>
                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                            style={tableStyle}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '11%', cursor: 'pointer' }} onClick={() => handleHeaderClick('start_date')}>
                                        Start Date
                                        <span className={`sorting-icon ${sortConfig.key === 'start_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'start_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '10%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('end_date')}>
                                        End Date
                                        <span className={`sorting-icon ${sortConfig.key === 'end_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'end_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('title')}>
                                        Title
                                        <span className={`sorting-icon ${sortConfig.key === 'title' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'title' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '35%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('content')}>
                                        Content
                                        <span className={`sorting-icon ${sortConfig.key === 'content' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'content' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('priority')}>
                                        Priority
                                        <span className={`sorting-icon ${sortConfig.key === 'priority' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'priority' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('is_active')}>
                                        Status
                                        <span className={`sorting-icon ${sortConfig.key === 'is_active' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'is_active' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody className='text-gray-600 fw-bold'>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Loading...
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className='text-gray-600 fw-bold'>
                                    {currentAnnouncements.length === 0 ? (
                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                            <td colSpan={6} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                        </tr>
                                    ) : (
                                        currentAnnouncements.map((announcement) => (
                                            <tr key={announcement.id}>
                                                <td className="py-9 ps-10" style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{parseDate(announcement.start_date)}</td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{parseDate(announcement.end_date)}</td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{announcement.title}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left' }}>{announcement.content}</td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                                    <div className={`badge ${Number(announcement.priority) === 1 ? 'badge-success' : Number(announcement.priority) === 2 ? 'badge-warning' : 'badge-danger'} fw-bolder`}>
                                                        {Number(announcement.priority) === 1 ? 'Low' : Number(announcement.priority) === 2 ? 'Medium' : 'High'}
                                                    </div>
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                                    <div className={`badge ${announcement.is_active ? 'badge-success' : 'badge-light'} fw-bolder`}>
                                                        {announcement.is_active ? 'Active' : 'Inactive'}
                                                    </div>
                                                </td>
                                            </tr>
                                        )))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {totalPages > 1 &&
                        <div className='card-footer mb-5'>
                            <div className='row'>
                                <div className='d-flex justify-content-center mt-1 mb-1'>
                                    <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                            color="primary"
                                            showFirstButton
                                            showLastButton
                                            size="large"
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    className={clsx({
                                                        'Mui-selected': item.page === currentPage && item.type !== 'first' && item.type !== 'last',
                                                    })}
                                                    sx={{
                                                        border: item.page === currentPage && !item.disabled ? '1px solid' : 'none',
                                                        color: item.page === currentPage ? 'text.primary' : 'text.primary', // Change to your desired hex color
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'linear-gradient(to bottom, #2C81AF 50%, #2C81AF 50%)' // Change to your desired hex color
                                                        },
                                                    }}
                                                    disabled={
                                                        ((item.type === 'first' || item.type === 'previous') && currentPage === 1) ||
                                                        ((item.type === 'last' || item.type === 'next') && currentPage === totalPages)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default AnnouncementList;

import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Organizations } from './Organizations'
import { ProfileHeader2 } from './ProfileHeader'
import { Settings } from './Settings'
import { Users } from './Users'
import Announcements from './Announcement'
import Feedback from './Feedback/Feedback'
import { Configuration } from './Configuration'
import DataOperations from './DataOperations'
import RegulatoryDetails from './DataOperations/RegulatoryDetails'
import { UploadWarningLetters } from './UploadWarningLetters'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'SuperAdmin',
    path: '/super-admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = ({ activeTab }) => (
  <>
    <ProfileHeader2 />
    <Routes>
      {/* <Route
      element={
        <>
          <ProfileHeader2 />
          <Outlet />
        </>
      }
    > */}
      <Route
        path='organizations'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Organizations</PageTitle>
            <Organizations />
          </>
        }
      />
      <Route
        path='settings'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Settings</PageTitle>
            <Settings />
          </>
        }
      />
      <Route
        path='users'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Users</PageTitle>
            <Users />
          </>
        }
      />
      <Route
        path='support'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Support</PageTitle>
            <Feedback />
          </>
        }
      />
      <Route
        path='announcements'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Announcements</PageTitle>
            <Announcements />
          </>
        }
      />
      <Route
        path='configurations'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Configurations</PageTitle>
            <Configuration />
          </>
        }
      />
      <Route
        path='data-operations'
        element={<Navigate to='/super-admin/data-operations/regulatory' replace />}
      />
      <Route
        path='data-operations/:tab'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Data Operations</PageTitle>
            <DataOperations />
          </>
        }
      />
      <Route
        path='data-operations/regulatory/:guid/:type'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Regulatory Run History Details</PageTitle>
            <RegulatoryDetails />
          </>
        }
      />
      <Route
        path='upload-warning-letters'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Upload Warning Letters</PageTitle>
            <UploadWarningLetters />
          </>
        }
      />
      {/* <Route
        path='support'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Support</PageTitle>
            <h1>Support</h1>
          </>
        }
      />
      <Route
        path='billing'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Billing</PageTitle>
            <h1>Billing</h1>
          </>
        }
      /> */}
      if {activeTab === 'data-operations' ? (
        <Route index element={<Navigate to={`/super-admin/${activeTab}/regulatory`} />} />
      ) : (
        <Route index element={<Navigate to={`/super-admin/${activeTab}`} />} />
      )}
      {/* <Route index element={<Navigate to={`/super-admin/${activeTab}`} />} /> */}
      {/* </Route> */}
    </Routes>
  </>
)

export default ProfilePage